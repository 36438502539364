import { matches } from 'lodash';
import get from 'lodash/get';
import { Action } from 'redux-actions';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { Seller } from '@savgroup-front-common/types';

import callAndGetResponse from '../../services/callAndGetResponse';

import * as ActionTypes from './actionTypes';
import { sellerIsLoadingSelector, sellerListValueSelector } from './selectors';

const MAX_SELLER_NUMBER_TO_SUPPORT_LANGUAGE_RESTRICTION = 5;

interface GetSellersConfigurationPayload {
  sellerIds: string[];
}

export function* getSellersConfigurationWorker({
  payload,
}: {
  payload: GetSellersConfigurationPayload;
}): Generator {
  const { sellerIds } = payload;

  yield put(ActionTypes.GET_SELLERS_CONFIGURATION.start({ sellerIds }));

  if (sellerIds.length <= MAX_SELLER_NUMBER_TO_SUPPORT_LANGUAGE_RESTRICTION) {
    for (const sellerId of sellerIds) {
      yield put(ActionTypes.GET_SELLER_CONFIGURATION.base(sellerId));
      yield take(ActionTypes.GET_SELLER_CONFIGURATION.END);
    }
  }

  yield put(ActionTypes.GET_SELLERS_CONFIGURATION.success({ sellerIds }));
  yield put(ActionTypes.GET_SELLERS_CONFIGURATION.end({ sellerIds }));
}
function* getSellersConfigurationWatcher() {
  yield takeEvery<Action<GetSellersConfigurationPayload>>(
    ActionTypes.GET_SELLERS_CONFIGURATION.BASE,
    getSellersConfigurationWorker,
  );
}

export function* getSellerConfigWorker({
  payload: sellerId,
}: {
  payload: string;
}): Generator {
  if (sellerId) {
    yield call(
      callAndGetResponse,
      ActionTypes.GET_SELLER_CONFIGURATION,
      `${APIConfiguration.config}sellers/${sellerId}/configuration`,
      { method: SUPPORTED_METHODS.GET },
      { sellerId, indexer: sellerId },
    );
  }

  yield put(ActionTypes.GET_SELLER_CONFIGURATION.end({ sellerId }));
}
function* getSellerConfigWatcher() {
  yield takeEvery<Action<string>>(
    ActionTypes.GET_SELLER_CONFIGURATION.BASE,
    getSellerConfigWorker,
  );
}

export function* getSellerByIdWorker({
  payload: sellerId,
}: {
  payload: string;
}): Generator {
  yield call(
    callAndGetResponse,
    ActionTypes.GET_SELLER_BY_ID,
    `${APIConfiguration.config}sellers/byid/${sellerId}`,
    { method: SUPPORTED_METHODS.GET },
    { sellerId },
  );
  yield put(ActionTypes.GET_SELLER_BY_ID.end({ sellerId }));
}
function* getSellerByIdWatcher() {
  yield takeEvery<Action<string>>(
    ActionTypes.GET_SELLER_BY_ID.BASE,
    getSellerByIdWorker,
  );
}

function* getTenant(tenantId: string): Generator {
  const sellerList: any = yield select(sellerListValueSelector);
  const tenant = sellerList.find((seller: Seller) => seller.id === tenantId);
  const isLoading = yield select((state) =>
    sellerIsLoadingSelector(state, tenantId),
  );
  const meta = { sellerId: tenantId };

  if (tenantId && !tenant && !isLoading) {
    yield call(
      callAndGetResponse,
      ActionTypes.GET_SELLER_BY_ID,
      `${APIConfiguration.config}sellers/byid/${tenantId}`,
      { method: SUPPORTED_METHODS.GET },
      meta,
    );

    yield put(ActionTypes.GET_SELLER_BY_ID.end(meta));
  }
}
export function* getTenantFromSellerWorker(): Generator {
  const sellers: any = yield select((state) => sellerListValueSelector(state));

  const tenants = sellers
    .map((seller: Seller) => get(seller, 'tenantId', null))
    .filter((tenantId: string) => tenantId);

  yield all(tenants.map(getTenant));
}
function* getTenantFromSellerWatcher() {
  yield takeEvery(ActionTypes.GET_SELLER_BY_ID.END, getTenantFromSellerWorker);
}

interface SetSellerConfigPayload {
  sellerId: string;
  timestamp: string;
}

export function* setSellerConfigWorker({
  payload,
}: {
  payload: SetSellerConfigPayload;
}): Generator {
  const { sellerId, timestamp } = payload;
  const meta = { indexer: timestamp };
  const actions = [
    ActionTypes.SET_SELLER_CONFIGURATION_OTHER,
    ActionTypes.SET_SELLER_CONFIGURATION_TRANSPORT,
    ActionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES,
    ActionTypes.SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER,
  ];

  yield put(ActionTypes.SET_SELLER_CONFIGURATION.start(meta));
  // each call should be following each other
  // otherwise, the backend will ereased the some values

  let errored = false;

  for (const action of actions) {
    const [, [, failed]]: any = yield all([
      put(action.base(payload)),
      race([
        take(
          matches({
            type: action.END,
            meta: { sellerId },
          }),
        ),
        take(
          matches({
            type: action.ERRORED,
            meta: { sellerId },
          }),
        ),
      ]),
    ]);

    if (failed) {
      errored = true;
    }
  }

  if (errored) {
    yield put(ActionTypes.SET_SELLER_CONFIGURATION.error({ sellerId }, meta));
  } else {
    yield put(ActionTypes.SET_SELLER_CONFIGURATION.success({ sellerId }, meta));
  }

  yield put(ActionTypes.SET_SELLER_CONFIGURATION.end({ sellerId }));
}
function* setSellerConfigWatcher() {
  yield takeEvery<Action<SetSellerConfigPayload>>(
    ActionTypes.SET_SELLER_CONFIGURATION.BASE,
    setSellerConfigWorker,
  );
}

interface SetSellerConfigOtherPayload {
  cgvUrl?: string | null;
  faqUrl?: string | null;
  sellerId?: string | null;
  lowValueThreshold?: {
    amount: string;
    currency: string;
  } | null;
  automatedMessagesEnabled?: boolean | null;
  isClaimValidationNeeded?: boolean | null;
  defaultOwnerOrderUrl?: string | null;
  ownerNotificationSenderMail?: string;
  paymentUrl?: string;
  qualiReparUrl?: string;
  isCashRegisterConnected: boolean;
  isSparePartNewModelEnabled: boolean;
  vonageNaming: string;
}

function* setSellerConfigOtherWorker({
  payload: {
    cgvUrl = null,
    faqUrl = null,
    sellerId = null,
    lowValueThreshold = null,
    automatedMessagesEnabled = null,
    isClaimValidationNeeded = null,
    defaultOwnerOrderUrl = null,
    ownerNotificationSenderMail,
    paymentUrl,
    qualiReparUrl,
    isCashRegisterConnected,
    isSparePartNewModelEnabled,
    vonageNaming,
  },
}: {
  payload: SetSellerConfigOtherPayload;
}): Generator {
  const json = {
    cgvUrl,
    faqUrl,
    lowValueThreshold,
    automatedMessagesEnabled,
    isClaimValidationNeeded,
    defaultOwnerOrderUrl,
    ownerNotificationSenderMail,
    paymentUrl,
    isCashRegisterConnected,
    qualiReparUrl,
    isSparePartNewModelEnabled,
    vonageNaming,
  };
  const meta = { sellerId };

  if (
    ![
      cgvUrl,
      sellerId,
      lowValueThreshold,
      automatedMessagesEnabled,
      isClaimValidationNeeded,
      defaultOwnerOrderUrl,
      paymentUrl,
      qualiReparUrl,
    ].includes(null)
  ) {
    yield call(
      callAndGetResponse,
      ActionTypes.SET_SELLER_CONFIGURATION_OTHER,
      `${APIConfiguration.config}sellers/${sellerId}/configuration/other`,
      { method: SUPPORTED_METHODS.POST, json },
      meta,
    );
  }

  yield put(ActionTypes.SET_SELLER_CONFIGURATION_OTHER.end(meta));
}
function* setSellerConfigOtherWatcher() {
  yield takeEvery<Action<SetSellerConfigOtherPayload>>(
    ActionTypes.SET_SELLER_CONFIGURATION_OTHER.BASE,
    setSellerConfigOtherWorker,
  );
}

interface SetSellerConfigTransportPayload {
  sellerId?: string | null;
  insuranceThreshold?: number | null;
  carriersOptIn?: string | null;
  deductTransportFromRefund?: boolean | null;
  carriersLabelsExpirationDelayInDays?: {
    chronopost?: number;
    colissimo?: number;
    ups?: number;
    external?: number;
    mondialRelay?: number;
  };
}

function* setSellerConfigTransportWorker({
  payload: {
    sellerId = null,
    insuranceThreshold = null,
    carriersOptIn = null,
    deductTransportFromRefund = null,
    carriersLabelsExpirationDelayInDays = undefined,
  } = {},
}: {
  payload: SetSellerConfigTransportPayload;
}): Generator {
  const json = {
    insuranceThreshold,
    carriersOptIn,
    deductTransportFromRefund,
    carriersLabelsExpirationDelayInDays,
  };
  const meta = { sellerId };

  if (
    ![
      sellerId,
      insuranceThreshold,
      carriersOptIn,
      deductTransportFromRefund,
    ].includes(null)
  ) {
    yield call(
      callAndGetResponse,
      ActionTypes.SET_SELLER_CONFIGURATION_TRANSPORT,
      `${APIConfiguration.config}sellers/${sellerId}/configuration/transport`,
      { method: SUPPORTED_METHODS.POST, json },
      meta,
    );
  }

  yield put(ActionTypes.SET_SELLER_CONFIGURATION_TRANSPORT.end(meta));
}
function* setSellerConfigTransportWatcher() {
  yield takeEvery<Action<SetSellerConfigTransportPayload>>(
    ActionTypes.SET_SELLER_CONFIGURATION_TRANSPORT.BASE,
    setSellerConfigTransportWorker,
  );
}

interface SetSellerConfigAccountManagerPayload {
  sellerId?: string | null;
  accountManagerConfiguration?: {
    sellerId: string;
    accountStatus: string;
    customerService: boolean;
    liveDateUtc: string;
    lastChangeTimeUtc: string;
    lastChangeUser: string;
  } | null;
}

function* setSellerConfigAccountManagerWorker({
  payload: { sellerId = null, accountManagerConfiguration = null },
}: {
  payload: SetSellerConfigAccountManagerPayload;
}): Generator {
  const json = { ...accountManagerConfiguration };
  const meta = { sellerId };

  if (accountManagerConfiguration && sellerId) {
    yield call(
      callAndGetResponse,
      ActionTypes.SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER,
      `${APIConfiguration.config}sellers/${sellerId}/configuration/accountManager`,
      { method: SUPPORTED_METHODS.POST, json },
      meta,
    );
  }

  yield put(ActionTypes.SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER.end(meta));
}
function* setSellerConfigAccountManagerWatcher() {
  yield takeEvery<Action<SetSellerConfigAccountManagerPayload>>(
    ActionTypes.SET_SELLER_CONFIGURATION_ACCOUNT_MANAGER.BASE,
    setSellerConfigAccountManagerWorker,
  );
}

interface SetSellerConfigAcceptedLanguagesPayload {
  sellerId?: string | null;
  acceptedLanguages?: string[] | null;
  timestamp: string;
}

export function* setSellerConfigAcceptedLanguagesWorker({
  payload: { sellerId = null, acceptedLanguages = null, timestamp },
}: {
  payload: SetSellerConfigAcceptedLanguagesPayload;
}): Generator {
  const meta = {
    sellerId,
    acceptedLanguages,
    indexer: timestamp,
  };

  if (sellerId && acceptedLanguages) {
    yield call(
      callAndGetResponse,
      ActionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES,
      `${APIConfiguration.config}sellers/${sellerId}/configuration/acceptedLanguages`,
      { method: SUPPORTED_METHODS.POST, json: { acceptedLanguages } },
      meta,
    );
  }

  yield put(ActionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES.end(meta));
}
function* setSellerConfigAcceptedLanguagesWatcher() {
  yield takeEvery<Action<SetSellerConfigAcceptedLanguagesPayload>>(
    ActionTypes.SET_SELLER_CONFIGURATION_ACCEPTED_LANGUAGES.BASE,
    setSellerConfigAcceptedLanguagesWorker,
  );
}

export default function* sellerConfigurationSaga() {
  try {
    yield all([
      getSellerByIdWatcher(),
      getSellerConfigWatcher(),

      getSellersConfigurationWatcher(),
      getTenantFromSellerWatcher(),
      setSellerConfigWatcher(),
      setSellerConfigAcceptedLanguagesWatcher(),
      setSellerConfigOtherWatcher(),
      setSellerConfigAccountManagerWatcher(),
      setSellerConfigTransportWatcher(),
    ]);
  } catch (error) {
    logCritical(error as any);
  }
}
