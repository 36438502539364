import { defineMessages } from 'react-intl';

export default defineMessages({
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  deductibleFromRefund: {
    id: 'view.addressHandling.deductibleFromRefund',
    defaultMessage: 'deductible from refund',
  },
});
