import * as Yup from 'yup';

import {
  globalMessages,
  isValidPhoneNumber,
} from '@savgroup-front-common/core/src/helpers';
import { postalCodeSchema } from '@savgroup-front-common/core/src/schemas/postalCode.schema';

export const addressFormSchema = Yup.object().shape({
  address: Yup.string().required(globalMessages.form.required),
  phone: Yup.string()
    .required(globalMessages.form.required)
    .test(
      'validPhoneNumber',
      globalMessages.form.invalidPhoneNumber,
      (value, context) => {
        const { countryCode } = context.parent;

        return isValidPhoneNumber(value, countryCode);
      },
    ),
  companyName: Yup.string(),
  countryCode: Yup.string().required(globalMessages.form.required),
  lastname: Yup.string().required(globalMessages.form.required),
  firstname: Yup.string().required(globalMessages.form.required),
  postalCode: Yup.string()
    .required(globalMessages.form.required)
    .concat(postalCodeSchema),
  city: Yup.string().required(globalMessages.form.required),
  hasElevator: Yup.boolean(),
  hasParkingSpace: Yup.boolean(),
  housingType: Yup.string(),
  floor: Yup.string(),
  doorCode: Yup.string(),
  additionalInformation: Yup.string(),
});
