import { TransportMethodType } from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

const getHandlingCardIcon = ({
  transportMethodType,
}: {
  transportMethodType: TransportMethodType;
}) => {
  switch (transportMethodType) {
    case TransportMethodType.HomeIntervention:
    case TransportMethodType.HomeDelivery:
    case TransportMethodType.HomePickup:
    case TransportMethodType.HomePickupProvidedBySeller:
    case TransportMethodType.BulkyHomeDelivery:
    case TransportMethodType.LabelProvidedByCustomer:
    case TransportMethodType.LabelProvidedBySeller:
      return ICONS_TYPE.HOME_ICON;

    case TransportMethodType.DropAtStoreDeposit:
    case TransportMethodType.ImmediateDropAtStoreDeposit:
    case TransportMethodType.PickUpStoreDelivery:
    case TransportMethodType.OnSiteCollection:
      return ICONS_TYPE.SMALL_SHOP_ICON;
    case TransportMethodType.PickUpRelayPointDelivery:
    case TransportMethodType.RelayPointDeposit:
      return ICONS_TYPE.BOX_ICON;

    default:
      return ICONS_TYPE.DELIVERY_TRUCK_ICON;
  }
};

export default getHandlingCardIcon;
