import { combineReducers } from 'redux';

import {
  constants as actorsConstants,
  reducer as actorsReducer,
} from '@savgroup-front-common/core/src/domains/actors';
import { reducer as attachmentsReducer } from '@savgroup-front-common/core/src/domains/attachments';
import { reducer as carriersReducer } from '@savgroup-front-common/core/src/domains/carriers';
import {
  constants as claimsConstants,
  reducer as claimsReducer,
} from '@savgroup-front-common/core/src/domains/claims';
import { reducer as fileUploadReducer } from '@savgroup-front-common/core/src/domains/fileUpload';
import {
  I18N_KEY,
  reducer as i18nReducer,
} from '@savgroup-front-common/core/src/domains/i18n';
import routerReducer from '@savgroup-front-common/core/src/domains/router/reducer';
import {
  SAGA_REQUEST_METADATA_KEY,
  reducer as sagaRequestMetadataReducer,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata';
import { reducer as sellerConfigurationReducer } from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import {
  SELLER_DOMAIN_KEY,
  sellerReducer,
} from '@savgroup-front-common/core/src/domains/sellers';
import {
  STORE_DOMAIN_KEY,
  storeReducer,
} from '@savgroup-front-common/core/src/domains/store';
import {
  technicalInterventionsConstants,
  technicalInterventionsReducer,
} from '@savgroup-front-common/core/src/domains/technicalInterventions';
import { reducer as workflowReducer } from '@savgroup-front-common/core/src/domains/workflow';

import { reducer as claimReducer } from './Claim';
import { reducer as customerReducer } from './Customer';
import fileTrackingReducer from './FileTrackingPage/fileTrackingReducer';
import loginReducer from './Login/loginReducer';
import myOrderFeatureReducer from './Orders/reducer';
import { reducer as ownerReducer } from './owner';
import profileManagementReducer from './ProfileManagement/reducer';

const rootReducer = () =>
  combineReducers({
    [actorsConstants.ACTORS_DOMAIN_KEY]: actorsReducer,
    attachments: attachmentsReducer,
    carriers: carriersReducer,
    claim: claimReducer,
    [claimsConstants.CLAIMS_DOMAIN_KEY]: claimsReducer,
    customer: customerReducer,
    fileTrackingPage: fileTrackingReducer,
    fileUpload: fileUploadReducer,
    [I18N_KEY]: i18nReducer,
    login: loginReducer,
    orders: myOrderFeatureReducer,
    owner: ownerReducer,
    profileManagement: profileManagementReducer,
    router: routerReducer,
    [STORE_DOMAIN_KEY]: storeReducer,
    [SELLER_DOMAIN_KEY]: sellerReducer,
    [SAGA_REQUEST_METADATA_KEY]: sagaRequestMetadataReducer,
    sellerConfiguration: sellerConfigurationReducer,
    [technicalInterventionsConstants.TECHNICAL_INTERVENTIONS_DOMAIN_KEY]:
      technicalInterventionsReducer,
    workflow: workflowReducer,
  });

export default rootReducer;
