import styled from 'styled-components';

import { standardTimingFunctionTransition } from '../../../../animations/timingFunction';
import { InputStatus } from '../helpers/getFinalFieldState.types';

import {
  getWrapperColorFromStatus,
  getWrapperHoverColorFromStatus,
} from './Wrapper.helpers';

interface WrapFixProps {
  $status: InputStatus;
  $isHovered: boolean;
  $isFocused: boolean;
}
interface WrapElementProps extends WrapFixProps {
  $isRounded: boolean;
  $isFullWidth: boolean;
  $isNewUiEnabled?: boolean;
}

export const $WrapFix = styled.div<WrapFixProps>`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  transition-duration: ${({ theme }) => theme.interactions.transformTiming};
  transition-property: color;
  padding: 0 0.75rem;
  white-space: nowrap;
  color: ${({ $isHovered, $isFocused, theme, $status }) => {
    return $isHovered || $isFocused
      ? getWrapperHoverColorFromStatus({ theme, $status })
      : getWrapperColorFromStatus({ theme, $status });
  }};
`;

export const $WrapElement = styled.div<WrapElementProps>`
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  transition-duration: 0.1s;
  border-radius: ${({ theme, $isRounded, $isNewUiEnabled }) => {
    if ($isNewUiEnabled) {
      return theme.newUI.borders.mediumRadius;
    }

    if ($isRounded === false) {
      return 0;
    }

    return theme.borders.radius;
  }};
  transition-property: background;
  transition-duration: ${({ theme }) => theme.interactions.transformTiming};

  ${standardTimingFunctionTransition};
`;

export const $Wrapper = styled.div`
  display: flex;
`;
