import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useMedia } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { IrshStepValues } from '../IrshPages.types';

import messages from './messages';
import useClaimGroupSolutionPage from './NewClaimGroupSolutionPage.hooks';
import {
  $Heading,
  $HeadingIcon,
  $LeftColumn,
  $SolutionItemWrapper,
  $SolutionResultWrapper,
  $SolutionWrapper,
} from './NewClaimGroupSolutionPage.styles';
import { SolutionItem } from './SolutionItem';

export const NewClaimGroupSolutionPage: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onNextStep, onPreviousStep }) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const {
    solutions,
    claimGroupConfirmation,
    formContext,
    onSubmit,
    isSkippingSolution,
    isSolutionsLoading,
  } = useClaimGroupSolutionPage({ onNextStep });

  if (isSkippingSolution || isSolutionsLoading) {
    return <BaseLoader />;
  }

  return (
    <>
      <FormProvider {...formContext}>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            return undefined;
          }}
        >
          <NewStepLayout stepName={STEP_CONFIG.CLAIM_GROUP_SOLUTION}>
            <$SolutionWrapper>
              <$LeftColumn>
                <$Heading data-testid="choose-solution-title">
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.LIGHT_BULB_ON_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.solutionTitle}
                  />
                </$Heading>

                <$SolutionItemWrapper>
                  {solutions?.map((solution, index) => (
                    <SolutionItem
                      key={solution.key}
                      solution={solution}
                      onClick={() =>
                        onSubmit({ chooseSolution: solution.solutionTypeId })
                      }
                      dataTestId={`choose-solution-${index}`}
                    />
                  ))}
                </$SolutionItemWrapper>
              </$LeftColumn>

              <$SolutionResultWrapper>
                {isMobileView && (
                  <ResumeCardButton
                    claimGroupConfirmation={claimGroupConfirmation}
                  />
                )}
                {!isMobileView && (
                  <ResumeCard claimGroupConfirmation={claimGroupConfirmation} />
                )}
              </$SolutionResultWrapper>
            </$SolutionWrapper>
          </NewStepLayout>

          <PageFooterContainer reverseChildren={false}>
            <Button
              type={BUTTON_TYPES.BUTTON}
              secondary
              naked
              onClick={() => {
                onPreviousStep({});
              }}
              icon={<ArrowNakedLeftIcon />}
              position={SUPPORTED_ICON_POSITIONS.LEFT}
              dataTestId="previousButton"
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.previousButton}
              />
            </Button>
          </PageFooterContainer>
        </form>
      </FormProvider>
    </>
  );
};
