import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import { LanguageChooser } from '@savgroup-front-common/core/src/molecules/LanguageChooser';
import { useGetSellerAcceptedLanguages } from '@savgroup-front-common/core/src/molecules/LanguageChooser/hooks/useGetSellerAcceptedLanguages';
import useLangageChooser from '@savgroup-front-common/core/src/molecules/LanguageChooser/hooks/useLangageChooser';
import {
  LOGO_POSITION,
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import {
  $EmptyDiv,
  $HeaderContainer,
  $MenuContainer,
  $RightSide,
} from './Header.styles';
import Logo from './logo/Logo';

interface HeaderTestProps {
  sellerId: string;
  enableServicePortal?: boolean;
}

const Header: React.FC<HeaderTestProps> = ({
  sellerId,
  enableServicePortal,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [rightSideWidth, setRightSideWidth] = useState(0);
  const { currentLanguage, handleChangeLanguage, isLoading } =
    useLangageChooser();

  const theme = useTheme();

  const position = getCustomComponentProperty({
    theme,
    componentName: SUPPORTED_COMPONENTS.LOGO,
    property: SUPPORTED_PROPERTIES.POSITION,
    fallback: LOGO_POSITION.LEFT,
  }) as LOGO_POSITION;

  const { acceptedLanguagesBySeller } = useGetSellerAcceptedLanguages({
    sellerId,
  });

  const isLanguageChooseDisplayed = !!acceptedLanguagesBySeller?.length;

  useEffect(() => {
    if (ref.current) {
      setRightSideWidth(ref.current.offsetWidth);
    }
  }, [ref.current?.offsetWidth]);

  return (
    <$HeaderContainer
      componentThemeName={SUPPORTED_COMPONENTS.APP_HEADER}
      enableServicePortal={enableServicePortal}
    >
      <$MenuContainer $position={position}>
        {position === LOGO_POSITION.CENTER && isLanguageChooseDisplayed && (
          <$EmptyDiv $width={rightSideWidth} />
        )}
        <Logo sellerId={sellerId} />
        <$RightSide ref={ref}>
          <LanguageChooser
            acceptedLanguagesBySeller={acceptedLanguagesBySeller}
            currentLanguage={currentLanguage}
            onChangeLanguage={handleChangeLanguage}
            isLoading={isLoading}
          />
        </$RightSide>
      </$MenuContainer>
    </$HeaderContainer>
  );
};

export default Header;
