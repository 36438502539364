import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { QuoteClientAnswer } from '@savgroup-front-common/types/src/Quotation/QuoteClientAnswer';

import { safeFormattedIntlString } from '../../../../../formatters';
import { AdaptedQuotationNewModel } from '../../../types/AdaptedQuotationNewModel';
import { schema } from '../ConfirmQuotationRejectModalContent.schema';
import { ConfirmQuotationRejectModalValues } from '../ConfirmQuotationRejectModalContent.types';
import messages from '../messages';

interface UseConfirmQuotationRejectModalArgs {
  onRejectWithDestruction: ({ rejectReason }: { rejectReason: string }) => void;
  onRejectWithReturn: ({ rejectReason }: { rejectReason: string }) => void;
  quotation: AdaptedQuotationNewModel;
}

const useConfirmQuotationRejectModal = ({
  onRejectWithDestruction,
  onRejectWithReturn,
  quotation,
}: UseConfirmQuotationRejectModalArgs) => {
  const formContext = useForm<ConfirmQuotationRejectModalValues>({
    resolver: yupResolver(schema),
    mode: REVALIDATE_MODES.ALL,
  });

  const { handleSubmit } = formContext;

  const rejectOptions = [
    {
      value: QuoteClientAnswer.REJECTED_WITH_DESTRUCTION,
      label: safeFormattedIntlString(messages.destructionOption),
      data: {
        price: quotation.destructionFee?.amountIncludedTax,
      },
    },
    {
      value: QuoteClientAnswer.REJECTED_WITH_RETURN,
      label: safeFormattedIntlString(messages.returnOption),
      data: {
        price: quotation.returnFee?.amountIncludedTax,
      },
    },
  ];

  const handleRejectWithReturn = handleSubmit(
    ({ rejectOption, rejectComment }) => {
      if (rejectOption.value === QuoteClientAnswer.REJECTED_WITH_DESTRUCTION) {
        onRejectWithDestruction({ rejectReason: rejectComment });

        return undefined;
      }

      if (rejectOption.value === QuoteClientAnswer.REJECTED_WITH_RETURN) {
        onRejectWithReturn({ rejectReason: rejectComment });

        return undefined;
      }

      return undefined;
    },
  );

  return {
    formContext,
    rejectOptions,
    handleRejectWithReturn,
  };
};

export default useConfirmQuotationRejectModal;
