import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';

import {
  AutocompleteOption,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';

import { $Container, $Price } from './OptionValueWithPriceFormatter.styles';

type WithPrice<T> = T & { price: ReverseMoneyNumberAmount };

export const OptionValueWithPriceFormatter: FC<
  AutocompleteOption<WithPrice<unknown>>
> = ({ label, data }) => {
  const { price } = data || {};

  return (
    <$Container>
      {label ? (
        <SafeFormattedMessageWithoutSpread message={label} />
      ) : undefined}
      {price ? (
        <$Price>
          <FormattedNumber value={price.amount} format={price.currency} />
        </$Price>
      ) : undefined}
    </$Container>
  );
};
