import { defineMessages } from 'react-intl';

export default defineMessages({
  homeIntervention: {
    id: 'view.addressHandling.homeIntervention',
    defaultMessage: 'Home intervention',
  },

  DropAtStoreDeposit: {
    id: 'view.addressHandling.DropAtStoreDeposit',
    defaultMessage: 'Deposit at store',
  },
  RelayPointDeposit: {
    id: 'view.addressHandling.RelayPointDeposit',
    defaultMessage: 'Deposit at relay point',
  },
  ImmediateDropAtStoreDeposit: {
    id: 'view.addressHandling.ImmediateDropAtStoreDeposit',
    defaultMessage: 'Immediate Drop at store',
  },
  HomePickup: {
    id: 'view.addressHandling.HomePickup',
    defaultMessage: 'Pickup at home',
  },
  HomePickupProvidedBySeller: {
    id: 'view.addressHandling.HomePickupProvidedBySeller',
    defaultMessage: 'Seller provided by seller',
  },
  HomeDelivery: {
    id: 'view.addressHandling.HomeDelivery',
    defaultMessage: 'Home delivery',
  },
  PickUpStoreDelivery: {
    id: 'view.addressHandling.PickUpStoreDelivery',
    defaultMessage: 'Pickup store delivery',
  },
  PickUpRelayPointDelivery: {
    id: 'view.addressHandling.PickUpRelayPointDelivery',
    defaultMessage: 'Pickup relay point delivery',
  },

  OnSiteCollection: {
    id: 'view.addressHandling.OnSiteCollection',
    defaultMessage: 'On site collection',
  },

  LabelProvidedBySeller: {
    id: 'view.addressHandling.LabelProvidedBySeller',
    defaultMessage: 'External transport provided by seller',
  },
  LabelProvidedByCustomer: {
    id: 'view.addressHandling.LabelProvidedByCustomer',
    defaultMessage: 'External transport provided by customer',
  },

  BulkyHomeDelivery: {
    id: 'view.addressHandling.BulkyHomeDelivery',
    defaultMessage: 'Bulky Home delivery',
  },

  flat: {
    id: 'components.myaccount.claims.handling.flat',
    defaultMessage: 'Flat',
  },
  house: {
    id: 'components.myaccount.claims.handling.house',
    defaultMessage: 'House',
  },
  other: {
    id: 'components.myaccount.claims.handling.other',
    defaultMessage: 'Other',
  },
});
