import React, { FC } from 'react';

import { SALESFORCE_INTEGRATION_TYPE } from '@savgroup-front-common/types';

import { TakeInterventionAppointment } from '../components/TakeInterventionAppointment';
import { useGetSalesforceContextSummary } from '../hooks';
import { TakeInterventionAppointmentLegacy } from '../legacy/TakeInterventionAppointmentLegacy';

interface TakeInterventionAppointmentContentProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

const TakeInterventionAppointmentContent: FC<
  React.PropsWithChildren<TakeInterventionAppointmentContentProps>
> = ({ children, fileId, sellerId, onSubmit, isLoading }) => {
  const { sellerConfiguration } = useGetSalesforceContextSummary({
    fileId,
    sellerId,
  });
  const isDeprecated =
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
    SALESFORCE_INTEGRATION_TYPE.Deprecated;
  const isStandardOrDemoMode =
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.Standard ||
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.Integration;
  const isNone =
    sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
      SALESFORCE_INTEGRATION_TYPE.None ||
    !sellerConfiguration?.salesforceFieldServiceLightningIntegrationType;

  return (
    <>
      {isDeprecated && (
        <TakeInterventionAppointmentLegacy
          fileId={fileId}
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
          {children}
        </TakeInterventionAppointmentLegacy>
      )}

      {(isStandardOrDemoMode || isNone) && (
        <TakeInterventionAppointment
          fileId={fileId}
          sellerId={sellerId}
          onSubmit={onSubmit}
          isLoading={isLoading}
        >
          {children}
        </TakeInterventionAppointment>
      )}
    </>
  );
};

TakeInterventionAppointmentContent.displayName =
  'TakeInterventionAppointmentContent';

export default TakeInterventionAppointmentContent;
