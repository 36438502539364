import { useContext, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { CommonAttachmentService } from '@savgroup-front-common/core/src/api';

import { InitContext } from '../../../../../../../../../app/NewLayout/InitProvider/InitProvider.context';
import { PickupPointOption } from '../../../../../../../IrshPages.types';

import { getUrlsForPickupPoint } from './MapWithControlledZoom.helpers';
import { AdaptedPickupPointOption } from './MapWithControlledZoom.types';

interface UseMapWithControlledZoomArgs {
  adaptedPickupPointOptions: PickupPointOption[];
}

const useMapWithControlledZoom = ({
  adaptedPickupPointOptions,
}: UseMapWithControlledZoomArgs): {
  isLoading: boolean;
  adaptedPickupPointOptions: AdaptedPickupPointOption[];
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [adaptedPickupPoints, setAdaptedPickupPoints] = useState(
    adaptedPickupPointOptions,
  );

  const { orders } = useContext(InitContext);

  const sellerId = orders?.at(0)?.sellerId;

  useDeepCompareEffect(() => {
    const call = async () => {
      if (!sellerId) {
        return;
      }

      setIsLoading(true);

      const preparedResourceExistCache = adaptedPickupPointOptions.reduce<
        Record<string, undefined>
      >((acc, pickupPoint) => {
        const urls = getUrlsForPickupPoint({
          pickupPointOption: pickupPoint,
          sellerId,
        });

        return {
          ...acc,
          ...Object.values(urls).reduce((urlAcc, url) => {
            return { ...urlAcc, [url]: undefined };
          }, {}),
        };
      }, {});

      const resourceExistCache = (
        await Promise.all(
          Object.keys(preparedResourceExistCache).map((url) => {
            return CommonAttachmentService.urlExists({
              url,
            }).then((isExisting) => {
              return {
                url,
                isExisting,
              };
            });
          }),
        )
      ).reduce<Record<string, boolean>>((acc, item) => {
        return {
          ...acc,
          [item.url]: item.isExisting,
        };
      }, {});

      const result = await Promise.all(
        adaptedPickupPointOptions.map(async (pickupPoint) => {
          const urls = getUrlsForPickupPoint({
            pickupPointOption: pickupPoint,
            sellerId,
          });

          const defaultMarkerUrlFromPublicDocumentExist =
            resourceExistCache[urls.defaultMarkerUrl];
          const defaultSelectedMarkerUrlFromPublicDocumentsExist =
            resourceExistCache[urls.defaultSelectedMarkerUrl];
          const markerUrlFromPublicDocumentExist =
            resourceExistCache[urls.markerUrlFromPublicDocument];
          const selectedMarkerUrlFromPublicDocumentsExist =
            resourceExistCache[urls.selectedMarkerUrlFromPublicDocument];

          if (
            markerUrlFromPublicDocumentExist &&
            selectedMarkerUrlFromPublicDocumentsExist
          ) {
            return {
              value: pickupPoint.value,
              label: pickupPoint.label,
              data: {
                ...pickupPoint.data,
                icon: urls.markerUrlFromPublicDocument,
                selectedIcon: urls.selectedMarkerUrlFromPublicDocument,
              },
            };
          }

          if (markerUrlFromPublicDocumentExist) {
            return {
              value: pickupPoint.value,
              label: pickupPoint.label,
              data: {
                ...pickupPoint.data,
                icon: urls.markerUrlFromPublicDocument,
                selectedIcon: defaultSelectedMarkerUrlFromPublicDocumentsExist
                  ? urls.defaultSelectedMarkerUrl
                  : undefined,
              },
            };
          }

          if (selectedMarkerUrlFromPublicDocumentsExist) {
            return {
              value: pickupPoint.value,
              label: pickupPoint.label,
              data: {
                ...pickupPoint.data,
                icon: defaultMarkerUrlFromPublicDocumentExist
                  ? urls.defaultMarkerUrl
                  : undefined,
                selectedIcon: urls.selectedMarkerUrlFromPublicDocument,
              },
            };
          }

          return {
            value: pickupPoint.value,
            label: pickupPoint.label,
            data: {
              ...pickupPoint.data,
              icon: defaultMarkerUrlFromPublicDocumentExist
                ? urls.defaultMarkerUrl
                : undefined,
              selectedIcon: defaultSelectedMarkerUrlFromPublicDocumentsExist
                ? urls.defaultSelectedMarkerUrl
                : undefined,
            },
          };
        }),
      );

      setAdaptedPickupPoints(result);
      setIsLoading(false);
    };

    if (adaptedPickupPointOptions && adaptedPickupPointOptions.length > 0) {
      call();
    }
  }, [adaptedPickupPointOptions, sellerId]);

  return {
    isLoading,
    adaptedPickupPointOptions: adaptedPickupPoints,
  };
};

export default useMapWithControlledZoom;
