import React, { FC, Suspense } from 'react';

import AppointmentCalendarContainerSkeleton from '../../TakeInterventionAppointmentContainer/TakeInterventionAppointmentContainer.skeleton';

import { AppointmentCalendarContainer } from './AppointmentCalendarContainer';
import { TakeInterventionAppointmentContextProvider } from './TakeInterventionAppointment.context';
import { useInterventionAppointment } from './TakeInterventionAppointment.hooks';

interface InterventionAppointmentContentProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  isLoading?: boolean;
}

const TakeInterventionAppointment: FC<
  React.PropsWithChildren<InterventionAppointmentContentProps>
> = ({ children, fileId, sellerId, onSubmit, isLoading = false }) => {
  const { detailedSchedules } = useInterventionAppointment({
    fileId,
    onSubmit,
    sellerId,
  });

  return (
    <TakeInterventionAppointmentContextProvider
      detailedSchedules={detailedSchedules}
    >
      <Suspense fallback={<AppointmentCalendarContainerSkeleton />}>
        <AppointmentCalendarContainer
          fileId={fileId}
          sellerId={sellerId}
          isLoading={isLoading}
          onSubmit={onSubmit}
        >
          {children}
        </AppointmentCalendarContainer>
      </Suspense>
    </TakeInterventionAppointmentContextProvider>
  );
};

TakeInterventionAppointment.displayName = 'TakeInterventionAppointment';

export default TakeInterventionAppointment;
