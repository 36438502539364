import { useContext } from 'react';
import { useQuery } from 'react-query';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { TransportPlan } from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { StrictAutocompleteOption } from '@savgroup-front-common/types';

import { CarrierService } from '../../../../../../../api';
import { InitContext } from '../../../../../../app/NewLayout/InitProvider/InitProvider.context';
import { IrshStepValues } from '../../../../IrshPages.types';

export const GET_PICKUP_POINT_BY_GEO_CORD_QUERY =
  'getPickupPointByGeoCoordQuery';

export const usePickupPointOptions = ({
  availableMethod,
  searchAddress,
}: {
  availableMethod: StrictAutocompleteOption<TransportPlan[]>;
  searchAddress?: {
    address?: string;
    countryCode?: string;
  };
}) => {
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();

  const { claims } = values;

  const claimId = claims?.at(0)?.claimId;

  const { orders } = useContext(InitContext);

  const sellerId = orders?.at(0)?.sellerId;

  const { data: pickupPoints, isLoading } = useQuery(
    [
      GET_PICKUP_POINT_BY_GEO_CORD_QUERY,
      {
        sellerId,
        claimId,
        searchAddress,
      },
    ],
    async () => {
      if (!sellerId || !claimId) {
        return [];
      }

      const responses = await Promise.all(
        availableMethod?.data?.map(async (transportPlan) => {
          return {
            response: await CarrierService.getPickupPointByGeoCoordQuery({
              address: searchAddress?.address ?? '',
              countryCode:
                searchAddress?.countryCode ?? (COUNTRY_CODES.FR as string),
              maxPickupPoint: 10,
              transportPlanId: transportPlan.transportPlanId,
              sellerId,
              claimId,
              distance: 200,
            }),
            transportPlan,
          };
        }),
      );

      if (responses.some((response) => !response.response.failure)) {
        return responses
          .map((response) => {
            if (response.response.failure) {
              return [];
            }

            const pickupPoints = response.response.value;

            return pickupPoints.map((pickupPoint) => ({
              value: pickupPoint.id,
              label: pickupPoint.name,
              data: {
                pickupPoint,
                transportPlan: response.transportPlan,
              },
            }));
          })
          .flat();
      }
    },
    { suspense: true },
  );

  return {
    pickupPoints,
    isLoading,
  };
};
