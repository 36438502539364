import styled from 'styled-components';

export const LinkButton = styled.button<{ color?: string }>`
  color: ${({ theme, color }) => color || theme.colors.linkColor};
  display: inline;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`;

export default LinkButton;
