import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { TransportMethodType } from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  ActorInfos,
  BackResponse,
  BackResponseFailure,
  HandlingSpot,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/handling`;

export interface ClaimContextHandling {
  claimId: string;
  claimGroupId: string;
  productLocationCountryCode?: string;
  hasDeposit: boolean;
  hasDelivery: boolean;
  hasHome: boolean;
  delivery: HandlingSpot;
  deposit: HandlingSpot;
  home: HandlingSpot;
  actorInfos: ActorInfos;
  canDeduceTransportFromRefund: boolean;
  transportPlanId?: string;
  transportPlanCost?: ReverseMoneyNumberAmount;
  useTransportPlan?: boolean;
  transportPlanMethodType: TransportMethodType;
}

interface GetClaimContextHandlingQueryArgs {
  claimId: string;
}

const getClaimContextHandlingQuery = async ({
  claimId,
}: GetClaimContextHandlingQueryArgs): Promise<
  BackResponse<ClaimContextHandling> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId);

    return await apiCall<BackResponse<ClaimContextHandling>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getClaimContextHandlingQuery;
