import styled from 'styled-components';

export const $ChooseItemCardAddress = styled.div`
  text-align: left;
  line-height: 1.3rem;
  & * {
    color: ${({ theme }) => theme.colors.mainTextColor};
  }
`;

export const $AddressLine = styled.div`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;
