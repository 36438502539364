import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { BaseBackResponse } from '@savgroup-front-common/types';

import { getCurrentLanguage } from '../helpers/intl';

import APIClient from './APIClient';
import { userManager } from './userManager';

export interface Config {
  json?: any;
  body?: any;
  method: SUPPORTED_METHODS;
  responseType?: string;
  timeout?: number;
  signal?: AbortSignal;
}

const apiCall = async <T = BaseBackResponse>(
  url: string,
  config: Config,
): Promise<T> => {
  if (typeof url !== 'string') {
    throw new Error(`Url should be string got: ${typeof url}`);
  }

  if (!userManager) {
    return APIClient({
      url,
      config,
      currentLanguage: getCurrentLanguage(),
      authToken: undefined,
    });
  }

  let accessToken = null;

  const user = await userManager.getUser();

  if (user && !user.expired) {
    accessToken = user.access_token;
  } else {
    logVerbose('User is not logged in');
  }

  return APIClient({
    url,
    config,
    currentLanguage: getCurrentLanguage(),
    authToken: accessToken,
  });
};

export default apiCall;
