import {
  SigninRedirectArgs,
  SignoutRedirectArgs,
  UserManager,
  UserManagerSettings,
} from 'oidc-client-ts';

import { AuthConfiguration } from '@savgroup-front-common/types';

import { getInitialLocale } from '../helpers';

export let userManager: UserManager;

const buildUserManagerSettings = (
  authConfiguration: AuthConfiguration,
): UserManagerSettings => {
  const url = window.location.origin;

  const silent_redirect_uri = `${url}/silent-renew`;
  const post_logout_redirect_uri = `${url}/index`;

  return {
    client_id: authConfiguration.clientId,
    redirect_uri: `${url}/callback`,
    response_type: 'code',
    response_mode: 'query',
    scope: authConfiguration.scope,
    authority: authConfiguration.authority,
    post_logout_redirect_uri,
    silent_redirect_uri,
    loadUserInfo: false,
    filterProtocolClaims: true,
    revokeTokensOnSignout: true,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTimeInSeconds: 10 * 60,
    staleStateAgeInSeconds: 60,
    ui_locales: getInitialLocale(),
    monitorSession: false,
  };
};

export const generateUserManager = (authConfiguration: AuthConfiguration) => {
  userManager = new UserManager(buildUserManagerSettings(authConfiguration));

  return userManager;
};

export const login = async (args?: SigninRedirectArgs) =>
  userManager.signinRedirect(args);
export const logout = async (args?: SignoutRedirectArgs) =>
  userManager.signoutRedirect(args);
export const getUser = () => userManager.getUser();

export default (authConfiguration: AuthConfiguration) => {
  return {
    userManagerConfig: () => buildUserManagerSettings(authConfiguration),
    userManager: generateUserManager(authConfiguration),
  };
};
