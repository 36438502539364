import React from 'react';
import { FormProvider } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import { useMedia } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import SwipeableDrawer from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { PickupPoint } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import useGetPickupPoints from 'myaccount/view/app/hooks/useGetPickupPoints';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import { FINAL_ROUTES } from '../../../app';
import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { AddressManagement } from '../../../components/AddressManagement';
import HandlingCard from '../../../components/HandlingCard';
import PickupPointSelector from '../../../components/PickupPointSelector';
import { ResumeSolution } from '../../../components/ResumeSolution';
import { IrshStepValues } from '../IrshPages.types';

import messages from './messages';
import useClaimGroupDepositPage from './NewClaimGroupDepositPage.hooks';
import {
  $DepositItemWrapper,
  $DepositResultWrapper,
  $DepositWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from './NewClaimGroupDepositPage.styles';

export const NewClaimGroupDepositPage: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onNextStep, onPreviousStep }) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const {
    claimGroupConfirmation,
    groupedCarriers,
    claimGroupId,
    formContext,
    setChangedValue,
    changeSelection,
    onSubmit,
    handling,
    isDepositHandlingLoading,
    isLoadingSubmit,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
    openModalRelayPointDeposit,
    openModalHomeDeposit,
    handleOpenDepositModal,
  } = useClaimGroupDepositPage({
    onNextStep,
  });

  const hasHome = handling?.hasHome;

  const {
    setValue,
    formState: { isValid },
  } = formContext;

  const {
    sellerId,
    carriers,
    addressSelected,
    pickupPoints,
    noPickupPoints,
    addresses,
    handleSetAddressSelected,
    selectedPickupPoint,
    pickupPointsIsLoading,
  } = useGetPickupPoints({
    selectedCarrierType: chooseHandlingSelected,
    groupedCarriers,
    claimGroupId,
  });

  const handleSetSelectedPickupPoint = async (
    pickupPointSelected?: PickupPoint,
  ) => {
    if (!pickupPointSelected) {
      return;
    }

    Promise.resolve(setValue('pickupPointSelected', pickupPointSelected)).then(
      () => onSubmit(),
    );
  };

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <NewStepLayout stepName={STEP_CONFIG.CLAIM_GROUP_DEPOSIT}>
            <$DepositWrapper>
              <$LeftColumn>
                <ResumeSolution
                  claimGroupId={claimGroupId}
                  claimGroupConfirmation={claimGroupConfirmation}
                  customBuildUpdateSolutionUrl={generatePath(
                    FINAL_ROUTES.CLAIM_GROUP_SOLUTION,
                    {
                      claimGroupId,
                    },
                  )}
                />

                <$Heading data-testid="handling-deposit-title">
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.DEPOSIT_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={
                      handling?.hasHome
                        ? messages.handlingTitleOnlyHome
                        : messages.handlingTitle
                    }
                  />
                </$Heading>

                <$DepositItemWrapper>
                  {isDepositHandlingLoading && <BaseLoader />}{' '}
                  {!isDepositHandlingLoading && (
                    <HandlingCard
                      groupedCarriers={groupedCarriers}
                      setChangedValue={setChangedValue}
                      hasHome={hasHome}
                      handleOpenModal={handleOpenDepositModal}
                    />
                  )}
                </$DepositItemWrapper>
              </$LeftColumn>

              <$DepositResultWrapper>
                {isMobileView && (
                  <ResumeCardButton
                    claimGroupConfirmation={claimGroupConfirmation}
                  />
                )}
                {!isMobileView && (
                  <ResumeCard
                    claimGroupConfirmation={claimGroupConfirmation}
                    customLinkModify={generatePath(
                      FINAL_ROUTES.CLAIM_GROUP_REASON,
                      {
                        claimGroupId,
                      },
                    )}
                  />
                )}
              </$DepositResultWrapper>
            </$DepositWrapper>
          </NewStepLayout>

          <PageFooterContainer>
            <Button
              primary
              type={BUTTON_TYPES.BUTTON}
              icon={<ArrowNakedRightIcon />}
              position={SUPPORTED_ICON_POSITIONS.RIGHT}
              disabled={!isValid}
              isLoading={isLoadingSubmit}
              onClick={onSubmit}
              dataTestId="nextButton"
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.nextButton}
              />
            </Button>

            <Button
              type={BUTTON_TYPES.BUTTON}
              secondary
              naked
              onClick={() => {
                onPreviousStep({});
              }}
              icon={<ArrowNakedLeftIcon />}
              position={SUPPORTED_ICON_POSITIONS.LEFT}
              dataTestId="previousButton"
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.previousButton}
              />
            </Button>
          </PageFooterContainer>

          <SwipeableDrawer
            isOpen={handlingSelected || openModalRelayPointDeposit}
            onClose={changeSelection}
            hasBackdrop
            hasPadding={false}
            hasBackdropScroll={false}
            hasCloseButton={false}
          >
            <PickupPointSelector
              maxDistance={200}
              pickupPoints={pickupPoints}
              selectedPickupPointId={
                selectedPickupPoint ? selectedPickupPoint.id : undefined
              }
              onSelectPickupPoint={handleSetSelectedPickupPoint}
              searchAddress={addressSelected}
              userAddresses={addresses}
              onAddressSelected={handleSetAddressSelected}
              carriers={carriers}
              onClose={changeSelection}
              pickupPointsIsLoading={pickupPointsIsLoading}
              sellerId={sellerId}
              noPickupPoints={noPickupPoints}
            />
          </SwipeableDrawer>

          <SwipeableDrawer
            isOpen={homeSelectedAddressChanged || openModalHomeDeposit}
            onClose={changeSelection}
            hasBackdrop
            hasBackdropScroll={false}
          >
            <AddressManagement onSubmit={onSubmit} />
          </SwipeableDrawer>
        </form>
      </FormProvider>
    </>
  );
};
