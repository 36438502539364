import { useQuery } from 'react-query';

import { GetPickupPointByGeoCoordQueryArgs } from '@savgroup-front-common/core/src/api/Carrier/getPickupPointByGeoCoordQuery';
import { PickupPoint } from '@savgroup-front-common/types';
import { CarrierService } from 'myaccount/api';

export const GetMultiPickupPoints = 'getMultiPickupPoints';

export const useGetMultiPickupPoints = ({
  pickupsArg,
}: {
  pickupsArg?: GetPickupPointByGeoCoordQueryArgs[];
}) => {
  const { data: pickupPointsAddresses, isLoading } = useQuery(
    [GetMultiPickupPoints, { pickupsArg }],
    async () => {
      if (!pickupsArg || pickupsArg.length === 0) {
        return undefined;
      }

      return Promise.all(
        pickupsArg?.map(async (pickupArg) => {
          const response = await CarrierService.getPickupPoints(pickupArg);

          if (response.failure) {
            return undefined;
          }

          return response.value.map((pickUp) => ({
            ...pickUp,
            carrierCompany: pickupArg.carrierCompany,
            carrierName: pickupArg.carrierName,
          }));
        }),
      ).then(
        (value) =>
          value.filter((pickupPoint) => pickupPoint) as PickupPoint[][],
      );
    },
    { staleTime: Infinity },
  );

  return {
    pickupPointsAddresses,
    isLoading,
  };
};
