import React from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { AddressInfoDto } from '@savgroup-front-common/types';

import { $Button } from '../AvailableHandlingMethods/ChooseHandlingMethod/ChooseHandlingMethod.styles';
import messages from '../AvailableHandlingMethods/ChooseHandlingMethod/messages';

import {
  $AddressLine,
  $ChooseItemCardAddress,
} from './DisplayHomeAddress.styles';

interface ChooseItemCardAddressProps {
  address?: AddressInfoDto;
  onModifyClick: () => void;
}

const DisplayHomeAddress: React.FC<ChooseItemCardAddressProps> = ({
  address,
  onModifyClick,
}) => {
  return (
    <$ChooseItemCardAddress>
      <div>
        <b>
          {address?.firstname} {address?.lastname}
        </b>
      </div>
      <$AddressLine>
        {address?.address} {address?.postalCode} {address?.city}
      </$AddressLine>
      <$Button type={BUTTON_TYPES.BUTTON} naked onClick={() => onModifyClick()}>
        <SafeFormattedMessageWithoutSpread message={messages.modify} />
      </$Button>
    </$ChooseItemCardAddress>
  );
};

export default DisplayHomeAddress;
