import { lighten } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { rem } from '@savgroup-front-common/core/src/helpers';

interface ChooseProductCardProps {
  isChecked?: boolean;
  isDisabled: boolean;
  isInProgress?: boolean;
  for?: string;
}

export const $RowProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 24px 16px;
  @media ${media.minWidth.sm} {
    align-items: stretch;
  }
`;

export const $ColonProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 16px;

  ${$RowProductInfo} {
    padding: 0;
  }
`;

interface ChooseProductCardCheckboxContainerProps {
  isChecked?: boolean;
}

export const $ChooseProductCardCheckboxContainer = styled(
  'div',
)<ChooseProductCardCheckboxContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 20px;
  height: 20px;
  @media ${media.minWidth.sm} {
    width: 24px;
    height: 24px;
  }
  border-radius: 17px;
  align-self: center;
  box-sizing: border-box;
`;

export const $ChooseProductCardImageContainer = styled(
  'div',
)<ChooseProductCardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  margin-right: 16px;
  margin-left: ${({ isDisabled }) => (isDisabled ? '40px' : 'unset')};
  padding: 2px;
  overflow: hidden;
  img {
    object-fit: contain;
    max-height: ${rem('70px')};
    max-width: ${rem('70px')};
  }
  @media ${media.minWidth.sm} {
    width: 70px;
    height: 70px;
  }
`;

export const $ChooseProductCardInfoContainer = styled(
  'div',
)<ChooseProductCardProps>`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.paragraphTextColor : theme.colors.black};
  span:last-child {
    font-weight: bold;
  }
`;

export const $OpenFile = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  a,
  button {
    color: ${({ theme }) => theme.colors.mainTextColor};
    text-decoration-color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
  }
  svg {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.mainTextColor};
  }
`;

export const $ChooseProductCardContainer = styled(
  'label',
)<ChooseProductCardProps>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: flex-start;
  background: ${({ theme, isDisabled, isInProgress, isChecked }) => {
    if (isInProgress) {
      return lighten(0.02, theme.colors.secondaryColor2);
    }
    if (isDisabled) {
      return theme.colors.secondaryColor2;
    }
    if (isChecked) {
      return theme.newUI.variationColors.light7;
    }

    return theme.colors.white;
  }};
  border-top: 1px solid #e1e4e8;
  border-left: 1px solid #e1e4e8;
  border-right: 1px solid #e1e4e8;

  cursor: ${({ isDisabled }) => {
    if (isDisabled) {
      return 'default';
    }

    return 'pointer';
  }};

  input {
    width: 100%;
    height: 100%;
  }

  input:hover {
    ~ ${$RowProductInfo} {
      background: ${({ theme }) =>
        theme.colors.primaryHover || lighten(0.52, theme.colors.primary)};
    }
  }

  span[class*='Checkboxstyles'] {
    display: none;
  }

  &:last-child {
    border-bottom: 1px solid #e1e4e8;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
