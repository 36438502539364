import 'intersection-observer-polyfill/index.global';
import React, { FC } from 'react';
import ReactIntersectionObserver, {
  IntersectionObserverProps,
} from 'react-intersection-observer';

export const VisibilityObserver: FC<IntersectionObserverProps> = ({
  children,
  ...restProps
}) => {
  return (
    <ReactIntersectionObserver {...restProps}>
      {children}
    </ReactIntersectionObserver>
  );
};
