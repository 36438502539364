import { defineMessages } from 'react-intl';

export default defineMessages({
  startingFrom: {
    id: 'components.myaccount.claims.handling.cards.startingFrom',
    defaultMessage: 'starting from {price}',
  },

  openingHours: {
    id: 'components.myaccount.claims.handling.pickup.openingHours',
    defaultMessage: 'Opening hours',
  },

  close: {
    id: 'components.myaccount.claims.handling.pickup.close',
    defaultMessage: 'Close',
  },
  deductibleFromRefund: {
    id: 'view.addressHandling.deductibleFromRefund',
    defaultMessage: 'deductible from refund',
  },
});
