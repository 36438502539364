import React from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { AddressInfo } from '@savgroup-front-common/types';

import { $Button } from './ChooseItemCard.styles';
import messages from './messages';

interface ChooseItemCardAddressProps {
  address: AddressInfo;
  value: any;
  handleOnChange: (value: unknown) => void;
}

const ChooseItemCardAddress: React.FC<ChooseItemCardAddressProps> = ({
  address,
  value,
  handleOnChange,
}) => {
  return (
    <>
      <div>
        <b>
          {address?.firstName} {address?.lastName}
        </b>
      </div>
      <div>
        {address?.address} {address?.postalCode} {address?.city}
      </div>
      <$Button
        type={BUTTON_TYPES.BUTTON}
        naked
        onClick={() => handleOnChange(value)}
      >
        <SafeFormattedMessageWithoutSpread message={messages.modify} />
      </$Button>
    </>
  );
};

export default ChooseItemCardAddress;
