import React from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { PickupPointOption } from '../../IrshPages.types';
import { $Button } from '../AvailableHandlingMethods/ChooseHandlingMethod/ChooseHandlingMethod.styles';
import messages from '../AvailableHandlingMethods/ChooseHandlingMethod/messages';
import {
  $AddressLine,
  $ChooseItemCardAddress,
} from '../DisplayHomeAddress/DisplayHomeAddress.styles';

interface DisplayPickupPointAddressProps {
  pickupPointOption: PickupPointOption;
  onModifyClick: () => void;
}

export const DisplayPickupPointAddress: React.FC<
  DisplayPickupPointAddressProps
> = ({ pickupPointOption, onModifyClick }) => {
  const pickupPoint = pickupPointOption?.data?.pickupPoint;

  if (!pickupPoint) {
    return undefined;
  }

  return (
    <$ChooseItemCardAddress>
      <div>
        <b>{pickupPoint?.name}</b>
      </div>
      <$AddressLine>
        {pickupPoint?.adress} {pickupPoint?.postalCode} {pickupPoint?.city}
      </$AddressLine>
      <$Button type={BUTTON_TYPES.BUTTON} naked onClick={() => onModifyClick()}>
        <SafeFormattedMessageWithoutSpread message={messages.modify} />
      </$Button>
    </$ChooseItemCardAddress>
  );
};
