import { add, format, parse } from 'date-fns';
import get from 'lodash/get';
import { v4 } from 'uuid';

import {
  FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
  STANDARD_DATE_FORMAT_DATE_FNS,
} from '@savgroup-front-common/constants';
import { Timeslot } from '@savgroup-front-common/types';

import { TimeslotFromBack } from '../../../../../api/Workflow/getTechnicalInterventionTimeSlots';
import { DetailedSchedule } from '../../../../AppointmentCalendar/types/DetailedSchedule';

const adaptTimeslotToDetailedSchedule = (
  rawTimeslots: TimeslotFromBack[],
  { pageNumber, pageSize }: { pageNumber: number; pageSize: number },
): DetailedSchedule[] => {
  const groupedTimeslots = rawTimeslots.reduce<{ [date: string]: Timeslot[] }>(
    (acc, rawTimeslot) => {
      const key = format(
        parse(
          rawTimeslot.start,
          FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
          new Date(),
        ),
        STANDARD_DATE_FORMAT_DATE_FNS,
      );

      const timeslot = {
        internalId: v4(),
        start: parse(
          rawTimeslot.start,
          FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
          new Date(),
        ),
        end: parse(
          rawTimeslot.finish,
          FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
          new Date(),
        ),
      };

      return {
        ...acc,
        [key]: [...get(acc, key, []), timeslot],
      };
    },
    {},
  );

  if (Object.keys(groupedTimeslots).length === pageSize) {
    return Object.entries(groupedTimeslots).map(([date, timeslots]) => ({
      date: parse(date, STANDARD_DATE_FORMAT_DATE_FNS, new Date()),
      timeslots,
    }));
  }

  const startDate = add(new Date(), {
    days: 1,
  }).setHours(0, 0, 0);

  return new Array(pageSize).fill(null).map((_, index) => {
    const date = add(startDate, {
      days: (pageNumber - 1) * pageSize + index,
    });

    return {
      date,
      timeslots:
        groupedTimeslots[format(date, STANDARD_DATE_FORMAT_DATE_FNS)] || [],
    };
  });
};

export default adaptTimeslotToDetailedSchedule;
