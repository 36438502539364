import React, { FunctionComponent, useCallback, useRef, useState } from 'react';

interface ImgProps {
  urls: string[];
  alt: string;
}

const Img: FunctionComponent<ImgProps> = ({ urls, alt }) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const [listAlternatives, setListAlternatives] = useState(
    urls.filter((item) => item),
  );
  const [firstAlternative, ...rest] = listAlternatives;

  const onError = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.onerror = rest.length > 0 ? onError : null;
      imgRef.current.src = firstAlternative;

      setListAlternatives(rest);
    }
  }, [firstAlternative, rest]);

  return (
    <img ref={imgRef} alt={alt} src={firstAlternative} onError={onError} />
  );
};

export default Img;
