import getFullOwnerProductQuery from './getFullOwnerProductQuery';
import { setOwnerLanguagePreference } from './setOwnerLanguagePreference';
import { getVideoSessionQuery } from './video/getVideoSessionQuery';

const CommonOwnerService = {
  getFullOwnerProductQuery,
  getVideoSessionQuery,
  setOwnerLanguagePreference,
};

export default CommonOwnerService;
