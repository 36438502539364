import {
  CheckCircle,
  InfoCircle,
  PriorityHigh,
  XmarkCircle,
} from 'iconoir-react';
import React, { ReactNode } from 'react';
import { toast as toastComponent, ToastOptions } from 'react-toastify';
import { OnChangeCallback } from 'react-toastify/dist/core';

import { SafeFormattedMessage } from '../../formatters';

import { ToastBody } from './Components';
import { $VerticalBar } from './Components/ToastBody/ToastBody.styles';
import { noticeOptions, toastOptions } from './Toast.config';
import { ReversToastOptions, ToastContent } from './Toast.types';

const adaptOptions = (
  defaultOptions: ReversToastOptions,
  options: ReversToastOptions,
  icon: ReactNode,
): ToastOptions => {
  const merged = {
    ...defaultOptions,
    ...options,
  };

  const { showIcon, ...mergedRest } = merged;

  const returnValue: ToastOptions = mergedRest;

  if (showIcon === true) {
    returnValue.icon = icon;
  }

  return returnValue;
};

const constructToast = (defaultOptions: ReversToastOptions) => {
  const BridgeToast = (
    content: ToastContent,
    options: ReversToastOptions = {},
  ) =>
    toastComponent(
      SafeFormattedMessage(content),
      adaptOptions(
        defaultOptions,
        options,
        <>
          <$VerticalBar />
          <InfoCircle />
        </>,
      ),
    );

  const ToastConfig =
    (
      icon: ReactNode,
      fn: (
        content: ReactNode,
        options?: ToastOptions | undefined,
      ) => string | number,
    ) =>
    (content: ToastContent, options: ReversToastOptions = {}) =>
      fn(
        <ToastBody content={content} options={options} />,
        adaptOptions(defaultOptions, options, icon),
      );

  BridgeToast.info = ToastConfig(
    <>
      <$VerticalBar />
      <InfoCircle />
    </>,

    toastComponent.info,
  );
  BridgeToast.success = ToastConfig(
    <>
      <$VerticalBar />
      <CheckCircle />
    </>,

    toastComponent.success,
  );
  BridgeToast.warn = ToastConfig(
    <>
      <$VerticalBar />
      <PriorityHigh />
    </>,
    toastComponent.warn,
  );
  BridgeToast.error = ToastConfig(
    <>
      <$VerticalBar />
      <XmarkCircle />
    </>,

    toastComponent.error,
  );

  BridgeToast.isActive = (toastId: string | number) =>
    toastComponent.isActive(toastId);
  BridgeToast.dismiss = (toastId: string | number) =>
    toastComponent.dismiss(toastId);
  BridgeToast.update = (toastId: string | number, options: ToastOptions) =>
    toastComponent.update(toastId, options);

  BridgeToast.onChange = (callback: OnChangeCallback) =>
    toastComponent.onChange(callback);
  BridgeToast.done = (toastId: string | number) => toastComponent.done(toastId);

  return BridgeToast;
};

export const toast = constructToast(toastOptions);
export const notice = constructToast(noticeOptions);
