import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  HANDLING_MODES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { buildUrl, prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/transportPlan`;

export interface GetClaimGroupTransportPlanQueryArgs {
  countryCode?: string;
  zipCode?: string;
  claimGroupId: string;
}

export enum Carriers {
  UPS = 'UPS',
  Chronopost = 'Chronopost',
  External = 'External',
  Colissimo = 'Colissimo',
  MondialRelay = 'MondialRelay',
  DPD = 'DPD',
  SwissPost = 'SwissPost',
  Teliae = 'Teliae',
  Warning = 'Warning',
  Vir = 'Vir',
  Geodis = 'Geodis',
  GLS = 'GLS',
}

export enum CarrierCompany {
  Chronopost = 'Chronopost',
  Colissimo = 'Colissimo',
  Stuart = 'Stuart',
  ExternalCarrier = 'ExternalCarrier',
  UPS = 'UPS',
  Amazon = 'Amazon',
  CorreosExpress = 'CorreosExpress',
  DBSchenker = 'DBSchenker',
  DHL = 'DHL',
  DPD = 'DPD',
  Exapaq = 'Exapaq',
  Geodis = 'Geodis',
  GLS = 'GLS',
  MondialRelayLD1 = 'MondialRelayLD1',
  MondialRelayLDS = 'MondialRelayLDS',
  NACEX = 'NACEX',
  Socolissimo = 'Socolissimo',
  TNT = 'TNT',
  TransporteurPrivé = 'TransporteurPrivé',
  MondialRelay = 'MondialRelay',
  SwissPost = 'SwissPost',
  Teliae = 'Teliae',
  Warning = 'Warning',
  Tamdis = 'Tamdis',
  Vir = 'Vir',
}

export enum TransportMethodType {
  OnSiteCollection = 'OnSiteCollection',
  DropAtStoreDeposit = 'DropAtStoreDeposit',
  RelayPointDeposit = 'RelayPointDeposit',
  ImmediateDropAtStoreDeposit = 'ImmediateDropAtStoreDeposit',
  HomePickup = 'HomePickup',
  LabelProvidedBySeller = 'LabelProvidedBySeller',
  HomePickupProvidedBySeller = 'HomePickupProvidedBySeller',
  LabelProvidedByCustomer = 'LabelProvidedByCustomer',
  HomeDelivery = 'HomeDelivery',
  PickUpStoreDelivery = 'PickUpStoreDelivery',
  BulkyHomeDelivery = 'BulkyHomeDelivery',
  PickUpRelayPointDelivery = 'PickUpRelayPointDelivery',
  HomeIntervention = 'HomeIntervention',
}

export enum CarrierDimensionType {
  XS = 'XS',
  XL = 'XL',
}

export enum HandlingProcedure {
  Transport = 'Transport',
  Destruction = 'Destruction',
}

export interface TransportPlan {
  transportPlanId: string;
  transportPlanUniqueName: string;
  carrier: Carriers;
  carrierForTms: CarrierCompany;
  carrierDimensionType: CarrierDimensionType;
  handlingMode: HANDLING_MODES;
  weight: number;
  price: number;
  priceWithTax: number;
  currencyCode: string;
  transportMethod: TransportMethodType;
  carrierTransportMethodUniqueName: string;
  handlingProcedure: HandlingProcedure;
  skipLabelCreation: boolean;
  isScheduledNeeded: boolean;
  isDeductible?: boolean;
}

export async function getClaimGroupTransportPlanQuery({
  claimGroupId,
  zipCode,
  countryCode,
}: GetClaimGroupTransportPlanQueryArgs): Promise<
  BackResponse<TransportPlan[]> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    const response = await apiCall<BackResponse<TransportPlan[]>>(
      buildUrl(url, { zipCode, countryCode }),
      { method: SUPPORTED_METHODS.GET },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
