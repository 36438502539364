import { FC, PropsWithChildren, ReactElement } from 'react';
import { createPortal } from 'react-dom';

interface ExternalPortalProps {
  portalId?: string;
}

export const ExternalPortal: FC<PropsWithChildren<ExternalPortalProps>> = ({
  children,
  portalId = 'externalPortal',
}): ReactElement => {
  const portalIdFormatted = portalId.replace('/', '-');
  let element = document.querySelector(`#${portalIdFormatted}`);

  if (!element) {
    element = document.createElement('div');
    element.id = portalIdFormatted;

    document.body.appendChild(element);
  }

  return createPortal(children, element);
};
