import { DeliveryTruck } from 'iconoir-react';
import React, { FC } from 'react';

import PictureCalendar from '../../atoms/Logo/PictureCalendar';
import Workshop from '../../atoms/Logo/Workshop';
import { SafeFormattedMessageWithoutSpread } from '../../formatters';

import messages from './messages';
import {
  $CenteredTitle,
  $Col,
  $Footer,
  $Row,
  $TakeHomePickupAppointmentHeader,
  $Title,
} from './TakeHomePickupAppointmentHeader.styles';

export const TakeHomePickupAppointmentHeader: FC = () => {
  return (
    <$TakeHomePickupAppointmentHeader>
      <$CenteredTitle>
        <SafeFormattedMessageWithoutSpread message={messages.howDoesItWorks} />
      </$CenteredTitle>

      <$Row>
        <$Col xs={12} sm={4}>
          <PictureCalendar />
          <$Footer>
            <div>1</div>
            <div>
              <$Title>
                <SafeFormattedMessageWithoutSpread
                  message={messages.takeAppointment}
                />
              </$Title>
              <div>
                <SafeFormattedMessageWithoutSpread
                  message={messages.takeAppointmentDidactic}
                />
              </div>
            </div>
          </$Footer>
        </$Col>
        <$Col xs={12} sm={4}>
          <DeliveryTruck width={70} height={70} />
          <$Footer>
            <div>2</div>
            <div>
              <$Title>
                <SafeFormattedMessageWithoutSpread
                  message={messages.handling}
                />
              </$Title>
              <div>
                <SafeFormattedMessageWithoutSpread
                  message={messages.handlingDidactic}
                />
              </div>
            </div>
          </$Footer>
        </$Col>
        <$Col xs={12} sm={4}>
          <Workshop />
          <$Footer>
            <div>3</div>
            <div>
              <$Title>
                <SafeFormattedMessageWithoutSpread
                  message={messages.takeAppointmentDone}
                />
              </$Title>
              <div>
                <SafeFormattedMessageWithoutSpread
                  message={messages.takeAppointmentDoneDidactic}
                />
              </div>
            </div>
          </$Footer>
        </$Col>
      </$Row>

      <$CenteredTitle>
        <SafeFormattedMessageWithoutSpread message={messages.title} />
      </$CenteredTitle>
    </$TakeHomePickupAppointmentHeader>
  );
};

TakeHomePickupAppointmentHeader.displayName = 'TakeHomePickupAppointmentHeader';
