import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES, CURRENCIES } from '@savgroup-front-common/constants';
import { TransportPlan } from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import PickupPointItemInfo from '@savgroup-front-common/core/src/components/PickupPointItemInfo/PickupPointItemInfo';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign';
import { ICONS_TYPE, MessageType } from '@savgroup-front-common/types';

import { PickupPointOption } from '../../../../../../../IrshPages.types';
import { $Button } from '../PickupPointList.styles';

import {
  $ImageContainer,
  $ImageHoveredContainer,
  $ItemContentTextWrapper,
  $ItemContentWrapper,
  $ItemDescription,
  $ItemDistance,
  $ItemHighlighting,
  $ItemHighlightingIcon,
  $ItemLogo,
  $ItemPrice,
  $ItemTitle,
  $RadioCardBody,
  $RadioCardContainer,
  $RadioCardFirstLine,
  $RadioCardInfo,
  $RadioCardLabelContainer,
  $RadioCardSubLine,
} from './ChoosePickupPointCard.styles';
import messages from './messages';

export interface ChooseHandlingCardProps {
  onSelectPickupPoint: () => void;
  pickupPointOption: PickupPointOption;
  isSelected?: boolean;
  isDisabled?: boolean;
  title?: MessageType | string;
  highlighting?: MessageType | string;
  description?: MessageType | string;
  image?: JSX.Element;
  imageHovered?: JSX.Element;
  label?: string | number | MessageType;
  dataTestId?: string;
  value?: string;
  subLine?: ReactNode;
  isHidden?: boolean;
  distance: string;
  price?: number;
  logo?: ReactElement;
  transportPlan?: TransportPlan;
}

export const ChoosePickupPointCard: FC<ChooseHandlingCardProps> = ({
  isSelected = false,
  isDisabled = undefined,
  onSelectPickupPoint,
  pickupPointOption,
  title,
  highlighting,
  description,
  image,
  imageHovered,
  dataTestId,
  subLine,
  distance,
  price,
  logo,
  transportPlan,
}) => {
  const theme = useTheme();

  const [
    isOpenModalPickupPointOpemingHours,
    setIsOpenModalPickupPointOpemingHours,
  ] = useState<boolean>(false);

  return (
    <>
      <$RadioCardLabelContainer
        data-testid={dataTestId}
        disabled={isDisabled}
        onClick={() => onSelectPickupPoint()}
        $isSelected={isSelected}
      >
        <$RadioCardContainer>
          <$RadioCardFirstLine>
            <$RadioCardInfo>
              <div>
                {image && <$ImageContainer>{image}</$ImageContainer>}
                {imageHovered && (
                  <$ImageHoveredContainer>
                    {imageHovered}
                  </$ImageHoveredContainer>
                )}
              </div>
            </$RadioCardInfo>

            <$RadioCardBody hasImage={!!image}>
              <$ItemContentWrapper>
                <$ItemContentTextWrapper>
                  {title && (
                    <$ItemTitle $isSelected={isSelected}>
                      <SafeFormattedMessageWithoutSpread message={title} />
                    </$ItemTitle>
                  )}
                  {highlighting && (
                    <$ItemHighlighting>
                      <$ItemHighlightingIcon>
                        <MyAccountIcon
                          icon={ICONS_TYPE.BASELINE_ECO}
                          color={theme.colors.success}
                        />
                      </$ItemHighlightingIcon>
                      <SafeFormattedMessageWithoutSpread
                        message={highlighting}
                      />
                    </$ItemHighlighting>
                  )}
                  {description && (
                    <$ItemDescription>
                      <SafeFormattedMessageWithoutSpread
                        message={description}
                      />
                    </$ItemDescription>
                  )}

                  <$ItemDistance>
                    <SafeFormattedMessageWithoutSpread message={distance} />
                  </$ItemDistance>

                  {!!price && (
                    <$ItemPrice $hasPrice $hasPickupPointType={false}>
                      {formatAmountWithCurrency(
                        price,
                        CURRENCIES.EUR,
                      )?.toString()}

                      {transportPlan?.isDeductible && (
                        <SafeFormattedMessageWithoutSpread
                          message={messages.deductibleFromRefund}
                        />
                      )}
                    </$ItemPrice>
                  )}

                  <$Button
                    underline
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpenModalPickupPointOpemingHours(true);
                    }}
                    type={BUTTON_TYPES.BUTTON}
                  >
                    {safeFormattedIntlString(messages.openingHours)}
                  </$Button>
                </$ItemContentTextWrapper>

                {logo && <$ItemLogo>{logo}</$ItemLogo>}
              </$ItemContentWrapper>
            </$RadioCardBody>
          </$RadioCardFirstLine>

          {subLine && <$RadioCardSubLine>{subLine}</$RadioCardSubLine>}
        </$RadioCardContainer>
      </$RadioCardLabelContainer>

      <Modal
        size={MODAL_SIZES.SMALL}
        isOpen={isOpenModalPickupPointOpemingHours}
        onClose={() => setIsOpenModalPickupPointOpemingHours(false)}
      >
        <PickupPointItemInfo
          openingHours={pickupPointOption?.data.pickupPoint.openingHours}
        />
        <Button
          primary
          fluid
          hollow
          onClick={() => setIsOpenModalPickupPointOpemingHours(false)}
          type={BUTTON_TYPES.BUTTON}
        >
          <SafeFormattedMessageWithoutSpread message={messages.close} />
        </Button>
      </Modal>
    </>
  );
};
