import React from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { MessageType } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import messages from './messages';
import {
  $IsDeductibleBlock,
  $PriceColumn,
  $SimpleCardAddressName,
  $SimpleCardAddressNameContainer,
  $SimpleCardContainer,
  $SimpleCardDetail,
  $SimpleCardDidactic,
  $SimpleCardIcon,
  $SimpleCardInfoContainer,
  $SimpleCardLink,
  $SimpleCardPrefixTitle,
  $SimpleCardTitle,
} from './SimpleItemCard.styles';

interface SimpleItemCardProps {
  icon?: ICONS_TYPE;
  iconColor?: string;
  prefixTitle: MessageType;
  title?: string | MessageType;
  didactic?: string | MessageType;
  address?: {
    addressName?: string;
    addressStreet: string;
  };
  link?: string | MessageType;
  linkUrl?: string;
  price?: {
    priceValue?: number;
    priceCurrencyCode?: CURRENCIES;
  };
  hasDisplayFreeAmount?: boolean;
  isDeductibleFromRefund?: boolean;
}

const SimpleItemCard: React.FC<SimpleItemCardProps> = ({
  icon,
  iconColor,
  prefixTitle,
  title,
  didactic,
  address,
  link,
  linkUrl,
  price,
  hasDisplayFreeAmount = true,
  isDeductibleFromRefund = false,
}) => {
  return (
    <$SimpleCardContainer>
      <$SimpleCardIcon>
        <MyAccountIcon icon={icon} color={iconColor} />
      </$SimpleCardIcon>
      <div style={{ flex: 1 }}>
        <$SimpleCardInfoContainer>
          <div>
            {prefixTitle && (
              <$SimpleCardPrefixTitle>
                <SafeFormattedMessageWithoutSpread message={prefixTitle} />
              </$SimpleCardPrefixTitle>
            )}
            {title && (
              <$SimpleCardTitle>
                <SafeFormattedMessageWithoutSpread message={title} />
              </$SimpleCardTitle>
            )}
            {didactic && (
              <$SimpleCardDidactic>
                <SafeFormattedMessageWithoutSpread message={didactic} />
              </$SimpleCardDidactic>
            )}
          </div>
          {!!price?.priceValue && (
            <$PriceColumn>
              <$SimpleCardDetail>
                {formatAmountWithCurrency(
                  price?.priceValue,
                  price.priceCurrencyCode || CURRENCIES.EUR,
                )?.toString()}
              </$SimpleCardDetail>
              {isDeductibleFromRefund && (
                <$IsDeductibleBlock>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.deductibleFromRefund}
                  />
                </$IsDeductibleBlock>
              )}
            </$PriceColumn>
          )}
          {!price?.priceValue && hasDisplayFreeAmount && (
            <$SimpleCardDetail>
              <SafeFormattedMessageWithoutSpread message={messages.free} />
            </$SimpleCardDetail>
          )}
        </$SimpleCardInfoContainer>

        {address && (
          <$SimpleCardAddressNameContainer>
            {address.addressName && (
              <$SimpleCardAddressName>
                <span>{address.addressName}</span>
              </$SimpleCardAddressName>
            )}
            <span>{address.addressStreet}</span>
          </$SimpleCardAddressNameContainer>
        )}
        {link && (
          <$SimpleCardLink href={linkUrl}>
            <SafeFormattedMessageWithoutSpread message={link} />
          </$SimpleCardLink>
        )}
      </div>
    </$SimpleCardContainer>
  );
};

export default SimpleItemCard;
