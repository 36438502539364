import { useLocation, useMatch } from 'react-router-dom';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

export const useMatchWithQuery = (url: string | undefined) => {
  const location = useLocation();

  const urlObj = new URL(url ?? '', window.location.origin);
  const pathMatch = useMatch(urlObj.pathname);

  if (!url) {
    return false;
  }

  try {
    if (!pathMatch) {
      return false;
    }

    const currentSearchParams = new URLSearchParams(location.search);
    const targetSearchParams = urlObj.searchParams;

    for (const [key, value] of targetSearchParams.entries()) {
      if (currentSearchParams.get(key) !== value) {
        return false;
      }
    }

    return true;
  } catch (e: any) {
    logVerbose(e.message);

    return false;
  }
};
