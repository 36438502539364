import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { AddressInfoDto } from '@savgroup-front-common/types';

import AddressForm from './AddressForm/AddressForm';
import useGetCountriesOptions from './hooks/useGetCountriesOptions/useGetCountriesOptions';

interface AddressManagementProps {
  onAddressSubmit: ({ address }: { address: AddressInfoDto }) => void;
  defaultAddress?: AddressInfoDto;
}

export const AddressManagement: FC<AddressManagementProps> = ({
  onAddressSubmit,
  defaultAddress,
}) => {
  const intl = useIntl();
  const countryOptions = useGetCountriesOptions(intl.locale);

  return (
    <AddressForm
      defaultAddress={defaultAddress}
      countriesOptions={countryOptions}
      onAddressSubmit={onAddressSubmit}
    />
  );
};
