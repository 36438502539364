import React from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import {
  CURRENCIES,
  HANDLING_UNIQUE_NAME,
  media,
} from '@savgroup-front-common/constants';
import { HandlingSummary } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import {
  SimpleItemCard,
  SimpleItemCardMobile,
} from 'myaccount/view/atoms/SimpleItemCard';

import { getHandlingData } from '../../../helpers';
import { getHandlingCardLabel } from '../../newDesignPages/irshPages/components/AvailableHandlingMethods/helpers/getHandlingCardLabel';

import messages from './messages';

interface ResumeDeliveryProps {
  claimGroupConfirmation?: ClaimGroupConfirmation;
  handlingSummary?: HandlingSummary;
}

const ResumeDelivery: React.FC<ResumeDeliveryProps> = ({
  claimGroupConfirmation,
  handlingSummary,
}) => {
  const isTransportPlanFeatureEnabled = !!handlingSummary;
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();

  const addressName = claimGroupConfirmation?.deliveryAddress?.firstname;
  const address = claimGroupConfirmation?.deliveryAddress?.address;
  const postalCode = claimGroupConfirmation?.deliveryAddress?.postalCode;
  const city = claimGroupConfirmation?.deliveryAddress?.city;
  const addressFormated = `${address} ${postalCode} ${city}`;

  const { label, description } = getHandlingData({
    carrierUniqueName:
      claimGroupConfirmation?.carrierDeliveryTransportMethodUniqueName as HANDLING_UNIQUE_NAME,
  });

  const { label: newLabel, description: newDescription } = getHandlingCardLabel(
    {
      transportMethodType: handlingSummary?.delivery?.transportPlanMethodType,
    },
  );

  const delivery = handlingSummary?.delivery;
  const deliveryAddress = delivery?.address || delivery?.pickupPointAddress;

  if (isTransportPlanFeatureEnabled && delivery) {
    return isMobileView ? (
      <SimpleItemCardMobile
        icon={ICONS_TYPE.DELIVERY_ICON}
        iconColor={theme.colors.primary}
        prefixTitle={messages.deliveryTitle}
        title={newLabel}
        didactic={newDescription}
        address={{
          addressName: delivery?.pickupPointFullName
            ? delivery?.pickupPointFullName
            : `${deliveryAddress?.firstname || ''} ${
                deliveryAddress?.lastname || ''
              }`,
          addressStreet: `${deliveryAddress?.address} ${deliveryAddress?.postalCode} ${deliveryAddress?.city}`,
        }}
        price={{
          priceValue: delivery.transportPlanCostWithTax?.amount,
          priceCurrencyCode: delivery.transportPlanCostWithTax?.currency,
        }}
        isDeductibleFromRefund={delivery.isDeductible}
      />
    ) : (
      <SimpleItemCard
        icon={ICONS_TYPE.DELIVERY_ICON}
        iconColor={theme.colors.primary}
        prefixTitle={messages.deliveryTitle}
        title={newLabel}
        didactic={newDescription}
        address={{
          addressName: delivery?.pickupPointFullName
            ? delivery?.pickupPointFullName
            : `${deliveryAddress?.firstname || ''} ${
                deliveryAddress?.lastname || ''
              }`,
          addressStreet: `${deliveryAddress?.address} ${deliveryAddress?.postalCode} ${deliveryAddress?.city}`,
        }}
        price={{
          priceValue: delivery.transportPlanCostWithTax?.amount,
          priceCurrencyCode: delivery.transportPlanCostWithTax?.currency,
        }}
        isDeductibleFromRefund={delivery.isDeductible}
      />
    );
  }

  return isMobileView ? (
    <SimpleItemCardMobile
      icon={ICONS_TYPE.DELIVERY_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={messages.deliveryTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDeliveryPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDeliveryPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  ) : (
    <SimpleItemCard
      icon={ICONS_TYPE.DELIVERY_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={messages.deliveryTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDeliveryPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDeliveryPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  );
};

export default ResumeDelivery;
