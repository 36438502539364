import React, { ReactElement } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFormContext,
} from 'react-hook-form';

import { EMPTY_UUID } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import { Autocomplete } from '../../../atoms/Form';

interface PossibleValue {
  id: string;
  key: string;
  name: string;
}

interface AdditionalInformationEnumProps {
  id: string;
  name: string | null;
  label?: string | MessageType;
  placeholder?: string;
  isRequired?: boolean;
  postLabel?: string | ReactElement;
  possibleValues: PossibleValue[];
  formContext?: {
    control: Control;
    formState: { errors: FieldErrors };
    warnings?: any;
  };
  onAdditionalInformationSave: () => Promise<void>;
  isScrollIntoView?: boolean;
  suffixOptionButton?: React.ElementType;
  isLabelMultiLineAllowed?: boolean;
}

const AdditionalInformationEnum: React.FC<AdditionalInformationEnumProps> = ({
  id,
  name = null,
  label = null,
  placeholder = null,
  isRequired = false,
  postLabel = null,
  possibleValues = [],
  formContext,
  onAdditionalInformationSave,
  isScrollIntoView = false,
  suffixOptionButton,
  isLabelMultiLineAllowed,
}) => {
  const defaultContext = useFormContext();

  const {
    control,
    formState: { errors },
  } = formContext || defaultContext;
  const warnings = formContext?.warnings || {};

  const options = possibleValues.map(
    ({ id: possibleValueId, name: possibleValueName, key }) => ({
      label: possibleValueName,
      value: possibleValueId === EMPTY_UUID ? key : possibleValueId,
    }),
  );

  return (
    <>
      <Controller
        control={control}
        name={name || ''}
        render={({ field }) => {
          return (
            <Autocomplete
              internalId={id}
              errors={errors}
              warnings={warnings}
              required={isRequired}
              placeholder={placeholder as any}
              label={label as any}
              name={name || ''}
              postLabel={postLabel as any}
              isRequired={isRequired}
              options={options}
              dataTestIdForCypress={`additional-information-input-dropdown-${id}`}
              dataTestId={`data-additional-information-${id}`}
              onChange={async (option) => {
                field.onChange(option);
                await onAdditionalInformationSave();
              }}
              ref={field.ref}
              value={field.value}
              onBlur={field.onBlur}
              isScrollIntoView={isScrollIntoView}
              suffixOptionButton={suffixOptionButton as any}
              isLabelMultiLineAllowed={isLabelMultiLineAllowed}
            />
          );
        }}
      />
    </>
  );
};

export default AdditionalInformationEnum;
