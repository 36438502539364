import React, { Component, ReactNode } from 'react';

import { PickupPointOption } from '../../../../../../IrshPages.types';
import { AddressSelected, Bounds } from '../PickupPointSelector.types';

import { MapWithControlledZoom } from './MapWithControlledZoom';
import { $MapContainer } from './PickupPointMap.styles';
import { VisibilityObserver } from './VisibilityObserver';

interface Location {
  lat: number;
  lng: number;
}

interface IconsPath {
  point: string;
  selectedPoint: string;
  home: string;
}

interface PickupPointMapProps {
  searchAddress: AddressSelected;
  iconsPath?: IconsPath;
  onSelectPickupPoint: (pickupPoint: PickupPointOption) => void;
  pickupPoints: PickupPointOption[];
  selectedPickupPoint?: PickupPointOption;
}

interface BoundInfo {
  bounds: Bounds;
  center: Location;
}

class PickupPointMap extends Component<PickupPointMapProps> {
  static defaultProps: Partial<PickupPointMapProps> = {
    iconsPath: {
      point: '/images/carriers/Logo_Chrono_marker.png',
      selectedPoint: '/images/carriers/Logo_Chrono_marker_active.png',
      home: '/images/Group 14@1x.png',
    },
  };

  static getBounds(
    address: AddressSelected,
    pickupPointOptions: PickupPointOption[],
  ): BoundInfo {
    const bounds: Bounds = {
      north: address.location?.lat ?? 0,
      east: address.location?.lng ?? 0,
      south: address.location?.lat ?? 0,
      west: address.location?.lng ?? 0,
    };

    pickupPointOptions.forEach((pickupPointOption) => {
      const pickupPoint = pickupPointOption.data?.pickupPoint;

      const { latitude: lat, longitude: lng } = pickupPoint;

      if (bounds.north < lat) {
        bounds.north = lat;
      }
      if (bounds.east < lng) {
        bounds.east = lng;
      }

      if (bounds.south > lat) {
        bounds.south = lat;
      }
      if (bounds.west > lng) {
        bounds.west = lng;
      }
    });

    const center: Location = {
      lat: address.location?.lat ?? 0,
      lng: address.location?.lng ?? 0,
    };

    return {
      bounds,
      center,
    };
  }

  render(): ReactNode {
    const { searchAddress, pickupPoints } = this.props;

    if (
      !searchAddress ||
      !searchAddress.location ||
      searchAddress.location.lat === undefined
    ) {
      return null;
    }

    const boundInfo = PickupPointMap.getBounds(searchAddress, pickupPoints);

    return (
      <VisibilityObserver threshold={0}>
        {({ inView, ref }: { inView: boolean; ref: any }) => (
          <$MapContainer ref={ref}>
            <MapWithControlledZoom
              key={`${boundInfo.bounds.north - boundInfo.bounds.south}-${
                boundInfo.bounds.west - boundInfo.bounds.east
              }-${inView}`}
              center={boundInfo.center}
              bounds={boundInfo.bounds}
              searchAddress={this.props.searchAddress}
              selectedPickupPoint={this.props.selectedPickupPoint}
              onSelectPickupPoint={this.props.onSelectPickupPoint}
              pickupPoints={pickupPoints}
            />
          </$MapContainer>
        )}
      </VisibilityObserver>
    );
  }
}

export default PickupPointMap;
