import styled from 'styled-components';

import { rem } from '../../../../helpers';

export const $ToastTitle = styled.div`
  font-weight: bold;
  font-size: ${rem(15)};
  margin-bottom: ${rem(3)};
`;

export const $VerticalBar = styled.div`
  width: 5px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.black};
  margin-right: 8px;
  margin-top: -11px;
  border-radius: ${({ theme }) => theme.newUI.borders.bigRadius};
`;
