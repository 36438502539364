import { defineMessages } from 'react-intl';

export default defineMessages({
  isLoading: {
    id: 'connection.isLoading',
    defaultMessage: 'Loading...',
  },
  isTakingTooLong: {
    id: 'connection.isTakingTooLong',
    defaultMessage: 'There might be an error',
  },
});
