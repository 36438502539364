import React from 'react';

import {
  TransportMethodType,
  TransportPlan,
} from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import {
  AddressInfoDto,
  HandlingSummary,
  PickupPoint,
  StrictAutocompleteOption,
} from '@savgroup-front-common/types';

import { HandlingValues } from '../../IrshPages.types';

import { $AvailableHandlingMethods } from './AvailableHandlingMethods.styles';
import { ChooseHandlingMethod } from './ChooseHandlingMethod/ChooseHandlingMethod';
import getHandlingCardIcon from './helpers/getHandlingCardIcon';

interface HandlingCardProps {
  handlingSummary: HandlingSummary;
  availableMethods: StrictAutocompleteOption<TransportPlan[]>[];
  onSetPickupPoint: ({
    selectedPickupPoint,
    selectedMethod,
  }: {
    selectedPickupPoint?: StrictAutocompleteOption<{
      pickupPoint: PickupPoint;
      transportPlan: TransportPlan;
    }>;
    selectedMethod: StrictAutocompleteOption<TransportPlan[]>;
  }) => void;
  onSetAddress: ({
    address,
    selectedMethod,
  }: {
    address: AddressInfoDto;
    selectedMethod: StrictAutocompleteOption<TransportPlan[]>;
  }) => void;
  handlingValues: HandlingValues;
}

export const AvailableHandlingMethods: React.FC<HandlingCardProps> = ({
  handlingSummary,
  availableMethods,
  onSetAddress,
  onSetPickupPoint,
  handlingValues,
}) => {
  const hasHome = handlingSummary?.hasHome;

  if (!availableMethods || availableMethods.length === 0) {
    return <BaseLoader />;
  }

  return (
    <$AvailableHandlingMethods>
      {availableMethods.map((availableMethod, index) => {
        const transportMethodType =
          availableMethod.value as TransportMethodType;

        return (
          <ChooseHandlingMethod
            key={transportMethodType}
            isSelected={
              transportMethodType === handlingValues.selectedMethod?.value
            }
            icon={getHandlingCardIcon({
              transportMethodType: hasHome
                ? TransportMethodType.HomeIntervention
                : (transportMethodType as TransportMethodType),
            })}
            availableMethod={availableMethod}
            dataTestId={`handling-card-${index}`}
            onSetAddress={onSetAddress}
            onSetPickupPoint={onSetPickupPoint}
            handlingValues={handlingValues}
            handlingSummary={handlingSummary}
          />
        );
      })}
    </$AvailableHandlingMethods>
  );
};
