import { get } from 'lodash';

import {
  TransportMethodType,
  TransportPlan,
} from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { StrictAutocompleteOption } from '@savgroup-front-common/types';

import messages from './messages';

export const getNewAvailableMethods = ({
  transportPlans,
  hasHome,
}: {
  transportPlans?: TransportPlan[];
  hasHome?: boolean;
}): StrictAutocompleteOption<TransportPlan[]>[] => {
  return Object.values(
    transportPlans?.reduce<
      Record<string, StrictAutocompleteOption<TransportPlan[]>>
    >(
      (
        acc: Record<string, StrictAutocompleteOption<TransportPlan[]>>,
        transportPlan,
      ) => {
        const transportMethoMessage = get(
          messages,
          transportPlan.transportMethod,
        );

        const existingItem = acc[transportPlan.transportMethod];

        return {
          ...acc,
          [transportPlan.transportMethod]: existingItem
            ? {
                ...existingItem,
                data: [...existingItem.data, transportPlan],
              }
            : {
                value: transportPlan.transportMethod,
                data: [transportPlan],
                label: transportMethoMessage
                  ? safeFormattedIntlString(transportMethoMessage)
                  : transportPlan.transportMethod,
              },
        };
      },
      hasHome
        ? {
            [TransportMethodType.HomeIntervention]: {
              label: safeFormattedIntlString(messages.homeIntervention),
              value: TransportMethodType.HomeIntervention,
              data: [],
            },
          }
        : {},
    ) ?? {},
  );
};
