import get from 'lodash/get';
import React, { isValidElement, ReactFragment, ReactNode } from 'react';
import { FormattedMessage, IntlShape, MessageDescriptor } from 'react-intl';

import { MessageType } from '@savgroup-front-common/types';

import { FieldMessages } from '../atoms/Form/common/helpers/getFinalFieldState.types';
import { showMessageId } from '../helpers/intl';

// Temporarily workaround until upgrade formatjs to support React 18
const isReactFragment = (
  _objectToDetermine: unknown,
): _objectToDetermine is ReactFragment => {
  return false;
};

const formatMessage = (input: Record<string, unknown> | MessageDescriptor) => {
  const id = get(input, 'id');

  const el = React.createElement(FormattedMessage, input);
  const span = React.createElement(
    'span',
    {
      title: id,
      style: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    el,
  );

  if (showMessageId) {
    return span;
  }

  return el;
};

type SafeFormattedMessageProps =
  | ReactNode
  | Record<string, Record<string, Record<string, unknown>>>
  | Record<string, unknown>
  | MessageType
  | MessageDescriptor
  | IntlShape;

export const SafeFormattedMessage = (input: SafeFormattedMessageProps) => {
  if (!input) {
    return null;
  }

  const defaultMessage = (input as any)?.defaultMessage;
  const messages = (input as any)?.messages as FieldMessages | undefined;
  const id = (input as any)?.id;

  let result = id;

  if (
    typeof input === 'string' ||
    typeof input === 'number' ||
    typeof input === 'boolean'
  ) {
    return React.createElement('span', {}, input);
  }

  if (isValidElement(input)) {
    return input;
  }

  if (isReactFragment(input)) {
    return React.createElement(
      'span',
      {
        style: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
      input,
    );
  }

  if (defaultMessage && id && !messages) {
    result = formatMessage(
      input as Record<string, unknown> | MessageDescriptor,
    );
  } else if (defaultMessage && !id) {
    result = defaultMessage;
  }

  if (messages && id && typeof id === 'string' && messages[id]) {
    const values = get(input, 'values', {});
    const arrayValues = Object.entries(
      values as Record<string, string | Record<string, unknown>>,
    );

    const translatedValues = arrayValues.reduce((acc, [key, value]) => {
      if (typeof value === 'string' || isValidElement(value)) {
        return {
          ...acc,
          [key]: value,
        };
      }

      return {
        ...acc,
        [key]: SafeFormattedMessage({
          messages,
          ...value,
        }),
      };
    }, {});

    result = formatMessage({
      ...messages[id],
      values: translatedValues,
    });
  }

  if (!result) {
    return <></>;
  }

  return <>{result}</>;
};

SafeFormattedMessage.displayName = 'SafeFormattedMessage';
