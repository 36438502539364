import React, { FC } from 'react';

import { ReverseMoneyNumberAmount } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../formatters';
import {
  formatAmountWithCurrency,
  formatReverseMoneyToString,
} from '../../formatters/intl';

import { $ItemPrice } from './ChooseItemCard.styles';
import messages from './messages';

interface ChooseItemCardPriceBlockProps {
  price?: ReverseMoneyNumberAmount;
  displayPriceAs: 'amount' | 'startFrom';
  hasDisplayFreeAmount?: boolean;
  canDeduceTransportFromRefund?: boolean;
}

export const ChooseItemCardPriceBlock: FC<ChooseItemCardPriceBlockProps> = ({
  price,
  displayPriceAs,
  hasDisplayFreeAmount = false,
  canDeduceTransportFromRefund,
}) => {
  return (
    <>
      {!!price?.amount && (
        <$ItemPrice $hasPrice $hasPickupPointType={false}>
          {displayPriceAs === 'amount' && (
            <span>
              {formatReverseMoneyToString(price, {
                shouldStripIfInteger: true,
              })}
            </span>
          )}

          {displayPriceAs === 'startFrom' && (
            <SafeFormattedMessageWithoutSpread
              message={messages.startingFrom}
              values={{
                price: formatAmountWithCurrency(
                  price.amount,
                  price.currency,
                )?.toString(),
              }}
            />
          )}

          <span>
            {canDeduceTransportFromRefund && (
              <SafeFormattedMessageWithoutSpread
                message={messages.deductibleFromRefund}
              />
            )}
          </span>
        </$ItemPrice>
      )}

      {!price?.amount && hasDisplayFreeAmount && (
        <$ItemPrice $hasPrice={false} $hasPickupPointType={false}>
          <SafeFormattedMessageWithoutSpread message={messages.free} />
        </$ItemPrice>
      )}
    </>
  );
};
