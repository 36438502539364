import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react';

import { GoogleApiConfiguration } from '@savgroup-front-common/configuration';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { PickupPointOption } from '../../../../../../../IrshPages.types';
import { AddressSelected, Bounds } from '../../PickupPointSelector.types';

import { mapStyles, markerShape } from './MapWithControlledZoom.constants';
import useMapWithControlledZoom from './MapWithControlledZoom.hooks';
import { $GoogleMap } from './MapWithControlledZoom.styles';
import { AdaptedPickupPointOption } from './MapWithControlledZoom.types';

const HOME_MARKER_URL = `${
  import.meta.env.VITE_STATIC_HASH
}/images/home_marker.svg`;

type MapWithControlledZoomProps = {
  onSelectPickupPoint: (pickupPoint: PickupPointOption) => void;
  pickupPoints: PickupPointOption[];
  searchAddress: AddressSelected;
  selectedPickupPoint?: PickupPointOption;
  bounds: Bounds;
  center: google.maps.LatLngLiteral;
};

const MapWithControlledZoom: React.FC<MapWithControlledZoomProps> = ({
  onSelectPickupPoint,
  pickupPoints,
  searchAddress,
  selectedPickupPoint,
  bounds,
  center,
}) => {
  const [hasHumanZoom, setHasHumanZoom] = useState(false);
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoading, adaptedPickupPointOptions } = useMapWithControlledZoom({
    adaptedPickupPointOptions: pickupPoints,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GoogleApiConfiguration.apiKey,
  });

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onZoomChanged = () => {
    if (map) {
      setZoom(map.getZoom() || 15);
      setHasHumanZoom(true);
    }
  };

  const handleSelectPickupPoint =
    (pickupPointOption: AdaptedPickupPointOption) => () => {
      const { latitude, longitude } =
        pickupPointOption?.data?.pickupPoint || {};

      if (map) {
        map.panTo({ lat: latitude, lng: longitude });
        setZoom(17);
      }

      onSelectPickupPoint({
        value: pickupPointOption.value,
        label: pickupPointOption.label,
        data: {
          pickupPoint: pickupPointOption?.data?.pickupPoint,
          transportPlan: pickupPointOption?.data?.transportPlan,
        },
      });
    };

  const autoFocus = useCallback(() => {
    if (adaptedPickupPointOptions && adaptedPickupPointOptions.length > 0) {
      const firstPickupPoint = adaptedPickupPointOptions[0]?.data?.pickupPoint;

      const lat = firstPickupPoint.latitude;
      const lng = firstPickupPoint.longitude;

      if (map && lat && lng) {
        const x = map.getBounds();

        if (x) {
          const northEast = x.getNorthEast();
          const southWest = x.getSouthWest();

          if (
            !(
              (lat < northEast.lat() && lat > southWest.lat()) ||
              (lng < northEast.lng() && lng > northEast.lng())
            )
          ) {
            setZoom(zoom - 1);
          }
        }
      }
    }
  }, [adaptedPickupPointOptions, zoom, map]);

  useEffect(() => {
    if (
      !hasHumanZoom &&
      adaptedPickupPointOptions &&
      adaptedPickupPointOptions.length > 0
    ) {
      autoFocus();
    }
  }, [autoFocus, adaptedPickupPointOptions, zoom, hasHumanZoom]);

  if (isLoading) {
    return <BaseLoader />;
  }

  if (!bounds || !isLoaded) {
    return null;
  }

  return (
    <$GoogleMap>
      <GoogleMap
        center={center}
        zoom={zoom}
        onZoomChanged={onZoomChanged}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ styles: mapStyles as any }}
      >
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={500 / Math.max(zoom, 1)}
          zoomOnClick={false}
          maxZoom={15}
        >
          {() => {
            return (
              <>
                {adaptedPickupPointOptions?.map((adaptedPickupPointOption) => {
                  const isSelected =
                    selectedPickupPoint?.value ===
                    adaptedPickupPointOption.value;
                  const zIndex = isSelected ? 1000 : 1;

                  const pickupPoint =
                    adaptedPickupPointOption.data?.pickupPoint;

                  return (
                    <Marker
                      key={adaptedPickupPointOption.value}
                      position={{
                        lat: pickupPoint.latitude,
                        lng: pickupPoint.longitude,
                      }}
                      icon={
                        isSelected
                          ? adaptedPickupPointOption.data?.selectedIcon
                          : adaptedPickupPointOption.data?.icon
                      }
                      onClick={handleSelectPickupPoint(
                        adaptedPickupPointOption,
                      )}
                      title={adaptedPickupPointOption.label}
                      zIndex={zIndex}
                      clickable
                      shape={markerShape}
                    />
                  );
                })}
              </>
            );
          }}
        </MarkerClusterer>

        {searchAddress.location && (
          <Marker
            position={{
              lat: searchAddress.location?.lat,
              lng: searchAddress.location?.lng,
            }}
            icon={HOME_MARKER_URL}
          />
        )}
      </GoogleMap>
    </$GoogleMap>
  );
};

export default MapWithControlledZoom;
