import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $LeftColumn = styled.div``;

export const $Heading = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 23px;
  @media ${media.minWidth.sm} {
    font-size: 20px;
  }

  color: ${({ theme }) => theme.newUI.defaultColors.heading};
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.newUI.defaultColors.heading};
  }
`;

export const $HeadingIcon = styled.span`
  margin-right: 1rem;
  display: flex;
  align-items: baseline;
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const $SolutionWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media ${media.minWidth.sm} {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
  }
`;

export const $SolutionItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const $SolutionResultWrapper = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`;
