import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import React, { Component, ReactNode } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete';
import { Col } from 'react-styled-flexboxgrid';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Loader } from '@savgroup-front-common/core/src/atoms/loader';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { PositionIcon } from '@savgroup-front-common/core/src/protons/icons';

import { AddressSelected } from '../PickupPointSelector.types';

import {
  $ColWithoutPaddingRight,
  $DropdownSuggestions,
  $LocationField,
  $RowStyled,
  $Suggestion,
} from './Address.styles';
import messages from './messages';

interface Location {
  lat: number;
  lng: number;
}

interface AddressProps {
  searchAddress?: { address?: string };
  onError?: (error: unknown) => void;
  onAddressSelected?: (details: AddressSelected) => void;
  isAdvancedOptionShown?: boolean;
  maxDistance: number;
}

interface AddressState {
  loading: boolean;
  placeId: string;
  isGoogleAddress: boolean;
  address?: string;
  isGettingCurrentLocation?: boolean;
}

class Address extends Component<AddressProps, AddressState> {
  state: AddressState = {
    loading: false,
    placeId: '',
    isGoogleAddress: false,
  };

  UNSAFE_componentWillMount() {
    const { searchAddress } = this.props;

    if (searchAddress) {
      this.callbackAddressSelected(searchAddress);
    }
  }

  onError = (error: unknown) => {
    logError(error);
  };

  onSuccess = (pos: GeolocationPosition) => {
    const crd = pos.coords;
    const latLng: Location = { lat: crd.latitude, lng: crd.longitude };
    const geocoder = new window.google.maps.Geocoder();

    if (!window.google || !window.google.maps) {
      alert(
        'Votre navigateur est trop ancien. Merci d’utiliser une version récente.',
      );

      return Promise.resolve([]);
    }

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        const address = results[0];

        if (address) {
          const countryCode = get(
            find(address.address_components, (info) =>
              includes(get(info, 'types'), 'country'),
            ),
            'short_name',
          );

          this.handleOnLocalize(address.formatted_address, countryCode);
        } else {
          logError('No results found');
        }
      } else {
        logError(`Geocoder failed due to: ${status}`);
      }
    });
  };

  getCurrentPosition = async () => {
    this.setState(() => ({ isGettingCurrentLocation: true }));
    const options: PositionOptions = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    return new Promise<GeolocationPosition>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options),
    )
      .then((data) => {
        this.setState(() => ({ isGettingCurrentLocation: false }));

        return this.onSuccess(data);
      })
      .catch((e) => {
        this.setState(() => ({ isGettingCurrentLocation: false }));

        return logError(e);
      });
  };

  handleOnLocalize = (address: string, countryCode?: string) => {
    this.setState({
      address,
    });
    if (address) {
      this.callbackAddressSelected({ address, countryCode });
    }
  };

  handleChange = (address: string) => {
    this.setState({
      address,
    });
  };

  handleSelect = (
    address: string,
    placeId?: string,
    isGoogleAddress = false,
  ) => {
    this.setState({
      loading: true,
      placeId: placeId ?? '',
      isGoogleAddress,
      address,
    });
    const { onAddressSelected } = this.props;

    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          this.setState({
            loading: false,
          });
          const country = results[0].address_components.find((c) =>
            c.types.some((t) => t === 'country'),
          );
          const city = results[0].address_components.find((c) =>
            c.types.some((t) => t === 'locality'),
          );
          const countryCode = country ? country.short_name : undefined;

          if (countryCode && onAddressSelected) {
            onAddressSelected({
              address,
              location: { lat, lng },
              placeId,
              countryCode,
              city: city ? city.long_name : undefined,
            });
          } else {
            logError('APP-MissingCountryCode', {
              countryCode,
              address,
              lat,
              lng,
            });
          }
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  callbackAddressSelected = ({
    address,
    countryCode,
  }: {
    address?: string;
    countryCode?: string;
  }) => {
    const { onAddressSelected } = this.props;

    if (!address || !countryCode) {
      return;
    }

    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        if (onAddressSelected) {
          onAddressSelected({
            address,
            location: latLng,
            countryCode,
          });
        }
      })
      .catch((error) => logError('Error in Address UI Component', error));
  };

  render(): ReactNode {
    const { onError } = this.props;
    const { isGettingCurrentLocation } = this.state;

    return (
      <PlacesAutocomplete
        value={
          get(
            this.state,
            'address',
            get(this.props, ['searchAddress', 'address']),
          ) || ''
        }
        onSelect={this.handleSelect}
        onChange={this.handleChange}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <$RowStyled>
              <Col style={{ flex: 1 }}>
                <$LocationField
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: 'location-search-input',
                  })}
                  isLoading={isGettingCurrentLocation ?? false}
                  selectAllOnFocus
                />
              </Col>
              <$ColWithoutPaddingRight>
                <Button
                  naked
                  icon={<PositionIcon />}
                  onClick={this.getCurrentPosition}
                  type={BUTTON_TYPES.BUTTON}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.aroundMe}
                  />
                </Button>
              </$ColWithoutPaddingRight>
            </$RowStyled>

            {suggestions.length > 0 ? (
              <$DropdownSuggestions>
                {loading && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Loader />
                  </div>
                )}
                {suggestions.map((suggestion: Suggestion) => (
                  <$Suggestion
                    {...getSuggestionItemProps(suggestion)}
                    active={suggestion.active}
                    key={suggestion.description}
                  >
                    {suggestion.description}
                  </$Suggestion>
                ))}
              </$DropdownSuggestions>
            ) : null}
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default Address;
