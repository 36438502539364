import { useCallback, useReducer, useState } from 'react';
import { useQuery } from 'react-query';

import {
  NOTIFICATION_TYPES,
  STALE_TIME,
} from '@savgroup-front-common/constants';
import {
  SALESFORCE_INTEGRATION_TYPE,
  Timeslot,
} from '@savgroup-front-common/types';

import { CommonWorkflowService } from '../../../../api';
import { buildNotification } from '../../../../helpers/errorsMessage';
import { sortBy } from '../../../../helpers/sorter';
import useGetSellerConfiguration from '../../../../hooks/useGetSellerConfiguration';
import { useBanners } from '../../../../molecules/NotificationsProvider';
import { InterventionAppointmentMockBuilder } from '../../__tests__/InterventionAppointmentMockBuilder.mock';
import { useGetPageSize } from '../../hooks/useGetPageSize';
import { getStartAndEndDate } from '../../legacy/TakeInterventionAppointmentLegacy/AppointmentCalendarContainerLegacy/AppointmentCalendarLegacy/helpers/getStartAndEndDate';

import adaptTimeslotToDetailedSchedule from './adapters/adaptTimeslotToDetailedSchedule';
import messages from './messages';
import {
  interventionAppointmentInit,
  takeInterventionAppointmentReducer,
} from './TakeInterventionAppointment.reducer';
import { INTERVENTION_APPOINTMENT_ACTION_TYPES } from './TakeInterventionAppointment.types';

interface UseInterventionAppointmentProps {
  fileId: string;
  sellerId: string;
  onSubmit: () => void;
  pageNumber?: number;
}

export const useInterventionAppointment = ({
  fileId,
  onSubmit,
  sellerId,
  pageNumber = 1,
}: UseInterventionAppointmentProps) => {
  const pageSize = useGetPageSize();
  const { removeAllNotifications, pushError, pushNotification } = useBanners({
    extendedMessages: messages,
  });
  const [state, dispatch] = useReducer(
    takeInterventionAppointmentReducer,
    undefined,
    interventionAppointmentInit,
  );

  const { hasErrors } = state;

  const [
    isCreateTechnicalInterventionLoading,
    setIsCreateTechnicalInterventionLoading,
  ] = useState(false);

  const { sellerConfiguration } = useGetSellerConfiguration({
    sellerId,
  });

  const { data: detailedSchedules } = useQuery(
    [
      'getTechnicalInterventionTimeSlots',
      {
        sellerId,
        integrationType:
          sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
        pageNumber,
      },
    ],
    async () => {
      removeAllNotifications();

      if (
        !sellerConfiguration ||
        !sellerConfiguration?.salesforceFieldServiceLightningIntegrationType
      ) {
        return undefined;
      }

      if (
        sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
        SALESFORCE_INTEGRATION_TYPE.Integration
      ) {
        const rawTimeslot =
          new InterventionAppointmentMockBuilder().addDemoSchedules().schedules;

        return adaptTimeslotToDetailedSchedule(
          rawTimeslot.sort(sortBy({ fieldName: 'start' })),
          { pageNumber, pageSize },
        );
      }
      const { startDate, endDate } = getStartAndEndDate({
        pageNumber,
        pageSize,
      });

      const response =
        await CommonWorkflowService.getTechnicalInterventionTimeSlots({
          fileId,
          startDate,
          endDate,
        });

      if (response.failure) {
        pushNotification(
          buildNotification({
            message: messages.pleaseContactSupportToTakeAppointment,
            notificationType: NOTIFICATION_TYPES.ALERT,
            options: {
              hollow: true,
            },
          }),
        );

        return undefined;
      }

      return adaptTimeslotToDetailedSchedule(
        response.value.sort(sortBy({ fieldName: 'start' })),
        { pageNumber, pageSize },
      );
    },
    {
      suspense: true,
      staleTime: STALE_TIME.SECONDS_1,
    },
  );

  const handleValidateSchedule = useCallback(
    async ({ selectedTimeslot }: { selectedTimeslot?: Timeslot }) => {
      dispatch({
        type: INTERVENTION_APPOINTMENT_ACTION_TYPES.VALIDATE_TIMESLOT,
      });
      removeAllNotifications();

      if (!selectedTimeslot) {
        const error = {
          name: 'requiredTimeSlotSelection',
          message: '',
        };

        dispatch({
          type: INTERVENTION_APPOINTMENT_ACTION_TYPES.SET_ERRORS,
          payload: {
            errors: [error],
          },
        });

        pushError(error);

        return;
      }

      if (
        sellerConfiguration?.salesforceFieldServiceLightningIntegrationType ===
        SALESFORCE_INTEGRATION_TYPE.Integration
      ) {
        setIsCreateTechnicalInterventionLoading(true);

        onSubmit();
      } else {
        try {
          setIsCreateTechnicalInterventionLoading(true);

          await CommonWorkflowService.createTechnicalIntervention({
            fileId,
            start: selectedTimeslot.start.toISOString(),
            end: selectedTimeslot.end.toISOString(),
          });

          onSubmit();
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (_) {
          setIsCreateTechnicalInterventionLoading(false);

          pushNotification(
            buildNotification({
              message: messages.createTechnicalInterventionError,
              notificationType: NOTIFICATION_TYPES.ALERT,
              options: {
                hollow: true,
              },
            }),
          );
        }
      }
    },
    [
      fileId,
      onSubmit,
      pushError,
      pushNotification,
      removeAllNotifications,
      sellerConfiguration?.salesforceFieldServiceLightningIntegrationType,
    ],
  );

  return {
    detailedSchedules,
    isCreateTechnicalInterventionLoading,
    handleValidateSchedule,
    hasErrors,
  };
};
