import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { HANDLING_MODES } from '@savgroup-front-common/constants';
import { TransportPlan } from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { useGetClaimGroupTransportPlanQuery } from '@savgroup-front-common/core/src/api/Claim/hooks/useGetClaimGroupTransportPlanQuery';
import { HandlingProcedure } from '@savgroup-front-common/core/src/api/Claim/setClaimGroupHandlingTransportPlanCommand';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import {
  AddressInfoDto,
  CUSTOMER_TYPE,
  PickupPoint,
  StrictAutocompleteOption,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';

import { getNewAvailableMethods } from '../helpers/getNewAvailableMethods';
import { UseGetHandlingByClaimGroup } from '../hooks/useGetHandlingByClaimGroup';
import { HandlingValues, IrshStepValues } from '../IrshPages.types';

interface UseClaimGroupDeliveryPageArgs {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupDeliveryPage = ({
  onNextStep,
}: UseClaimGroupDeliveryPageArgs) => {
  const queryClient = useQueryClient();
  const { removeAllNotifications, pushErrors } = useToasts();

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const handlingSummary = values?.handling;

  const claimGroupId = values?.claimGroupId;

  const { claimGroupConfirmation } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  const { transportPlans } = useGetClaimGroupTransportPlanQuery({
    claimGroupId,
  });

  const deliveryTransportPlans =
    transportPlans?.filter(
      (transportPlan) => transportPlan.handlingMode === HANDLING_MODES.DELIVERY,
    ) ?? [];

  const deliveryAvailableMethods = getNewAvailableMethods({
    transportPlans: deliveryTransportPlans,
  });

  const alreadySelectedMethod = deliveryAvailableMethods.find(
    (method) =>
      method.value === handlingSummary?.delivery?.transportPlanMethodType,
  );

  const defaultSelectedMethodFromSummary = handlingSummary?.delivery
    ?.transportPlanMethodType
    ? alreadySelectedMethod
    : undefined;
  const defaultAddressFromSummary = handlingSummary?.delivery?.address;
  const defaultPickupPointFromSummary = handlingSummary?.delivery?.pickupPointId
    ? {
        value: handlingSummary?.delivery?.pickupPointId,
      }
    : undefined;

  const [handlingValues, setHandlingValues] = useState<HandlingValues>({
    selectedMethod:
      values?.handlingDelivery?.selectedMethod ??
      (defaultSelectedMethodFromSummary as any),
    selectedPickupPoint:
      values?.handlingDelivery?.selectedPickupPoint ??
      (defaultPickupPointFromSummary as any),
    address: values?.handlingDelivery?.address ?? defaultAddressFromSummary,
  });

  const { mutate: onSubmit, isLoading: isSubmitLoading } = useMutation(
    ['setDeliveryHandling'],
    async (values: HandlingValues) => {
      removeAllNotifications();

      const { address, selectedPickupPoint, selectedMethod } = values;

      if (!claimGroupId || !selectedMethod) {
        return undefined;
      }

      const pickupPoint = selectedPickupPoint?.data?.pickupPoint;

      if (pickupPoint) {
        const responseHandling =
          await ClaimService.setClaimGroupHandlingTransportPlanCommand({
            claimGroupId,
            handlingMode: HANDLING_MODES.DELIVERY,
            pickupPointId: pickupPoint.id,
            pickupPointAddress: {
              address: pickupPoint.adress,
              additionalAddress: pickupPoint.adress3,
              postalCode: pickupPoint.postalCode,
              city: pickupPoint.city,
              countryCode: pickupPoint.countryCode,
              companyName: pickupPoint.carrierCompany,
              customerType: CUSTOMER_TYPE.NOT_SET,
            },
            pickupPointFullName: pickupPoint.name,
            transportPlanId:
              selectedPickupPoint?.data?.transportPlan.transportPlanId,
            transportPlanHandlingProcedure: HandlingProcedure.Transport,
          });

        if (responseHandling.failure) {
          pushErrors(responseHandling.errors);

          return undefined;
        }
      } else if (address) {
        const responseHandling =
          await ClaimService.setClaimGroupHandlingTransportPlanCommand({
            claimGroupId,
            handlingMode: HANDLING_MODES.DELIVERY,
            address,
            transportPlanId: selectedMethod?.data?.at(0)?.transportPlanId,
            transportPlanHandlingProcedure: HandlingProcedure.Transport,
          });

        if (responseHandling.failure) {
          pushErrors(responseHandling.errors);

          return undefined;
        }
      } else {
        return undefined;
      }

      await queryClient.invalidateQueries([
        UseGetHandlingByClaimGroup,
        { claimGroupId },
      ]);

      onNextStep({
        newValues: {
          handlingDelivery: values,
        },
      });
    },
  );

  const handleSetPickupPoint = ({
    selectedPickupPoint,
    selectedMethod,
  }: {
    selectedPickupPoint?: StrictAutocompleteOption<{
      pickupPoint: PickupPoint;
      transportPlan: TransportPlan;
    }>;
    selectedMethod: StrictAutocompleteOption<TransportPlan[]>;
  }) => {
    setHandlingValues({
      selectedMethod,
      address: undefined,
      selectedPickupPoint,
      pickupPointSelected: undefined,
    });

    onSubmit({
      selectedMethod,
      address: undefined,
      selectedPickupPoint,
      pickupPointSelected: undefined,
    });
  };

  const handleSetAddress = ({
    address,
    selectedMethod,
  }: {
    address: AddressInfoDto;
    selectedMethod: StrictAutocompleteOption<TransportPlan[]>;
  }) => {
    setHandlingValues({
      selectedMethod,
      address,
      selectedPickupPoint: undefined,
      pickupPointSelected: undefined,
    });

    onSubmit({
      selectedMethod,
      address,
      selectedPickupPoint: undefined,
      pickupPointSelected: undefined,
    });
  };

  return {
    claimGroupId,
    claimGroupConfirmation,
    handling: handlingSummary,
    handlingValues,
    onSubmit,
    handleSetAddress,
    handleSetPickupPoint,
    isSubmitLoading,
  };
};

export default useClaimGroupDeliveryPage;
