import React from 'react';
import { Controller } from 'react-hook-form';
import { Grid, Row } from 'react-styled-flexboxgrid';
import { useTheme } from 'styled-components';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import {
  FormGroup,
  RadioButtonGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  sortBy,
  SUPPORTED_ICON_POSITIONS,
} from '@savgroup-front-common/core/src/helpers';
import { RadioCardColumn } from '@savgroup-front-common/core/src/molecules/RadioCardColumn';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';

import { IssueSummaryDto } from '../../../../../../../types/IssueSummaryDto';
import { PageFooterContainer } from '../../../../../../app/NewLayout/NewStepLayout';
import { NewStepLayoutProfessional } from '../../../../../../app/NewLayout/NewStepLayout/NewStepLayoutProfessional';
import { PROFESSIONAL_FINAL_ROUTES } from '../../../../../../app/routes/ProfessionalRoutes';
import {
  $ColSvgItem,
  $RowContainer,
} from '../../../../../../components/common/Container';
import messages from '../../../../../../newDesignPages/irshPages/NewIssuesPage/messages';

import { getColumn } from './IssuesPage.helpers';
import useIssuesPage from './IssuesPage.hooks';
import { $Col } from './IssuesPage.styles';

const IssuesPage: React.FC = () => {
  const theme = useTheme();
  const { issues, issuesCount, formContext, onSubmit } = useIssuesPage();

  const { control } = formContext;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        return undefined;
      }}
    >
      <NewStepLayoutProfessional
        stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_ISSUES_REASONS_PAGE}
        customSteps={PROFESSIONAL_STEP_PROGRESS}
      >
        <FormGroup>
          <Controller
            control={control}
            name="choiceIssue"
            render={({ field }) => {
              return (
                <RadioButtonGroup name="choiceIssueRadioButtonGroup">
                  <Grid fluid>
                    <$RowContainer>
                      {issues
                        .sort(sortBy({ fieldName: 'displayOrder' }))
                        .map((issue: IssueSummaryDto) => {
                          return (
                            <$Col
                              key={issue.name}
                              xs={12}
                              sm={6}
                              md={getColumn(issuesCount)}
                            >
                              <RadioCardColumn
                                title={issue.name}
                                didactics={issue.didactic}
                                name={field.name}
                                value={issue.id}
                                isChecked={field.value === issue.id}
                                onChange={field.onChange}
                                onClick={() =>
                                  onSubmit({ choiceIssue: issue.id })
                                }
                                image={
                                  <Row center="xs">
                                    <$ColSvgItem xs={12}>
                                      <MyAccountIcon
                                        icon={
                                          issue.iconKey ||
                                          ICONS_TYPE.ADD_CIRCLE_OUTLINE_ICON
                                        }
                                        color={
                                          field.value === issue.id
                                            ? theme.colors.primary
                                            : theme.colors.paragraphTextColor
                                        }
                                      />
                                    </$ColSvgItem>
                                  </Row>
                                }
                              />
                            </$Col>
                          );
                        })}
                    </$RowContainer>
                  </Grid>
                </RadioButtonGroup>
              );
            }}
          />
        </FormGroup>
      </NewStepLayoutProfessional>

      <PageFooterContainer reverseChildren={false}>
        <ButtonLink
          secondary
          naked
          href={PROFESSIONAL_FINAL_ROUTES.HOME}
          icon={<ArrowNakedLeftIcon />}
          position={SUPPORTED_ICON_POSITIONS.LEFT}
          dataTestId="previousButton"
        >
          <SafeFormattedMessageWithoutSpread
            message={messages.previousButton}
          />
        </ButtonLink>
      </PageFooterContainer>
    </form>
  );
};

export default IssuesPage;
