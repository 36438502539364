import { tint } from 'polished';
import styled from 'styled-components';

import { Theme } from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import {
  FIELD_STATUS,
  InputStatus,
} from '../../atoms/Form/common/helpers/getFinalFieldState.types';
import { rem } from '../../helpers';

const imageSize = 35;

interface $SolutionProps {
  $status: InputStatus;
  $isDisabled?: boolean;
}

const getStatusColor = (
  {
    $status,
    theme,
  }: {
    $status: InputStatus;
    theme: Theme;
  },
  defaultColor?: string,
) => {
  switch ($status) {
    case FIELD_STATUS.ERROR:
      return theme.colors.danger;
    case FIELD_STATUS.WARNING:
      return theme.colors.alert;
    case FIELD_STATUS.SUCCESS:
      return theme.colors.success;
    default:
      return defaultColor || theme.colors.primary;
  }
};

export const $RadioCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
`;

export const $RadioCardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.colors.mainTextColor};
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
`;

export const $PostTitle = styled.div`
  display: flex;
`;

export const $TextContainer = styled.div`
  flex: 1;
  margin-left: 1rem;
  padding-right: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const $ImageContainer = styled.div<$SolutionProps>`
  width: ${rem(imageSize)};
  min-width: ${rem(imageSize)};
  height: ${rem(imageSize)};
  padding: 0.5rem;
  border-width: ${rem(1)};
  border-style: solid;
  border-color: ${({ theme, $status }) =>
    getStatusColor({ theme, $status }, theme.colors.default)};
  border-radius: ${({ theme }) => theme.borders.radius};
  background: ${({ theme }) => theme.colors.white};
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  svg {
    width: 100%;
    height: auto;
  }
`;

export const $RadioCardLabel = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const $RadioCardBody = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  padding-left: ${rem(imageSize + 14)};
`;

export const $RadioCardContainer = styled.div<$SolutionProps>`
  border-width: ${rem(1)};
  border-style: solid;
  border-color: ${({ theme, $status }) =>
    getStatusColor({ theme, $status }, theme.colors.default)};
  background: ${({ $isDisabled, theme }) =>
    $isDisabled ? theme.colors.disabled : theme.colors.neutralBackground};
  border-radius: ${({ theme }) => theme.borders.radius};

  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  padding: 1rem;
  margin-bottom: 1rem;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
`;

export const $RadioCardLabelContainer = styled.label<$SolutionProps>`
  position: relative;
  display: block;
  width: 100%;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};

  ${decelerateTimingFunctionTransition};

  input:hover,
  input:focus,
  input:active {
    ~ ${$RadioCardContainer} {
      border-color: ${({ $isDisabled }) =>
        $isDisabled ? null : getStatusColor};
    }
  }
  input:checked {
    ~ ${$RadioCardContainer} {
      border-color: ${getStatusColor};
      ${$RadioCardTitle} {
        color: ${getStatusColor};
      }
      ${$ImageContainer} {
        border-color: ${getStatusColor};
      }
      background-color: ${({ theme, $status }) =>
        tint(0.96, getStatusColor({ theme, $status }))};
    }
  }

  &:active {
    transform: ${({ $isDisabled }) => ($isDisabled ? null : 'scale(0.99)')};
  }

  input + * {
    transform: scale(1);
    top: unset;
    left: unset;
    position: relative;
    right: unset;
  }
  input:active + * {
    transform: scale(
      ${({ theme }) => theme.interactions.activeScale}
    ) !important;
  }
`;
