import { useQuery } from 'react-query';

import { CommonCarrierService } from '@savgroup-front-common/core/src/api';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

const GetCarrierProductById = 'getCarrierProductById';

export const useTransportPlanByIdQuery = ({
  transportPlanId,
}: {
  transportPlanId?: string;
}) => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: transportPlanSummary } = useQuery(
    [GetCarrierProductById, { transportPlanId }],
    async () => {
      if (!transportPlanId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await CommonCarrierService.getTransportPlanByIdQuery({
        transportPlanId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return {
    transportPlanSummary,
  };
};
