import { defineMessages } from 'react-intl';

export default defineMessages({
  joinAnAttachmentDocument: {
    id: 'view.SectionTitle.joinAnAttachmentDocument',
    defaultMessage: 'Join a picture or a video of your problem',
  },
  fileInputDocument: {
    id: 'view.card.FileAttachmentsSection.button',
    defaultMessage: 'Click Here',
  },
  dragAndDropFilesDocument: {
    id: 'view.import.fileUploader.dragAndDropFilesDocument',
    defaultMessage: 'Drag and drop or',
  },
  dragAndDropFilesDocumentAriaLabel: {
    id: 'ariaLabel.dragAndDropFilesDocument',
    defaultMessage: '{fieldLabel} - Drag and drop files here',
  },
});
