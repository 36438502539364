import _ from 'lodash';
import React, { FC } from 'react';
import { Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  media,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import {
  HandlingValues,
  PickupPointOption,
} from '../../../../../IrshPages.types';

import Address from './Address';
import { AdaptedPickupPointOption } from './components/MapWithControlledZoom/MapWithControlledZoom.types';
import PickupPointList from './components/PickupPointList';
import PickupPointMap from './components/PickupPointMap';
import messages from './messages';
import {
  $AddressContainer,
  $BackButtonContainer,
  $CloseButton,
  $ColListContainer,
  $ColMapContainer,
  $NoPickupPoint,
  $SelectContainerGrid,
  $SelectContainerRow,
} from './PickupPointSelector.styles';
import { AddressSelected } from './PickupPointSelector.types';

interface PickupPointSelectorProps {
  onClose: () => void;
  pickupPoints?: PickupPointOption[];
  maxDistance: number;
  onAddressSelected: (addressSelected: AddressSelected) => void;
  searchAddress?: AddressSelected;
  pickupPointsIsLoading: boolean;
  onSelectPickupPoint: (payload: PickupPointOption) => void;
  selectedPickupPoint?: PickupPointOption;
  handlingValues: HandlingValues;
}

const PickupPointSelector: FC<
  React.PropsWithChildren<PickupPointSelectorProps>
> = ({
  onClose,
  pickupPoints,
  maxDistance,
  onAddressSelected,
  searchAddress,
  pickupPointsIsLoading,
  onSelectPickupPoint,
  selectedPickupPoint,
  handlingValues,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const cardRefs: any = {};

  const selectPickupPointOnMap = (
    selectedPickupPointOnMap: PickupPointOption,
  ) => {
    const cardElement = cardRefs[selectedPickupPointOnMap.value];

    if (cardElement) {
      cardElement.scrollIntoView();
    }
    onSelectPickupPoint(selectedPickupPointOnMap);
  };

  const filteredEnrichedPickupPoints = pickupPoints?.filter(
    (pickupPointOption) => {
      const pickupPoint = pickupPointOption?.data?.pickupPoint;

      return pickupPoint?.latitude && pickupPoint?.longitude;
    },
  );

  const sortedePickupPointOptions = _.uniqBy(
    filteredEnrichedPickupPoints,
    'value',
  ).sort((a, b) => {
    const pickupPointA = a?.data?.pickupPoint;
    const pickupPointB = b?.data?.pickupPoint;

    return (
      (pickupPointA?.distanceInMeters ?? 0) -
      (pickupPointB?.distanceInMeters ?? 0)
    );
  });

  const handleSetSelectedPickupPoint = (
    pickupPointOption: AdaptedPickupPointOption,
  ) => {
    onSelectPickupPoint({
      value: pickupPointOption.value,
      label: pickupPointOption.label,
      data: {
        pickupPoint: pickupPointOption?.data?.pickupPoint,
        transportPlan: pickupPointOption?.data?.transportPlan,
      },
    });
  };

  return (
    <$SelectContainerGrid fluid>
      <$SelectContainerRow>
        <$ColListContainer sm={4}>
          <Row>
            <$BackButtonContainer xs={12}>
              <$CloseButton
                onClick={onClose}
                type={BUTTON_TYPES.BUTTON}
                icon={<MyAccountIcon icon={ICONS_TYPE.CROSS_ICON} />}
                primary
                naked
                small
              />
            </$BackButtonContainer>
          </Row>
          <$AddressContainer>
            <Address
              onAddressSelected={(addressSelected) => {
                onAddressSelected(addressSelected);
              }}
              searchAddress={searchAddress}
              maxDistance={maxDistance}
            />
          </$AddressContainer>

          {pickupPointsIsLoading && <BaseLoader />}

          {!pickupPointsIsLoading && (
            <Row>
              {pickupPoints && pickupPoints.length > 0 && (
                <PickupPointList
                  onSelectPickupPoint={handleSetSelectedPickupPoint}
                  pickupPoints={sortedePickupPointOptions}
                  handlingValues={handlingValues}
                />
              )}

              {(!pickupPoints || pickupPoints?.length === 0) && (
                <$NoPickupPoint>
                  <Banner
                    isOpen
                    hollow
                    notificationType={NOTIFICATION_TYPES.ALERT}
                  >
                    <SafeFormattedMessageWithoutSpread
                      message={messages.noPickupPoints}
                    />
                  </Banner>
                </$NoPickupPoint>
              )}
            </Row>
          )}
        </$ColListContainer>

        {pickupPointsIsLoading && !isMobileView ? (
          <BaseLoader />
        ) : (
          <$ColMapContainer sm={8}>
            <PickupPointMap
              searchAddress={searchAddress}
              selectedPickupPoint={selectedPickupPoint}
              onSelectPickupPoint={selectPickupPointOnMap}
              pickupPoints={sortedePickupPointOptions}
            />
          </$ColMapContainer>
        )}
      </$SelectContainerRow>
    </$SelectContainerGrid>
  );
};

export default PickupPointSelector;
