import toPairs from 'lodash/toPairs';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';

import { getCurrentLanguage } from '../helpers';

import { tryParseJson } from './reduxExtendedRequestSaga';
import { userManager } from './userManager';

const XHR_DONE_STATE = 4;

export interface XhrEvent {
  done?: boolean;
  ok?: boolean;
  status?: number;
  statusText?: string;
  response?: any;
  progress?: number;
  isCancelled?: boolean;
}

export function initiateXhr({
  emitter,
}: {
  emitter: (event: XhrEvent) => void;
}) {
  const xhr = new XMLHttpRequest();
  let xhrCancelled = false;

  if (xhr.upload) {
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.floor((event.loaded / event.total) * 100);

        emitter({ progress });
      }
    };
  }

  xhr.onload = () => {
    emitter({
      done: true,
      ok: xhr.status < 400,
      status: xhr.status,
      statusText: xhr.statusText,
      response: xhr.response,
      progress: 100,
    });
  };

  xhr.onerror = () => {
    emitter({
      done: true,
      ok: false,
      status: xhr.status,
      statusText: xhr.statusText,
      response: xhr.response,
    });
  };

  return {
    xhr,
    cancelRequest: () => {
      if (xhr.readyState !== XHR_DONE_STATE) {
        xhrCancelled = true;
        xhr.abort();

        emitter({
          isCancelled: true,
        });
      }
    },
    isCancelled() {
      return xhrCancelled;
    },
    setHeaders: (headers: Record<string, string | undefined>) => {
      for (const [name, value] of toPairs(headers)) {
        if (value !== undefined) {
          xhr.setRequestHeader(name, value);
        }
      }
    },
  };
}

async function extendedRequest<T>(
  url: string,
  config: {
    method: SUPPORTED_METHODS;
    headers?: Record<string, string>;
    body?: any;
    notify?: ({ progress }: { progress: number }) => void;
  },
): Promise<T> {
  return new Promise((resolve, reject) => {
    const { method, headers = {}, body, notify } = config;

    if (!userManager) {
      const { xhr, setHeaders } = initiateXhr({
        emitter: (event: XhrEvent) => {
          if (event.ok) {
            resolve(tryParseJson(event.response));
          } else if (event.done) {
            reject(tryParseJson(event.response));
          } else if (notify) {
            notify({ progress: event.progress || 0 });
          }
        },
      });

      xhr.open(method, url);
      setHeaders({
        Accept: 'application/json',
        'Access-Control-Max-Age': '600',
        'Accept-Language': getCurrentLanguage(),
        Authorization: undefined,
        ...headers,
      });
      xhr.send(body);
    } else {
      userManager.getUser().then((user) => {
        let accessToken = null;

        if (user && !user.expired) {
          accessToken = user.access_token;
        } else {
          logVerbose('User is not logged in');
        }

        const { xhr, setHeaders } = initiateXhr({
          emitter: (event: XhrEvent) => {
            if (event.ok) {
              resolve(tryParseJson(event.response));
            } else if (event.done) {
              reject(tryParseJson(event.response));
            } else if (notify) {
              notify({ progress: event.progress || 0 });
            }
          },
        });

        xhr.open(method, url);
        setHeaders({
          Accept: 'application/json',
          'Access-Control-Max-Age': '600',
          'Accept-Language': getCurrentLanguage(),
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          ...headers,
        });
        xhr.send(body);
      });
    }
  });
}

export default extendedRequest;
