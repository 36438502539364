import get from 'lodash/get';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  availableNamedLanguages,
  LANGUAGES,
} from '@savgroup-front-common/constants';
import {
  AutocompleteOption,
  SUPPORTED_COMPONENTS,
} from '@savgroup-front-common/types';

import { Autocomplete } from '../../atoms/Form';
import { changeLocaleLanguage } from '../../domains/i18n/actions';
import { safeFormattedIntlString } from '../../formatters';
import { useChangeHTMLLanguage } from '../../hooks';

import { $LanguageChooserContainer } from './LanguageChooser.styles';
import messages from './messages';

interface LanguageChooserProps {
  sellerId?: string;
  acceptedLanguages?: LANGUAGES[];
  acceptedLanguagesBySeller?: string[];
  currentLanguage?: string;
  onChangeLanguage?: (locale: AutocompleteOption<string>) => void;
  isLoading?: boolean;
}

const LanguageChooser = ({
  acceptedLanguages,
  acceptedLanguagesBySeller,
  currentLanguage,
  onChangeLanguage,
  isLoading,
}: LanguageChooserProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState<AutocompleteOption<string>>({
    value: intl.locale.toUpperCase(),
    label: '',
  });

  const handleChangeLanguage = (locale: AutocompleteOption<string>) => {
    if (onChangeLanguage) {
      onChangeLanguage(locale);
    } else {
      setLanguage(locale);

      dispatch(
        changeLocaleLanguage({
          locale: locale.value.toLowerCase() as LANGUAGES,
        }),
      );
    }
  };

  const optionsLanguages = availableNamedLanguages
    .filter((option) =>
      acceptedLanguages
        ? acceptedLanguages.includes(option)
        : acceptedLanguagesBySeller?.includes(option.toLocaleUpperCase()),
    )
    .map((option) => ({
      value: option.toLocaleUpperCase(),
      label: safeFormattedIntlString(get(messages, option)),
    }));

  useChangeHTMLLanguage(currentLanguage || intl.locale.toUpperCase());

  if (!acceptedLanguagesBySeller?.length && !acceptedLanguages?.length) {
    return <></>;
  }

  return (
    <$LanguageChooserContainer>
      <Autocomplete
        name="languageChooser"
        componentThemeName={SUPPORTED_COMPONENTS.LANGUAGE_CHOOSER}
        options={optionsLanguages as AutocompleteOption<string>[]}
        onChange={(option) =>
          handleChangeLanguage(option as AutocompleteOption<string>)
        }
        value={language}
        aria-label={safeFormattedIntlString(messages.chooseLanguageAriaLabel)}
        isLoading={isLoading}
      />
    </$LanguageChooserContainer>
  );
};

export default React.memo(LanguageChooser);
