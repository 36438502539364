import React, { FunctionComponent } from 'react';

import {
  $ProgressBarBackgound,
  $ProgressBarContent,
} from './ProgressBar.styles';

interface ProgressBarProps {
  percent: number;
  isAnimated?: boolean;
  isRounded?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  hideOnFinish?: boolean;
  isNewDesign?: boolean;
  className?: string;
}

const ProgressBar: FunctionComponent<
  React.PropsWithChildren<ProgressBarProps>
> = ({
  percent = 100,
  isAnimated = true,
  isRounded = true,
  isSuccess = false,
  isError = false,
  children,
  hideOnFinish = false,
  isNewDesign = false,
  className,
}) => {
  return (
    <$ProgressBarBackgound
      $isRounded={isRounded}
      $hideOnFinish={hideOnFinish}
      $percent={percent}
      $isAnimated={isAnimated}
      $isSuccess={isSuccess}
      $isError={isError}
      $isNewDesign={isNewDesign}
    >
      {children && (
        <$ProgressBarContent className={className}>
          {children}
        </$ProgressBarContent>
      )}
    </$ProgressBarBackgound>
  );
};

export default ProgressBar;
