import { Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

export const $CardContainer = styled.div`
  margin-top: 2rem;
`;

export const $Col = styled(Col)`
  margin-top: 0;
`;
