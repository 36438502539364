import React, { FunctionComponent } from 'react';
import { components } from 'react-select';
import { Col, Row } from 'react-styled-flexboxgrid';

const OptionWithPrefixImage: FunctionComponent<React.PropsWithChildren<any>> = (
  props,
) => {
  return (
    <components.Option {...props}>
      {React.Children?.map(props.children, (child, key) => {
        return (
          <Row key={key}>
            {props?.data?.icon && (
              <Col>
                <img src={props.data.icon} height={10} alt={props.data.label} />
              </Col>
            )}
            <Col>{child}</Col>
          </Row>
        );
      })}
    </components.Option>
  );
};

export default OptionWithPrefixImage;
