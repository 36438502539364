import React, { FunctionComponent, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { MessageType } from '@savgroup-front-common/types';

import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '../../../../formatters';
import { useIsNewUiEnabled } from '../../../../hooks/useIsNewUiEnabled';
import { FIELD_STATUS, InputStatus } from '../helpers/getFinalFieldState.types';

import {
  $LabelContainer,
  $LabelStyled,
  $LabelWrap,
  $SubLabel,
} from './Label.styles';

interface LabelProps {
  required?: boolean;
  status?: InputStatus;
  isRequired?: boolean;
  isError?: boolean;
  htmlFor?: string;
  postLabel?: ReactNode;
  subLabel?: MessageDescriptor | MessageType | string | number;
  fullWidth?: boolean;
  isHorizontal?: boolean;
  isLabelMultiLineAllowed?: boolean;
}

export const Label: FunctionComponent<React.PropsWithChildren<LabelProps>> = ({
  children,
  status = null,
  required = false,
  isRequired = false,
  isError = false,
  htmlFor,
  postLabel,
  subLabel,
  fullWidth,
  isHorizontal = false,
  isLabelMultiLineAllowed,
  ...rest
}) => {
  const transitionRequired = required || isRequired;
  const isNewUiEnabled = useIsNewUiEnabled();
  const isMobileView = useMedia(media.maxWidth.xs);

  if (subLabel) {
    return (
      <$LabelContainer>
        <$LabelWrap
          fullWidth={fullWidth || isMobileView}
          isHorizontal={isHorizontal}
        >
          <$LabelStyled
            isError={isError || status === FIELD_STATUS.ERROR}
            htmlFor={htmlFor}
            $isNewUi={isNewUiEnabled}
            isHorizontal={isHorizontal}
            fullWidth={fullWidth || isMobileView}
            $isRequired={transitionRequired}
            {...rest}
            $isMultiLine={isLabelMultiLineAllowed}
          >
            {children}
          </$LabelStyled>
          {postLabel}
        </$LabelWrap>
        <$SubLabel title={safeFormattedIntlString(subLabel)}>
          <SafeFormattedMessageWithoutSpread message={subLabel} />
        </$SubLabel>
      </$LabelContainer>
    );
  }

  return (
    <$LabelWrap
      fullWidth={fullWidth || isMobileView}
      isHorizontal={isHorizontal}
    >
      <$LabelStyled
        isError={isError || status === FIELD_STATUS.ERROR}
        htmlFor={htmlFor}
        $isNewUi={isNewUiEnabled}
        isHorizontal={isHorizontal}
        fullWidth={fullWidth || isMobileView}
        $isRequired={transitionRequired}
        {...rest}
        $isMultiLine={isLabelMultiLineAllowed}
      >
        {children}
      </$LabelStyled>
      {postLabel}
    </$LabelWrap>
  );
};
