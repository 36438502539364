import toPairs from 'lodash/toPairs';

import dataDE from '@savgroup-front-common/core/src/domains/i18n/country-list/country-de.json';
import dataEN from '@savgroup-front-common/core/src/domains/i18n/country-list/country-en.json';
import dataES from '@savgroup-front-common/core/src/domains/i18n/country-list/country-es.json';
import dataFR from '@savgroup-front-common/core/src/domains/i18n/country-list/country-fr.json';
import dataIT from '@savgroup-front-common/core/src/domains/i18n/country-list/country-it.json';
import dataNL from '@savgroup-front-common/core/src/domains/i18n/country-list/country-nl.json';
import dataPT from '@savgroup-front-common/core/src/domains/i18n/country-list/country-pt.json';

interface CountryOption {
  key: string;
  label: string;
  value: string;
}

function formatCountryData(
  _locale: string,
  data: Record<string, string>,
): CountryOption[] {
  return toPairs(data).map(([countryCode, text]) => ({
    key: countryCode,
    label: text,
    value: countryCode,
  }));
}

const countryCodes = {
  fr: formatCountryData('fr', dataFR),
  en: formatCountryData('en', dataEN),
  es: formatCountryData('es', dataES),
  de: formatCountryData('de', dataDE),
  it: formatCountryData('it', dataIT),
  nl: formatCountryData('nl', dataNL),
  pt: formatCountryData('pt', dataPT),
} as Record<string, CountryOption[]>;

const useGetCountriesOptions = (locale = 'en') => {
  return countryCodes[locale];
};

export default useGetCountriesOptions;
