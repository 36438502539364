import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';

import { Button } from '../button';

export const $ItemContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const $LeftSideContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
`;

export const $ItemTitle = styled.div<{
  $isActive: boolean;
}>`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumBold};
  && * {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.systemColors.defaultTextColor};
  }
`;

export const $ItemHighlighting = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.25rem 0;

  && * {
    color: ${({ theme }) => theme.colors.success} !important;
  }
`;

export const $ItemHighlightingIcon = styled.span`
  margin-right: 0.5rem;
  display: flex;
`;

export const $ItemDescription = styled.div`
  width: 100%;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  && * {
    color: ${({ theme }) => theme.colors.paragraphTextColor} !important;
  }
  margin: 0.25rem 0;
`;

export const $ItemDistance = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin: 0.25rem 0;
  && * {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

export const $ItemLogo = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;

  & img {
    max-height: 36px;
    max-width: 100%;
  }
`;

export const $ItemPrice = styled.div<{
  $hasPrice: boolean;
  $hasPickupPointType: boolean;
}>`
  margin-left: auto;
  white-space: nowrap;
  text-align: left;

  @media ${media.minWidth.sm} {
    text-align: ${({ $hasPickupPointType }) =>
      $hasPickupPointType ? 'left' : 'right'};
  }

  ${({ $hasPrice, theme }) =>
    $hasPrice
      ? css`
          font-weight: ${theme.fonts.weight.mediumFont};
          font-size: 1rem;
          line-height: 16px;
          color: ${theme.colors.primary} !important;

          & * {
            color: ${theme.colors.primary} !important;
          }

          @media ${media.maxWidth.sm} {
            margin-top: unset;
          }
        `
      : css`
          color: ${theme.colors.paragraphTextColor};
          @media ${media.maxWidth.sm} {
            color: ${theme.colors.black};
            margin-top: 1rem;
            font-weight: ${theme.fonts.weight.mediumFont};
          }
        `}
`;

export const $Item = styled.button<{
  $isActive: boolean;
}>`
  background: ${({ theme }) => theme.systemColors.defaultBackground};

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & * {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  }

  border: 1px solid
    ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  border-radius: ${({ theme }) => theme.borders.radius};

  text-align: left;

  padding: 24px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};

    & * {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  cursor: pointer;
`;

export const $ItemContentTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
  gap: 0.25rem;
`;

export const $Button = styled(Button)`
  &&,
  &&:visited,
  &&:active,
  &&:focus {
    background: transparent;
    padding: 0;
    height: auto;
    min-height: auto;
    justify-content: flex-start;
    text-align: left;
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;

    &&:hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const $SubLineContainer = styled.div`
  border-top: 1px solid #e1e4e8;
  margin-top: 1rem;
  padding-top: 1rem;
`;
