import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
  CARRIERS,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';
import {
  CarrierDimensionType,
  Carriers,
  TransportMethodType,
} from '../Claim/getClaimGroupTransportPlanQuery';

const ENDPOINT = `${APIConfiguration.carrier}TransportPlan/${COMMON_QUERY_PARAMS.ID}`;

export interface TransportPlanSummary {
  transportPlanId: string;
  tenantId: string;
  sellerId: string;
  transportPlanUniqueName?: string | null;
  carrier: Carriers;
  carrierForTMS: CARRIERS;
  carrierDimensionType: CarrierDimensionType;
  senderTerritories: { countryCode: string; zipCodes: string[] }[];
  excludedSenderZipCodes: { countryCode: string; zipCodes: string[] }[];
  recipientTerritories: { countryCode: string; zipCodes: string[] }[];
  excludedRecipientZipCodes: { countryCode: string; zipCodes: string[] }[];
  transportMethod: TransportMethodType;
  pricePerKilogramIntervals: {
    minWeight: number;
    maxWeight: number;
    price: number;
  }[];
}

interface GetTransportPlanByIdQueryArgs {
  transportPlanId: string;
}

export async function getTransportPlanByIdQuery({
  transportPlanId,
}: GetTransportPlanByIdQueryArgs): Promise<
  BackResponse<TransportPlanSummary> | BackResponseFailure
> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.ID, transportPlanId);

    return await apiCall<BackResponse<TransportPlanSummary>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
