import React from 'react';

interface PhoneIconProps {
  color?: string;
  size?: string;
}

const PhoneIcon: React.FC<PhoneIconProps> = ({
  color = 'blue',
  size = '24px',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      color={color}
    >
      <rect x="6" y="2" width="12" height="20" rx="2" ry="2" />
      <circle cx="12" cy="18" r="1" />
    </svg>
  );
};

export default PhoneIcon;
