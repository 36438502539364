import React, { FC, Suspense } from 'react';

import { FEATURE_FLAGS_FOR_SELLER } from '@savgroup-front-common/constants';
import { useGetFeatureFlagsQuery } from '@savgroup-front-common/core/src/api/Authorization/useGetFeatureFlagsQuery';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { ErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { BannersConsumer } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { TakeHomePickupAppointmentHeader } from '@savgroup-front-common/core/src/molecules/TakeHomePickupAppointmentHeader/TakeHomePickupAppointmentHeader';
import { FileSummary, ModuleSummary } from '@savgroup-front-common/types';

import {
  $AppointmentCardContainer,
  $ExpectContainer,
} from '../ExpectsContainer.styles';

import { NewTakeAppointmentContent } from './NewTakeAppointmentContent';
import { TakeAppointmentContent } from './TakeAppointmentContent';

interface TakeAppointmentProps {
  cancelToState?: string;
  fileSummary: FileSummary;
  fileStatusName: string;
  targetStateName: string;
  module: ModuleSummary;
}

export const TakeAppointment: FC<TakeAppointmentProps> = ({
  cancelToState,
  fileSummary,
  fileStatusName,
  targetStateName,
  module,
}) => {
  const isTransportPlanFeatureEnabled = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.TRANSPORT_PLAN,
    sellerId: fileSummary.sellerId,
  });

  return (
    <Suspense fallback={<BaseLoader />}>
      <ErrorBoundary
        fallback={
          <$ExpectContainer>
            <$AppointmentCardContainer>
              <BannersConsumer />
            </$AppointmentCardContainer>
          </$ExpectContainer>
        }
      >
        <$ExpectContainer>
          <$AppointmentCardContainer>
            <TakeHomePickupAppointmentHeader />

            <Card>
              {isTransportPlanFeatureEnabled && (
                <NewTakeAppointmentContent
                  cancelToState={cancelToState}
                  fileSummary={fileSummary}
                  fileStatusName={fileStatusName}
                  targetStateName={targetStateName}
                  module={module}
                />
              )}

              {!isTransportPlanFeatureEnabled && (
                <TakeAppointmentContent
                  cancelToState={cancelToState}
                  fileSummary={fileSummary}
                  fileStatusName={fileStatusName}
                  targetStateName={targetStateName}
                  module={module}
                />
              )}
            </Card>
          </$AppointmentCardContainer>
        </$ExpectContainer>
      </ErrorBoundary>
    </Suspense>
  );
};

TakeAppointment.displayName = 'TakeAppointment';
