import React from 'react';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Link } from '@savgroup-front-common/core/src/atoms/link';
import { SellerLogo } from '@savgroup-front-common/core/src/atoms/Logo/SellerLogo/SellerLogo';
import { SUPPORTED_COMPONENTS } from '@savgroup-front-common/types';

import { AuthConfiguration } from '../../../../../configuration';
import { $LogoContainer } from '../Header.styles';

import { useGetSellerUrl } from './hooks/useGetSellerUrl';
import messages from './messages';

interface LogoProps {
  sellerId: string;
}

const Logo: React.FC<LogoProps> = ({ sellerId }) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const { sellerUrl } = useGetSellerUrl({ sellerId });

  if (isMobileView) {
    return (
      <Link
        ariaLabel={messages.goToHomePage}
        href={sellerUrl.href}
        isExternal={sellerUrl.isExternal}
        dataTestId="headerlogo-image"
      >
        <$LogoContainer
          componentThemeName={SUPPORTED_COMPONENTS.LOGO}
          $isMobileView={isMobileView}
        >
          <SellerLogo
            sellerId={sellerId}
            clientId={AuthConfiguration.clientId}
          />
        </$LogoContainer>
      </Link>
    );
  }

  return (
    <Link
      ariaLabel={messages.goToHomePage}
      href={sellerUrl.href}
      isExternal={sellerUrl.isExternal}
      dataTestId="headerlogo-image"
    >
      <$LogoContainer componentThemeName={SUPPORTED_COMPONENTS.LOGO}>
        <SellerLogo sellerId={sellerId} clientId={AuthConfiguration.clientId} />
      </$LogoContainer>
    </Link>
  );
};

export default Logo;
