import validator from 'validator';
import * as yup from 'yup';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { COUNTRY_CODES } from '@savgroup-front-common/constants';

import { globalMessages } from '../helpers';

const NL_POSTAL_CODE_REGEX = /^\d{4}\s?[A-Z]{2}$/i;

export const postalCodeSchema = yup
  .string()
  .test(
    'test-postal-code',
    globalMessages.form.invalidPostalCode,
    function (postalCode: string | undefined): boolean {
      const { countryCode } = this.options.context || {};
      const countryCodeValue = countryCode || '';

      if (postalCode && countryCodeValue?.toUpperCase() === COUNTRY_CODES.NL) {
        return NL_POSTAL_CODE_REGEX.test(postalCode);
      }

      try {
        return (
          !countryCodeValue ||
          !postalCode ||
          validator.isPostalCode(
            postalCode,
            countryCodeValue.toUpperCase() as any,
          )
        );
      } catch (error) {
        logError(error);

        return true;
      }
    },
  );
