import React, { FC } from 'react';

import {
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters';
import { formatReverseMoneyToString } from '../../../../../formatters/intl';
import { getRecipientTypeMessage } from '../../../../../helpers/i18n';
import { QuotationPendingCustomerValidationForm } from '../../QuotationPendingCustomerValidation.types';
import { ConditionSelection } from '../ConditionSelection';
import { OptionalLineSelection } from '../OptionalLineSelection';

import messages from './messages';
import {
  $AmountCell,
  $QuotationLine,
  $SparePartLine,
} from './SparePartQuotationPendingCustomerValidationLineMobileView.styles';

interface SparePartQuotationPendingCustomerValidationLineMobileViewProps {
  sparePartQuotationLine: SparePartQuotationLine;
  onSelectSparePartQuotationLineChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
  shouldShowRecipientColumn?: boolean;
  isSelected: boolean;
  sparePart?: SparePartSearchSummary;
  isFluid?: boolean;
  preDataTestId: string;
  hasHideQuotationDisplaySparePartsReferencePermission: boolean;
  hasInteractWithQuotationTabPermission: boolean;
  index: number;
}

const SparePartQuotationPendingCustomerValidationLineMobileView: FC<
  SparePartQuotationPendingCustomerValidationLineMobileViewProps
> = ({
  sparePartQuotationLine,
  isSelected,
  shouldShowRecipientColumn,
  onSelectSparePartQuotationLineChange,
  sparePart,
  isFluid = false,
  index,
  preDataTestId,
  hasHideQuotationDisplaySparePartsReferencePermission,
}) => {
  return (
    <>
      <$QuotationLine
        key={sparePartQuotationLine.id}
        $isSelected={isSelected}
        $isActive
        $isFluid={isFluid}
      >
        <div>
          <div>
            <$SparePartLine>
              {!sparePartQuotationLine.isRequiredForRepair && (
                <OptionalLineSelection
                  sparePartQuotationLine={sparePartQuotationLine}
                  onOptionalLineSelectionChange={
                    onSelectSparePartQuotationLineChange
                  }
                  preDataTestId={preDataTestId}
                  index={index}
                />
              )}
              <span
                data-testid={`${preDataTestId}_subCategory_supplierReference_${index}`}
              >
                {hasHideQuotationDisplaySparePartsReferencePermission
                  ? sparePartQuotationLine.manufacturerLabel
                  : [
                      sparePartQuotationLine.supplierReference,
                      sparePartQuotationLine.manufacturerLabel,
                    ]
                      .filter((x) => !!x)
                      .join(' - ')}
              </span>
              <ConditionSelection
                sparePartQuotationLine={sparePartQuotationLine}
                onConditionChange={onSelectSparePartQuotationLineChange}
                dataTestId={`${preDataTestId}_condition_${index}`}
              />
            </$SparePartLine>

            {shouldShowRecipientColumn && (
              <div data-testid={`${preDataTestId}_recipient_${index}`}>
                {sparePartQuotationLine.recipient?.data && (
                  <>
                    <SafeFormattedMessageWithoutSpread
                      message={messages.recipient}
                    />
                    {': '}
                    <SafeFormattedMessageWithoutSpread
                      message={getRecipientTypeMessage(
                        sparePartQuotationLine.recipient?.data,
                      )}
                    />
                  </>
                )}
              </div>
            )}
          </div>
          {sparePart && (
            <$AmountCell
              data-testid={`${preDataTestId}_recommendedSalePriceIncludingTaxes_${index}`}
            >
              {formatReverseMoneyToString(
                sparePart.recommendedSalePriceIncludingTaxes,
              )}
            </$AmountCell>
          )}
        </div>
      </$QuotationLine>
    </>
  );
};

SparePartQuotationPendingCustomerValidationLineMobileView.displayName =
  'SparePartQuotationPendingCustomerValidationLineMobileView';

export default SparePartQuotationPendingCustomerValidationLineMobileView;
