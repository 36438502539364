import React, { FunctionComponent } from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';
import {
  FileSparePartRequestLine,
  RequestLineStatus,
} from '@savgroup-front-common/types';

import { getAdressText, getStatusText } from './DeliveredSpareParts.helpers';
import {
  $DeliveredSparePart,
  $DeliveredSparePartActionButton,
  $DeliveredSparePartActionButtonWrapper,
  $DeliveredSparePartColAlignCenter,
  $DeliveredSparePartColWithPaddingTopForXs,
  $DeliveredSparePartIconAndText,
  $DeliveredSparePartIconAndTextMessage,
} from './DeliveredSpareParts.styles';
import messages from './messages';

export interface DeliveredSparePartProps {
  onChange?: () => void;
  requestLine: FileSparePartRequestLine;
}

const DeliveredSparePart: FunctionComponent<
  React.PropsWithChildren<DeliveredSparePartProps>
> = ({ requestLine, onChange }) => {
  const isDelivered =
    requestLine.requestLineStatus === RequestLineStatus.ORDER_DELIVERED;

  const isDisabled =
    requestLine.requestLineStatus === RequestLineStatus.TO_PROCESS;
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();

  return (
    <$DeliveredSparePart>
      <div data-id={requestLine.requestLineId}>
        <$DeliveredSparePartColWithPaddingTopForXs>
          <Field
            label={messages.ref}
            dataTestId={`deliveredSparePart_${requestLine.requestLineId}_sparePartReference`}
            isNewUi={!isMobileView}
          >
            {requestLine.sparePartReference}
          </Field>
        </$DeliveredSparePartColWithPaddingTopForXs>
        <$DeliveredSparePartColWithPaddingTopForXs>
          <Field
            label={messages.supplier}
            dataTestId={`deliveredSparePart_${requestLine.requestLineId}_supplierName`}
            isNewUi={!isMobileView}
          >
            {requestLine.supplierName}
          </Field>
        </$DeliveredSparePartColWithPaddingTopForXs>
        <$DeliveredSparePartColWithPaddingTopForXs>
          <Field
            label={messages.statusKey}
            dataTestId={`deliveredSparePart_${requestLine.requestLineId}_requestLineStatus`}
            isNewUi={!isMobileView}
          >
            <SafeFormattedMessageWithoutSpread
              message={getStatusText(requestLine.requestLineStatus)}
            />
          </Field>
        </$DeliveredSparePartColWithPaddingTopForXs>
        <$DeliveredSparePartColWithPaddingTopForXs>
          <Field
            label={messages.address}
            dataTestId={`deliveredSparePart_${requestLine.requestLineId}_deliveryAddress`}
            isNewUi={!isMobileView}
          >
            {getAdressText(requestLine.deliveryAddress)}
          </Field>
        </$DeliveredSparePartColWithPaddingTopForXs>
      </div>

      <div>
        {!isDelivered && (
          <$DeliveredSparePartActionButtonWrapper>
            <$DeliveredSparePartActionButton
              theme={theme}
              disabled={isDisabled}
              type={BUTTON_TYPES.BUTTON}
              onClick={() => onChange && onChange()}
              dataTestId={`deliveredSparePart_${requestLine.requestLineId}_confirm`}
            >
              <SafeFormattedMessageWithoutSpread message={messages.confirm} />
            </$DeliveredSparePartActionButton>
          </$DeliveredSparePartActionButtonWrapper>
        )}

        {isDelivered && (
          <$DeliveredSparePartColAlignCenter
            theme={theme}
            data-testid={`deliveredSparePart_${requestLine.requestLineId}_submitted`}
          >
            <$DeliveredSparePartIconAndText>
              <CheckIcon color={theme.colors.success} />
              <$DeliveredSparePartIconAndTextMessage>
                <SafeFormattedMessageWithoutSpread
                  message={messages.submitted}
                />
              </$DeliveredSparePartIconAndTextMessage>
            </$DeliveredSparePartIconAndText>
          </$DeliveredSparePartColAlignCenter>
        )}
      </div>
    </$DeliveredSparePart>
  );
};

export default DeliveredSparePart;
