import React, { FunctionComponent, ReactElement, ReactNode, Ref } from 'react';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { MessageType } from '@savgroup-front-common/types';

import { useNewUiContext } from '../../components/NewUiProvider/NewUiProvider';
import { safeFormattedIntlString } from '../../formatters';
import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';
import { CrossIcon, LoaderIcon, SearchNewBoIcon } from '../../protons/icons';

import messages from './messages';
import {
  $SearchActionContainer,
  $SearchClearButton,
  $SearchFilter,
  $SearchIconContainer,
  $SearchInput,
  $SearchInputContainer,
  $SearchLoadingContainer,
} from './SearchInput.styles';
import { SearchInputVariant } from './SearchInput.types';

interface SearchInputProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClear?: React.MouseEventHandler<HTMLButtonElement>;
  placeholder?: MessageType | string;
  isLoading?: boolean;
  dataTestId?: string;
  children?: ReactNode;
  variant?: SearchInputVariant;
  isActive?: boolean;
  searchFilter?: ReactElement;
  isHightContrast?: boolean;
}
interface SearchInputWithRefProps extends SearchInputProps {
  forwardedRef: Ref<HTMLInputElement>;
}

const SearchInput: FunctionComponent<
  React.PropsWithChildren<SearchInputWithRefProps>
> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  isLoading = false,
  forwardedRef,
  onKeyDown,
  onClear,
  dataTestId = 'searchInput',
  placeholder = messages.placeholder,
  children,
  variant = SearchInputVariant.DEFAULT,
  isActive,
  searchFilter,
  isHightContrast = false,
}) => {
  const theme = useTheme();
  const isNewUiEnabled = useIsNewUiEnabled();
  const { isDarkModeEnabled } = useNewUiContext();

  return (
    <$SearchInputContainer
      $variant={variant}
      $isNewUiEnabled={isNewUiEnabled}
      $isActive={isActive}
      $isHightContrast={isDarkModeEnabled || isHightContrast}
    >
      <$SearchIconContainer $isNewUiEnabled={isNewUiEnabled}>
        {!isLoading && <SearchNewBoIcon size="22px" />}

        {isLoading && (
          <$SearchLoadingContainer>
            <LoaderIcon
              color={
                isDarkModeEnabled || isHightContrast
                  ? theme.colors.white
                  : theme.colors.mainTextColor
              }
              size="15px"
              strokeWidth="10"
            />
          </$SearchLoadingContainer>
        )}
      </$SearchIconContainer>

      <$SearchInput
        $variant={variant}
        $isNewUiEnabled={isNewUiEnabled}
        placeholder={safeFormattedIntlString(placeholder)}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        onFocus={onFocus}
        ref={forwardedRef}
        onKeyDown={onKeyDown}
        data-testid={`${dataTestId}_input`}
        $isHightContrast={isHightContrast}
      />
      <$SearchActionContainer>
        {onClear && (
          <$SearchClearButton
            icon={<CrossIcon />}
            naked
            type={BUTTON_TYPES.BUTTON}
            onClick={onClear}
            dataTestId={`${dataTestId}_clearButton`}
            ariaLabel={messages.clearSearchValue}
            small
          />
        )}
        {children}

        {searchFilter && <$SearchFilter>{searchFilter}</$SearchFilter>}
      </$SearchActionContainer>
    </$SearchInputContainer>
  );
};

export default React.forwardRef<HTMLInputElement, SearchInputProps>(
  (props, ref) => <SearchInput forwardedRef={ref} {...props} />,
);
