import { MutableRefObject, ReactElement, ReactNode } from 'react';

import { MessageType } from '@savgroup-front-common/types';

export enum MENU_ITEM_TYPES {
  LINK = 'link',
  BUTTON = 'button',
  TITLE = 'title',
  MENU = 'menu',
  CUSTOM = 'custom',
  BACK = 'back',
  LINK_TO_COMPONENT = 'linkToComponent',
  SEARCH = 'search',
}

export enum MENU_POSITIONS {
  RIGHT = 'right',
  LEFT = 'left',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum MENU_ACTION_TYPES {
  ADD_PATH = 'addPath',
  REMOVE_PATH = 'removePath',
  RESET_PATH = 'resetPath',
}

export interface CustomMenuProps {
  type: MENU_ITEM_TYPES.CUSTOM;
  children: ReactElement<{ onClose?: () => void }>;
  key: string;
}
interface TitleMenuProps {
  type: MENU_ITEM_TYPES.TITLE;
  ref?: MutableRefObject<HTMLHeadingElement>;
  label: MessageType | string | number;
  isLoading?: boolean;
  key: string;
}
interface LinkMenuProps {
  type: MENU_ITEM_TYPES.LINK;
  ref?: MutableRefObject<HTMLAnchorElement>;
  label: MessageType | string | number;
  isLoading?: boolean;
  isDisabled?: boolean;
  color?: string;
  icon?: ReactElement;
  href: string | undefined;
  key: string;
  target?: string;
  dataTestId?: string;
}
interface SubMenuProps {
  type: MENU_ITEM_TYPES.MENU;
  ref?: MutableRefObject<HTMLButtonElement>;
  label: MessageType | string | number;
  isLoading?: boolean;
  isDisabled?: boolean;
  color?: string;
  icon?: ReactElement;
  key: string;
  items: MenuItem[];
  isSearchable?: boolean;
}
export interface SubMenuEnhancedProps extends Omit<SubMenuProps, 'items'> {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  items: MenuItemEnhanced[];
}
interface BackMenuProps {
  type: MENU_ITEM_TYPES.BACK;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  key: string;
}

export interface ButtonMenuProps {
  dataTestId?: string;
  type: MENU_ITEM_TYPES.BUTTON;
  ref?: MutableRefObject<HTMLButtonElement>;
  label: MessageType | string | number;
  description?: MessageType | string | number;
  isLoading?: boolean;
  isDisabled?: boolean;
  color?: string;
  key: string;
  icon?: ReactElement;
  onClick: (
    response: ButtonMenuProps,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  SecondaryAction?: ReactNode;
}
export interface SearchMenuProps {
  type: MENU_ITEM_TYPES.SEARCH;
  key: string;
}

export type MenuItem =
  | CustomMenuProps
  | TitleMenuProps
  | LinkMenuProps
  | SubMenuProps
  | ButtonMenuProps;

export type MenuItemEnhanced =
  | CustomMenuProps
  | TitleMenuProps
  | LinkMenuProps
  | SubMenuEnhancedProps
  | BackMenuProps
  | ButtonMenuProps
  | SearchMenuProps;
