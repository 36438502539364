import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { history } from '@savgroup-front-common/configuration';
import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';
import rootReducer from 'myaccount/domains/rootReducer';
import rootSaga from 'myaccount/domains/rootSaga';

import { loadState } from './localStorage';

const { routerMiddleware } = createReduxHistoryContext({
  history,
});

export default function configureStoreAndHistory() {
  const sagaMiddleware = createSagaMiddleware();
  const defaultMiddleware = [sagaMiddleware, routerMiddleware, thunk];

  const middlewares =
    currentAppEnvironment === APP_ENVS.PROD ||
    import.meta.env.VITE_ENABLE_REDUX_LOGGER === 'false'
      ? defaultMiddleware
      : [...defaultMiddleware, logger];

  const persistedStore = loadState();

  const store = configureStore({
    reducer: rootReducer(),
    middleware: () => middlewares,
    devTools: currentAppEnvironment !== APP_ENVS.PROD,
    preloadedState: persistedStore,
  });

  sagaMiddleware.run(rootSaga);

  return { store };
}
