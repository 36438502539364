import React from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { FLEX_ALIGN } from '@savgroup-front-common/types';

import { STEP_CONFIG } from '../../../ClassiqueRoutes.config';
import { PROFESSIONAL_STEP_CONFIG } from '../../../ProfessionalRoutes.config';

import { getStepHeaderTitle } from './StepTitle.helpers';

interface StepTitleProps {
  stepName: STEP_CONFIG | PROFESSIONAL_STEP_CONFIG;
}

const StepTitle: React.FC<StepTitleProps> = ({ stepName }) => {
  return (
    <Row start="xs">
      <Heading
        level={2}
        align={FLEX_ALIGN.LEFT}
        dataTestId={`title_${stepName}`}
        ariaLabel={safeFormattedIntlString(getStepHeaderTitle(stepName))}
      >
        <SafeFormattedMessageWithoutSpread
          message={getStepHeaderTitle(stepName)}
        />
      </Heading>
    </Row>
  );
};

export default StepTitle;
