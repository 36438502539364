import 'intl';
import throttle from 'lodash/throttle';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { appShell } from '@savgroup-front-common/core/src/helpers';
import { generateUserManager } from '@savgroup-front-common/core/src/services/userManager';
import 'myaccount/helpers/localMoment';
import configureStoreAndHistory from 'myaccount/store/configureStore';
import { saveState } from 'myaccount/store/localStorage';
import 'myaccount/view/wdyr';

import '../datadog.config';

import { AuthConfiguration } from './configuration';
import { fetchAuthorityUrl } from './helpers';
import App from './view/app/App';

if (![].at) {
  Array.prototype.at = function (pos) {
    return this.slice(pos, pos + 1)[0];
  };
}

async function getUserManager() {
  await fetchAuthorityUrl();

  return { userManager: generateUserManager(AuthConfiguration) };
}

getUserManager().then(({ userManager }) => {
  const { store } = configureStoreAndHistory();

  store.subscribe(() => {
    throttle(() => {
      saveState(store.getState());
    }, 1000);
  });

  appShell({
    userManager,
    render: () => {
      const container = document.getElementById('root');
      const root = createRoot(container);

      root.render(<App store={store} userManager={userManager} />);
    },
  })
    // eslint-disable-next-line no-console
    .then(() => console.info('App launched with success.'))
    // eslint-disable-next-line no-console
    .catch((e) => console.error('App failed to launch.', e));
});
