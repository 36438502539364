import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';

interface UseGetClaimReasonsArgs {
  claimGroupId?: string;
  suspense?: boolean;
}

const GetReasons = 'getReasons';

const useGetClaimReasons = ({
  claimGroupId,
  suspense,
}: UseGetClaimReasonsArgs) => {
  const { pushErrors } = useToasts();

  const intl = useIntl();

  const { data: reasons, isLoading } = useQuery(
    [GetReasons, { claimGroupId, locale: intl?.locale }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getReasonsByClaimGroup({
        claimGroupId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense,
    },
  );

  return {
    reasons: reasons || [],
    reasonsIsLoading: isLoading,
  };
};

export default useGetClaimReasons;
