import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { RequiredPayloadFieldIsNotProvided } from '@savgroup-front-common/exceptions';

import { callAndGetResponse, reduxExtendedRequestSaga } from '../../services';
import { selectLocale } from '../i18n/selectors';

import * as actionTypes from './actionTypes';

function* uploadFileWorker({ payload = {} }) {
  const { indexer, file, endpoint } = payload;

  const meta = { indexer };

  if (!endpoint) {
    throw new RequiredPayloadFieldIsNotProvided('endpoint');
  }
  if (!indexer) {
    throw new RequiredPayloadFieldIsNotProvided('indexer');
  }

  const language = yield select(selectLocale);

  const body = new FormData();

  if (file) {
    body.append('fileBinary', file);
  }

  yield call(
    reduxExtendedRequestSaga,
    actionTypes.UPLOAD_FILE,
    endpoint,
    {
      method: SUPPORTED_METHODS.POST,
      body,
      language,
    },
    meta,
  );

  yield put(actionTypes.UPLOAD_FILE.end(meta));
}
function* uploadFileWatcher() {
  yield takeEvery(actionTypes.UPLOAD_FILE.BASE, uploadFileWorker);
}

function* deleteFileWorker({ payload = {} }) {
  const { indexer, endpoint, blobValue } = payload;
  const meta = { indexer };

  if (!endpoint) {
    throw new RequiredPayloadFieldIsNotProvided('endpoint');
  }

  yield call(
    callAndGetResponse,
    actionTypes.DELETE_FILE,
    endpoint,
    {
      method: SUPPORTED_METHODS.DELETE,
      json: {
        blobName: blobValue,
      },
    },
    meta,
  );

  yield put(actionTypes.DELETE_FILE.end(meta));
}
function* deleteFileWatcher() {
  yield takeEvery(actionTypes.DELETE_FILE.BASE, deleteFileWorker);
}

export default function* testRulesSaga() {
  try {
    yield all([uploadFileWatcher(), deleteFileWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}
