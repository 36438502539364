import { APIConfiguration } from '@savgroup-front-common/configuration';

import { PickupPointOption } from '../../../../../../../IrshPages.types';

import { ICON_TYPE } from './MapWithControlledZoom.constants';

export const getDefaultIconUrl = ({
  type,
  carrierBrand,
  chain,
}: {
  type: string;
  carrierBrand: string;
  chain: string;
}) => {
  const iconsPath = {
    [ICON_TYPE.POINT]: `${APIConfiguration.catalogCDN}carriers/marker_${(
      chain ||
      carrierBrand ||
      'external'
    ).toLowerCase()}.svg`,
    [ICON_TYPE.SELECTED_POINT]: `${
      APIConfiguration.catalogCDN
    }carriers/marker_${(
      chain ||
      carrierBrand ||
      'external'
    ).toLowerCase()}_selected.svg`,
  };

  return iconsPath[type];
};
export const getIconFromPublicDocumentsPath = ({
  type,
  carrierBrand,
  chain,
  sellerId,
}: {
  type: string;
  carrierBrand: string;
  chain: string;
  sellerId: string;
}) => {
  const iconsPath = {
    [ICON_TYPE.POINT]: `${
      APIConfiguration.imageCDN
    }sellers/${sellerId}/marker_${(
      chain ||
      carrierBrand ||
      'external'
    ).toLowerCase()}.svg`,
    [ICON_TYPE.SELECTED_POINT]: `${
      APIConfiguration.imageCDN
    }sellers/${sellerId}/marker_${(
      chain ||
      carrierBrand ||
      'external'
    ).toLowerCase()}_selected.svg`,
  };

  return iconsPath[type];
};

export const getUrlsForPickupPoint = ({
  pickupPointOption,
  sellerId,
}: {
  pickupPointOption: PickupPointOption;
  sellerId: string;
}) => {
  const pickupPoint = pickupPointOption.data.pickupPoint;

  return {
    markerUrlFromPublicDocument: getIconFromPublicDocumentsPath({
      type: ICON_TYPE.POINT,
      carrierBrand: pickupPoint.carrierBrand,
      chain: pickupPoint.chain ?? '',
      sellerId,
    }),
    selectedMarkerUrlFromPublicDocument: getIconFromPublicDocumentsPath({
      type: ICON_TYPE.SELECTED_POINT,
      carrierBrand: pickupPoint.carrierBrand,
      chain: pickupPoint.chain ?? '',
      sellerId,
    }),
    defaultMarkerUrl: getDefaultIconUrl({
      type: ICON_TYPE.POINT,
      carrierBrand: pickupPoint.carrierBrand,
      chain: pickupPoint.chain ?? '',
    }),
    defaultSelectedMarkerUrl: getDefaultIconUrl({
      type: ICON_TYPE.SELECTED_POINT,
      carrierBrand: pickupPoint.carrierBrand,
      chain: pickupPoint.chain ?? '',
    }),
  };
};
