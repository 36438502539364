import React, { FC, FunctionComponent, ReactElement } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ButtonProps } from '@savgroup-front-common/core/src/atoms/button';
import { MessageType } from '@savgroup-front-common/types';

import Banner from './Banner';
import { $BanneredLayout } from './BanneredLayout.styles';
import BannerTitle from './BannerTitle';
import Content from './Content';

interface BanneredLayoutProps {
  Banner: FunctionComponent<{ children?: ReactElement | ReactElement[] }>;
  BannerTitle: FunctionComponent<{
    breadcrumbs?: {
      label: string | MessageType | MessageDescriptor;
      route?: string;
    }[];
    title?: string | MessageType | MessageDescriptor;
    description?: string | MessageType | MessageDescriptor;
    actions?: {
      label: string | MessageType | MessageDescriptor;
      route: string;
      icon?: ReactElement;
      props?: ButtonProps;
      primary?: boolean;
    }[];
  }>;
  Content: FunctionComponent<{
    children?: ReactElement | ReactElement[];
    hasBannerUrl?: boolean;
  }>;
}

const BanneredLayout: FC<{
  children?: ReactElement[] | ReactElement;
  isHomePage?: boolean;
}> &
  BanneredLayoutProps = ({ children, isHomePage }) => {
  return (
    <$BanneredLayout $isNeutralBackground={isHomePage}>
      {children}
    </$BanneredLayout>
  );
};

BanneredLayout.Banner = Banner;
BanneredLayout.BannerTitle = BannerTitle;
BanneredLayout.Content = Content;

export default BanneredLayout;
