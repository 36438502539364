import React, { FC } from 'react';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  INPUT_TYPES,
  media,
} from '@savgroup-front-common/constants';
import {
  SparePartCondition,
  SparePartQuotationLine,
  SparePartSearchSummary,
} from '@savgroup-front-common/types';

import { Button } from '../../../../../atoms/button';
import { FieldMessage } from '../../../../../atoms/Form/common';
import { getFinalFieldState } from '../../../../../atoms/Form/common/helpers/getFinalFieldState';
import { SafeFormattedMessageWithoutSpread } from '../../../../../formatters';
import getSparePartConditionMessage from '../../../../../formatters/getSparePartConditionMessage';
import { formatReverseMoneyToString } from '../../../../../formatters/intl';
import { EnergySavings } from '../../../../../protons/icons';
import { QuotationPendingCustomerValidationForm } from '../../QuotationPendingCustomerValidation.types';

import { useConditionSelection } from './ConditionSelection.hooks';
import {
  $ButtonGroup,
  $ConditionSelection,
  $HiddenBlock,
  $UsedButton,
} from './ConditionSelection.styles';
import messages from './messages';

interface ConditionSelectionProps {
  sparePartQuotationLine: SparePartQuotationLine;
  onConditionChange: ({
    values,
    alternativeSpareParts,
  }: {
    values: QuotationPendingCustomerValidationForm;
    alternativeSpareParts: Record<
      string,
      Record<string, SparePartSearchSummary>
    >;
  }) => Promise<{ failure: boolean }>;
  isFluid?: boolean;
  dataTestId: string;
}
const ConditionSelection: FC<ConditionSelectionProps> = ({
  sparePartQuotationLine,
  onConditionChange,
  isFluid = false,
  dataTestId,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const {
    handleSetNewSparePart,
    handleSetUsedSparePart,
    formContext,
    isNewSparePartAvailable,
    isUsedSparePartAvailable,
    potentialNewSparePart,
    potentialUsedSparePart,
  } = useConditionSelection({ sparePartQuotationLine, onConditionChange });

  const {
    formState: { errors },
    watch,
    register,
  } = formContext;

  const condition = watch(
    `sparePartLines.${sparePartQuotationLine.id}.condition`,
  );

  const [status, message] = getFinalFieldState({
    errors: { isStatus: false, messages: errors },
    name: `sparePartLines.${sparePartQuotationLine.id}.condition`,
  });

  const isNewConditionSelected = condition === SparePartCondition.NEW;
  const isOldConditionSelected =
    condition === SparePartCondition.RECONDITIONED ||
    condition === SparePartCondition.USED;

  if (!isNewSparePartAvailable || !isUsedSparePartAvailable) {
    return (
      <>
        <$HiddenBlock>
          <input
            type={INPUT_TYPES.RADIO}
            value={SparePartCondition.NEW}
            {...register(
              `sparePartLines.${sparePartQuotationLine.id}.condition`,
            )}
          />
          <input
            type={INPUT_TYPES.RADIO}
            value={SparePartCondition.RECONDITIONED}
            {...register(
              `sparePartLines.${sparePartQuotationLine.id}.condition`,
            )}
          />
          <input
            type={INPUT_TYPES.RADIO}
            value={SparePartCondition.USED}
            {...register(
              `sparePartLines.${sparePartQuotationLine.id}.condition`,
            )}
          />
        </$HiddenBlock>

        {condition && (
          <div data-testid={dataTestId}>
            <SafeFormattedMessageWithoutSpread
              message={getSparePartConditionMessage(condition)}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <$ConditionSelection $isFluid={isFluid}>
      <$HiddenBlock>
        <input
          type={INPUT_TYPES.RADIO}
          value={SparePartCondition.NEW}
          {...register(`sparePartLines.${sparePartQuotationLine.id}.condition`)}
        />
        <input
          type={INPUT_TYPES.RADIO}
          value={SparePartCondition.RECONDITIONED}
          {...register(`sparePartLines.${sparePartQuotationLine.id}.condition`)}
        />
        <input
          type={INPUT_TYPES.RADIO}
          value={SparePartCondition.USED}
          {...register(`sparePartLines.${sparePartQuotationLine.id}.condition`)}
        />
      </$HiddenBlock>

      <$ButtonGroup>
        <Button
          type={BUTTON_TYPES.BUTTON}
          small
          tertiary
          fluid={isFluid}
          isPressed={condition === SparePartCondition.NEW}
          onClick={handleSetNewSparePart}
          dataTestId={`${dataTestId}_New`}
        >
          <SafeFormattedMessageWithoutSpread
            message={messages.new}
            values={{
              amount:
                !isMobileView &&
                isOldConditionSelected &&
                potentialNewSparePart &&
                ` (${formatReverseMoneyToString(
                  potentialNewSparePart.recommendedSalePriceIncludingTaxes,
                )}) `,
            }}
          />
        </Button>

        <$UsedButton
          type={BUTTON_TYPES.BUTTON}
          tertiary
          small
          fluid={isFluid}
          isPressed={
            condition &&
            [
              SparePartCondition.USED,
              SparePartCondition.RECONDITIONED,
            ].includes(condition)
          }
          onClick={handleSetUsedSparePart}
          dataTestId={`${dataTestId}_Used`}
        >
          <EnergySavings />

          <SafeFormattedMessageWithoutSpread
            message={messages.used}
            values={{
              amount:
                !isMobileView &&
                isNewConditionSelected &&
                potentialUsedSparePart &&
                ` (${formatReverseMoneyToString(
                  potentialUsedSparePart.recommendedSalePriceIncludingTaxes,
                )}) `,
            }}
          />
        </$UsedButton>
      </$ButtonGroup>
      <FieldMessage message={message} status={status} />
    </$ConditionSelection>
  );
};

ConditionSelection.displayName = 'ConditionSelection';

export default ConditionSelection;
