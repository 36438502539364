import { useQuery } from 'react-query';

import { CommonWorkflowService } from '../../../../../../../api';
import { sortBy } from '../../../../../../../helpers/sorter';
import useBanners from '../../../../../../../molecules/NotificationsProvider/BannersProvider/hooks';
import { useGetPageSize } from '../../../../../hooks/useGetPageSize';
import adaptTimeslotToDetailedSchedule from '../../../adapters/adaptTimeslotToDetailedSchedule';
import { getStartAndEndDate } from '../helpers/getStartAndEndDate';
import messages from '../messages';

export const useGetTechnicalInterventionTimeSlots = ({
  fileId,
  pageNumber,
  enableGetSchedules,
}: {
  fileId: string;
  pageNumber: number;
  enableGetSchedules: boolean;
}) => {
  const pageSize = useGetPageSize();
  const { removeAllNotifications, pushErrors } = useBanners({
    extendedMessages: messages,
  });

  const {
    isFetching: isGetTimeslotsLoading,
    data: detailedSchedules,
    refetch,
  } = useQuery(
    [
      'getTechnicalInterventionTimeSlotsLegacy',
      { fileId, pageNumber, pageSize },
    ],
    async () => {
      removeAllNotifications();

      const { startDate, endDate } = getStartAndEndDate({
        pageNumber,
        pageSize,
      });

      const response =
        await CommonWorkflowService.getTechnicalInterventionTimeSlots({
          fileId,
          startDate,
          endDate,
        });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return adaptTimeslotToDetailedSchedule(
        response.value.sort(sortBy({ fieldName: 'start' })),
        { pageNumber, pageSize },
      );
    },
    { enabled: enableGetSchedules, staleTime: 5_000, retry: false },
  );

  return { isGetTimeslotsLoading, detailedSchedules, refetch };
};
