import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { useGetCarrierProductByIdQuery } from '@savgroup-front-common/core/src/hooks';
import { AppointmentCard } from '@savgroup-front-common/core/src/molecules/AppointmentCard';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import {
  CARRIERS,
  FileSummary,
  ModuleSummary,
} from '@savgroup-front-common/types';

import { submitWorkflowAction } from '../../../../../../../domains/Customer/actionCreators';

interface TakeAppointmentProps {
  cancelToState?: string;
  fileStatusName: string;
  fileSummary: FileSummary;
  targetStateName: string;
  module?: ModuleSummary;
}

export const TakeAppointmentContent: FC<TakeAppointmentProps> = ({
  cancelToState,
  fileStatusName,
  fileSummary,
  targetStateName,
  module,
}) => {
  const dispatch = useDispatch();
  const { fileId } = useParams() as { fileId: string };

  const { carrierProductSummary } = useGetCarrierProductByIdQuery({
    carrierProductId: fileSummary?.transportDepositSummary?.carrierProductId,
  });

  const handleSubmit = useCallback(
    ({
      module,
      fromState,
      toState,
      expectValues,
      comment = '',
      toCustomerComment = '',
    }: {
      module?: ModuleSummary;
      fromState?: string;
      toState?: string;
      toCustomerComment?: string;
      comment?: string;
      expectValues?: Record<string, any>;
    }) => {
      dispatch(
        submitWorkflowAction({
          module,
          fromState,
          toState,
          comment,
          expects: expectValues,
          toCustomerComment,
          fileId,
        }),
      );
    },
    [dispatch, fileId],
  );

  const carrier = carrierProductSummary?.carrierOperator as CARRIERS;

  if (!carrier) {
    return <BaseLoader />;
  }

  return (
    <AppointmentCard
      fileId={fileId}
      carrier={carrier}
      onSubmit={() => {
        handleSubmit({
          module,
          fromState: fileStatusName,
          toState: targetStateName,
        });
      }}
      onCancel={() =>
        handleSubmit({
          module,
          fromState: fileStatusName,
          toState: cancelToState,
        })
      }
      productLocationCountryCode={
        fileSummary.productLocationCountryCode as COUNTRY_CODES
      }
    />
  );
};
