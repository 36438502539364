import React, { FC } from 'react';
import { Grid } from 'react-styled-flexboxgrid';

import { ExternalPortal } from '@savgroup-front-common/core/src/protons/ExternalPortal/ExternalPortal';

import { $Footer, $RowFooter } from './PageFooterContainer.styles';

interface PageFooterContainerProps {
  reverseChildren?: boolean;
}

export const PageFooterContainer: FC<
  React.PropsWithChildren<PageFooterContainerProps>
> = ({ children, reverseChildren = true }) => {
  return (
    <ExternalPortal>
      <$Footer>
        <Grid fluid={false}>
          <$RowFooter between="xs" $reverseChildren={reverseChildren}>
            {children}
          </$RowFooter>
        </Grid>
      </$Footer>
    </ExternalPortal>
  );
};
