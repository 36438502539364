import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $RadioCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  background: transparent;
`;

export const $ImageHoveredContainer = styled.div`
  display: none;
  align-items: flex-start;
  justify-content: center;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  svg {
    @media ${media.minWidth.sm} {
      width: 32px;
    }
    width: 24px;
    height: auto;
  }
`;

export const $ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  svg {
    @media ${media.minWidth.sm} {
      width: 32px;
    }
    width: 24px;
    height: auto;
  }
`;

interface RadioCardBodyProps {
  hasImage: boolean;
}

export const $RadioCardBody = styled.div<RadioCardBodyProps>`
  flex: 1;
  padding-left: unset;
  @media ${media.minWidth.sm} {
    padding-left: ${({ hasImage }) => (hasImage ? '24px' : 'unset')};
    margin-top: unset;
  }

  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  overflow: hidden;
`;

export const $RadioCardContainer = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.white};

  cursor: pointer;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};

  flex: 1;
`;

export const $RadioCardFirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;

  @media ${media.minWidth.sm} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  flex: 1;
`;

export const $ItemTitle = styled.div<{ $isSelected: boolean }>`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${({ theme, $isSelected }) => {
    if ($isSelected) {
      return theme.colors.primary;
    }

    return theme.newUI.defaultColors.menu;
  }};
  text-align: left;
`;

export const $RadioCardLabelContainer = styled.button<{
  disabled?: boolean;
  $isSelected: boolean;
}>`
  background-color: transparent;

  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 90px;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};

  ${decelerateTimingFunctionTransition};

  border-width: ${rem(1)};
  border-style: solid;
  border-color: ${({ theme, $isSelected }) => {
    if ($isSelected) {
      return theme.colors.primary;
    }

    return theme.colors.default;
  }};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};

    ${$RadioCardInfo} *, ${$ItemTitle} * {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media ${media.minWidth.sm} {
    padding: 24px;
  }
  padding: 12px;
`;

export const $RadioCardSubLine = styled.div`
  border-top: 1px solid #e1e4e8;
  margin-top: 1rem;
  padding-top: 1rem;
`;

export const $ItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${media.minWidth.sm} {
    flex-direction: row;
    align-items: center;
  }
  align-items: start;
  justify-content: space-between;
`;

export const $ItemHighlighting = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.25rem 0;
  &&&& {
    color: ${({ theme }) => theme.colors.success} !important;
  }
`;

export const $ItemHighlightingIcon = styled.span`
  margin-right: 0.5rem;
  display: flex;
`;

export const $ItemDescription = styled.div`
  width: 100%;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #8a94a6;
  margin: 0.25rem 0;
  white-space: pre-wrap;
  text-align: left;
`;

export const $ItemPrice = styled.div<{
  $hasPrice: boolean;
  $hasPickupPointType: boolean;
}>`
  text-align: left;
  display: flex;
  gap: 0.25rem;

  ${({ $hasPrice, theme }) =>
    $hasPrice
      ? `
        font-weight: ${theme.fonts.weight.mediumBold};
        font-size: ${({
          $hasPickupPointType,
        }: {
          $hasPickupPointType: boolean;
        }) => ($hasPickupPointType ? '14px' : '16px')};
        line-height: 16px;
        color: ${theme.colors.primary};
        @media ${media.maxWidth.sm} {
          margin-top: unset;
          font-weight: ${theme.fonts.weight.mediumFont};
        }
      `
      : `
        color: ${theme.colors.paragraphTextColor};
        @media ${media.maxWidth.sm} {
          color: ${theme.colors.black};
          margin-top: 1rem;
          font-weight: ${theme.fonts.weight.mediumFont};
        }
      `}
`;

export const $ItemContentTextWrapper = styled.div`
  width: 80%;
  @media ${media.maxWidth.sm} {
    width: 100%;
  }
`;

export const $ItemLogo = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;

  & img {
    max-height: 36px;
    max-width: 100%;
  }
`;
export const $ItemDistance = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
`;
