import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { LANGUAGES } from '@savgroup-front-common/constants';
import { AutocompleteOption } from '@savgroup-front-common/types';

import CommonOwnerService from '../../../api/Owner/CommonOwnerService';
import { changeLocaleLanguage } from '../../../domains/i18n/actions';
import { selectLocale } from '../../../domains/i18n/selectors';

const useLangageChooser = () => {
  const currentLanguage = useSelector(selectLocale);
  const dispatch = useDispatch();

  const { mutateAsync: handleChangeLanguage, isLoading } = useMutation(
    ['setOwnerLanguagePreference'],
    async (locale?: AutocompleteOption<string> | null) => {
      if (!locale) {
        return undefined;
      }

      const response = await CommonOwnerService.setOwnerLanguagePreference({
        preferences: {
          language: locale.value,
        },
      });

      if (response.failure) {
        logError(response.errors);

        return;
      }

      dispatch(
        changeLocaleLanguage({
          locale: locale.value.toLowerCase() as LANGUAGES,
        }),
      );
    },
  );

  return {
    currentLanguage,
    handleChangeLanguage,
    isLoading,
  };
};

export default useLangageChooser;
