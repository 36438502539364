import { useDeepCompareEffect } from 'react-use';

import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';

import { IrshStepValues } from '../IrshPages.types';

import { useGetHandlingByClaimGroup } from './useGetHandlingByClaimGroup';

export const useInitialiseHandling = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { values, onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  const { handling } = useGetHandlingByClaimGroup({
    claimGroupId,
    suspense: true,
    onSuccess: (handlingSummary) => {
      onValuesUpdate({
        newValues: {
          handling: handlingSummary,
        },
      });
    },
  });

  useDeepCompareEffect(() => {
    if (!values?.handling && handling) {
      onValuesUpdate({
        newValues: {
          handling,
        },
      });
    }
  }, [onValuesUpdate, values?.handling, handling]);

  return { handling };
};
