import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { $TabViewTransitionContainer } from './RouteTabs.styles';
import { RouteTabConfig } from './RouteTabs.types';

interface RouteTabsBodyProps {
  config: RouteTabConfig[];
}

const RouteTabsBody: FunctionComponent<RouteTabsBodyProps> = ({ config }) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$TabViewTransitionContainer $isNewUi={isNewUiEnabled}>
      <Routes>
        {config.map(({ path, Component, subTab, name }) => {
          if (subTab) {
            return subTab.map(({ path, Component, name: subName }) => {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    <div data-testid={`routeTabBody_${name}_${subName}`}>
                      {Component && <Component />}
                    </div>
                  }
                />
              );
            });
          }

          return (
            <Route
              key={path}
              path={`${path}/*`}
              element={
                <div data-testid={`routeTabBody_${name}`}>
                  {Component && <Component />}
                </div>
              }
            />
          );
        })}

        <Route
          index
          element={
            config.at(0)?.url ? <Navigate to={config[0].url} replace /> : ''
          }
        />
      </Routes>
    </$TabViewTransitionContainer>
  );
};

export default RouteTabsBody;
