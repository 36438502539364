import { useQuery } from 'react-query';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { COUNTRY_CODES } from '@savgroup-front-common/constants';

import {
  getClaimGroupTransportPlanQuery,
  TransportMethodType,
} from '../getClaimGroupTransportPlanQuery';

export const GET_CLAIM_GROUP_TRANSPORT_PLAN_QUERY_KEY =
  'getClaimGroupTransportPlanQuery';

export const homeMethods = [
  TransportMethodType.OnSiteCollection,
  TransportMethodType.HomePickup,
  TransportMethodType.HomePickupProvidedBySeller,
  TransportMethodType.LabelProvidedBySeller,
  TransportMethodType.LabelProvidedByCustomer,
  TransportMethodType.HomeDelivery,
  TransportMethodType.BulkyHomeDelivery,
  TransportMethodType.HomeIntervention,
];
export const pickupMethods = [
  TransportMethodType.RelayPointDeposit,
  TransportMethodType.PickUpRelayPointDelivery,
  TransportMethodType.DropAtStoreDeposit,
  TransportMethodType.PickUpStoreDelivery,
];

export const immediateDropAtStoreMethods = [
  TransportMethodType.ImmediateDropAtStoreDeposit,
];

export const useGetClaimGroupTransportPlanQuery = ({
  claimGroupId,
  countryCode,
  zipCode,
  isForceFreeHandling = false,
}: {
  claimGroupId?: string;
  countryCode?: COUNTRY_CODES;
  zipCode?: string;
  isForceFreeHandling?: boolean;
}) => {
  const { data: transportPlans } = useQuery(
    [
      GET_CLAIM_GROUP_TRANSPORT_PLAN_QUERY_KEY,
      {
        claimGroupId,
        countryCode,
        zipCode,
        isForceFreeHandling,
      },
    ],
    async () => {
      if (!claimGroupId) {
        return [];
      }

      const response = await getClaimGroupTransportPlanQuery({
        claimGroupId,
        countryCode,
        zipCode,
      });

      if (response.failure) {
        logError(response.errors);

        return [];
      }

      return response.value.map((transportPlan) => {
        if (!isForceFreeHandling) {
          return transportPlan;
        }

        return {
          ...transportPlan,
          priceWithTax: 0,
          isDeductible: false,
        };
      });
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return {
    transportPlans,
  };
};
