import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { mediaQuery } from '@savgroup-front-common/core/src/grid/helpers';

export const $DeliveredSparePartActionButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 2rem;
  height: auto;

  &:active,
  &:focus,
  &:active:hover,
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }

  ${mediaQuery.xs`
    width: 100%;
    margin-top: 1.5rem;
  `}
`;

export const $DeliveredSparePartInfoModal = styled('div')`
  padding-left: 1rem;
  padding-top: 0.5rem;
  display: flex;
`;
