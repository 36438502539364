import { useQuery } from 'react-query';

import { HandlingSummary } from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';

export const UseGetHandlingByClaimGroup = 'useGetHandlingByClaimGroup';

export const useGetHandlingByClaimGroup = ({
  claimGroupId,
  suspense,
  onSuccess,
}: {
  claimGroupId?: string;
  suspense?: boolean;
  onSuccess?: (handling: HandlingSummary) => void;
}) => {
  const { data: handling, isLoading } = useQuery(
    [UseGetHandlingByClaimGroup, { claimGroupId }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      const response = await ClaimService.getHandlingByClaimGroup({
        claimGroupId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      staleTime: Infinity,
      suspense,
      onSuccess,
    },
  );

  return {
    handling,
    isLoading,
  };
};
