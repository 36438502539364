import { transparentize } from 'polished';
import { Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { getCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

export const $Footer = styled.footer<{
  $enableServicePortal?: boolean;
}>`
  width: 100%;
  padding: ${({ $enableServicePortal }) =>
    $enableServicePortal ? '0.5rem 0px 0.5rem 0px' : '0.5rem 24px 0.5rem 24px'};
  position: ${({ $enableServicePortal }) =>
    $enableServicePortal ? 'relative' : 'fixed'};
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 30;
  background-color: ${({ theme }) => {
    return getCustomComponentProperty({
      theme,
      property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      fallback: theme.colors.white,
    });
  }};
  border-top: ${({ theme, $enableServicePortal }) => {
    if ($enableServicePortal) {
      return 'unset';
    }

    return `${theme.borders.thickness} solid ${transparentize(
      0.75,
      theme.colors.default,
    )}`;
  }};
  margin-top: 3rem;
  box-shadow: ${({ $enableServicePortal }) =>
    $enableServicePortal ? 'unset' : '0 -2px 10px rgba(104, 154, 186, 0.1)'};
  @media ${media.maxWidth.sm} {
    margin-top: 1rem;
    height: unset;
  }
`;

export const $RowFooter = styled(Row)<{
  $reverseChildren: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: unset;
  margin-left: unset;
  flex-direction: ${({ $reverseChildren }) =>
    $reverseChildren ? 'row-reverse' : 'row'};
`;
