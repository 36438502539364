import styled from 'styled-components';

import { ButtonLink } from '../../../button';

export const $ComplexSwitchContainer = styled.div`
  display: flex;
`;

interface $ComplexSwitchButtonProps {
  $isActive: boolean;
}

export const $ComplexSwitchRightButton = styled(
  ButtonLink,
)<$ComplexSwitchButtonProps>`
  font-size: ${({ theme }) => theme.fonts.size.small};

  border: 1px solid
    ${({ theme, $isActive }) => {
      if ($isActive) {
        return theme.colors.primary;
      }

      return theme.colors.disabled;
    }};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};

  box-shadow: ${({ $isActive }) =>
    $isActive ? '0px 0px 8px 0px rgba(0, 0, 0, 0.1)' : null};

  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  }
  &&&:focus {
    box-shadow: none;

    color: ${({ theme }) => theme.colors.mainTextColor};
    background-color: ${({ theme }) => theme.colors.white};

    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &&&:hover {
    box-shadow: ${({ $isActive }) =>
      $isActive ? '0px 0px 8px 0px rgba(0, 0, 0, 0.1)' : null};

    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.mainTextColor : theme.colors.mainTextColor};
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.white : theme.colors.default};

    svg {
      color: ${({ theme, $isActive }) =>
        $isActive ? theme.colors.primary : theme.colors.mainTextColor};
    }
  }
`;

export const $ComplexSwitchLeftButton = styled(
  ButtonLink,
)<$ComplexSwitchButtonProps>`
  font-size: ${({ theme }) => theme.fonts.size.small};

  border: 1px solid
    ${({ theme, $isActive }) => {
      if ($isActive) {
        return theme.colors.primary;
      }

      return theme.colors.disabled;
    }};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};

  box-shadow: ${({ $isActive }) =>
    $isActive ? '0px 0px 8px 0px rgba(0, 0, 0, 0.1)' : null};

  svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.paragraphTextColor};
  }
  &&&:focus {
    box-shadow: none;

    color: ${({ theme }) => theme.colors.mainTextColor};
    background-color: ${({ theme }) => theme.colors.white};

    svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &&&:hover {
    box-shadow: ${({ $isActive }) =>
      $isActive ? '0px 0px 8px 0px rgba(0, 0, 0, 0.1)' : null};

    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.mainTextColor : theme.colors.mainTextColor};

    svg {
      color: ${({ theme, $isActive }) =>
        $isActive ? theme.colors.primary : theme.colors.mainTextColor};
    }
  }
`;
