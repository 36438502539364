import React, { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import { useGetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';

import { PageFooterContainer } from '../../../../../app/NewLayout/NewStepLayout';
import { NewStepLayoutProfessional } from '../../../../../app/NewLayout/NewStepLayout/NewStepLayoutProfessional';
import { ResumeCard } from '../../../components/ResumeCard';
import { IrshStepValues } from '../../IrshPages.types';
import useClaimGroupSolutionPage from '../ClaimGroupSolutionPage.hooks';
import {
  $Heading,
  $HeadingIcon,
  $LeftColumn,
  $SolutionItemWrapper,
  $SolutionResultWrapper,
  $SolutionWrapper,
} from '../ClaimGroupSolutionPage.styles';
import messages from '../messages';
import { SolutionItem } from '../SolutionItem';

interface ClaimGroupSolutionProps {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
  onPreviousStep: RoutedStepProps<IrshStepValues>['onPreviousStep'];
}

export const ClaimGroupSolution: FC<ClaimGroupSolutionProps> = ({
  onNextStep,
  onPreviousStep,
}) => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const [openResumeModal, setOpenResumeModal] = useState(false);

  const { onSubmit, formContext } = useClaimGroupSolutionPage({
    onNextStep,
  });
  const { solutions, isSolutionsLoading } = useGetSolutionsByClaim({
    claimGroupId,
  });

  const { claimGroupConfirmation, isLoading } = useGetClaimGroupConfirmation({
    claimGroupId,
  });

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  const handlePreviousStep = () => {
    if (claimGroupConfirmation?.orderReference) {
      onPreviousStep({
        newValues: {
          orderReference: claimGroupConfirmation.orderReference,
        },
      });
    }
  };

  if (isLoading || isSolutionsLoading) {
    return <BaseLoader />;
  }

  return (
    <>
      <FormProvider {...formContext}>
        <form
          onSubmit={(event) => {
            event.preventDefault();

            return undefined;
          }}
        >
          <NewStepLayoutProfessional
            stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_SOLUTION_PAGE}
            customSteps={PROFESSIONAL_STEP_PROGRESS}
          >
            <$SolutionWrapper>
              <$LeftColumn>
                <$Heading>
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.LIGHT_BULB_ON_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.solutionTitle}
                  />
                </$Heading>
                <$SolutionItemWrapper>
                  {solutions?.map((solution) => (
                    <SolutionItem
                      key={solution.key}
                      solution={solution}
                      onClick={async () => {
                        await onSubmit({
                          chooseSolution: solution.solutionTypeId,
                        });
                      }}
                    />
                  ))}
                </$SolutionItemWrapper>
              </$LeftColumn>

              <$SolutionResultWrapper>
                <ResumeCard />
              </$SolutionResultWrapper>
            </$SolutionWrapper>
          </NewStepLayoutProfessional>

          <PageFooterContainer reverseChildren={false}>
            <Button
              type={BUTTON_TYPES.BUTTON}
              secondary
              naked
              disabled={!claimGroupConfirmation?.orderReference}
              onClick={() => {
                handlePreviousStep();
              }}
              icon={<ArrowNakedLeftIcon />}
              position={SUPPORTED_ICON_POSITIONS.LEFT}
              dataTestId="previousButton"
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.previousButton}
              />
            </Button>
          </PageFooterContainer>
        </form>
      </FormProvider>

      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard />
      </Modal>
    </>
  );
};
