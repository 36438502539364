import React from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import {
  CURRENCIES,
  HANDLING_MODES,
  HANDLING_UNIQUE_NAME,
  media,
} from '@savgroup-front-common/constants';
import { HandlingSummary } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';
import {
  SimpleItemCard,
  SimpleItemCardMobile,
} from 'myaccount/view/atoms/SimpleItemCard';

import { getHandlingData } from '../../../helpers';
import { getHandlingCardLabel } from '../../newDesignPages/irshPages/components/AvailableHandlingMethods/helpers/getHandlingCardLabel';

import messages from './messages';

interface ResumeDepositProps {
  claimGroupConfirmation?: ClaimGroupConfirmation;
  handlingStatus?: HANDLING_MODES;
  handlingSummary?: HandlingSummary;
}

const ResumeDeposit: React.FC<ResumeDepositProps> = ({
  claimGroupConfirmation,
  handlingStatus,
  handlingSummary,
}) => {
  const isTransportPlanFeatureEnabled = !!handlingSummary;

  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();

  const addressName = `${
    claimGroupConfirmation?.depositAddress?.firstname || ''
  } ${claimGroupConfirmation?.depositAddress?.lastname || ''}`;
  const address = claimGroupConfirmation?.depositAddress?.address;
  const postalCode = claimGroupConfirmation?.depositAddress?.postalCode;
  const city = claimGroupConfirmation?.depositAddress?.city;
  const addressFormated = `${address} ${postalCode} ${city}`;

  const { label, description } = getHandlingData({
    carrierUniqueName:
      claimGroupConfirmation?.carrierDepositTransportMethodUniqueName as HANDLING_UNIQUE_NAME,
  });
  const { label: newLabel, description: newDescription } = getHandlingCardLabel(
    {
      transportMethodType: handlingSummary?.deposit?.transportPlanMethodType,
    },
  );
  const deposit = handlingSummary?.deposit;
  const prefixTitle =
    handlingStatus === HANDLING_MODES.HOME
      ? messages.depositTitleIsHome
      : messages.depositTitleIsDeposit;

  const depositAddress = deposit?.address ?? deposit?.pickupPointAddress;
  const depositAddressName = deposit?.pickupPointFullName
    ? deposit?.pickupPointFullName
    : `${deposit?.address?.firstname || ''} ${
        deposit?.address?.lastname || ''
      }`;

  if (isTransportPlanFeatureEnabled && deposit) {
    return isMobileView ? (
      <SimpleItemCardMobile
        icon={ICONS_TYPE.DEPOSIT_ICON}
        iconColor={theme.colors.primary}
        prefixTitle={prefixTitle}
        title={handlingSummary ? newLabel : label}
        didactic={newDescription}
        address={{
          addressName: depositAddressName,
          addressStreet: `${depositAddress?.address} ${depositAddress?.postalCode} ${depositAddress?.city}`,
        }}
        price={{
          priceValue: deposit.transportPlanCostWithTax?.amount,
          priceCurrencyCode: deposit.transportPlanCostWithTax?.currency,
        }}
        isDeductibleFromRefund={deposit.isDeductible}
      />
    ) : (
      <SimpleItemCard
        icon={ICONS_TYPE.DEPOSIT_ICON}
        iconColor={theme.colors.primary}
        prefixTitle={prefixTitle}
        title={newLabel}
        didactic={newDescription}
        address={{
          addressName: depositAddressName,
          addressStreet: `${depositAddress?.address} ${depositAddress?.postalCode} ${depositAddress?.city}`,
        }}
        price={{
          priceValue: deposit.transportPlanCostWithTax?.amount,
          priceCurrencyCode: deposit.transportPlanCostWithTax?.currency,
        }}
        isDeductibleFromRefund={deposit.isDeductible}
      />
    );
  }

  return isMobileView ? (
    <SimpleItemCardMobile
      icon={ICONS_TYPE.DEPOSIT_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={prefixTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDepositPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDepositPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  ) : (
    <SimpleItemCard
      icon={ICONS_TYPE.DEPOSIT_ICON}
      iconColor={theme.colors.primary}
      prefixTitle={prefixTitle}
      title={label}
      didactic={description}
      address={{
        addressName,
        addressStreet: addressFormated,
      }}
      price={{
        priceValue: claimGroupConfirmation?.carrierDepositPriceWithTax,
        priceCurrencyCode:
          claimGroupConfirmation?.carrierDepositPriceWithTaxCurrencyCode as CURRENCIES,
      }}
    />
  );
};

export default ResumeDeposit;
