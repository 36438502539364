import React, { FC, useState } from 'react';

import { TransportPlan } from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { StrictAutocompleteOption } from '@savgroup-front-common/types';

import { HandlingValues, PickupPointOption } from '../../../../IrshPages.types';

import PickupPointSelector from './PickupPointSelector';
import { usePickupPointOptions } from './SelectPickupPointDrawerContent.hooks';

interface SelectPickupPointDrawerContentProps {
  onSetPickupPoint: ({
    pickupPoint,
  }: {
    pickupPoint: PickupPointOption;
  }) => void;
  availableMethod: StrictAutocompleteOption<TransportPlan[]>;
  defaultSearchAddress?: {
    address?: string;
    countryCode?: string;
  };
  selectedPickupPoint?: PickupPointOption;
  onClose: () => void;
  handlingValues: HandlingValues;
}

export const SelectPickupPointDrawerContent: FC<
  SelectPickupPointDrawerContentProps
> = ({
  onSetPickupPoint,
  availableMethod,
  defaultSearchAddress,
  selectedPickupPoint,
  onClose,
  handlingValues,
}) => {
  const [searchAddress, setSearchAddress] = useState<
    | {
        address?: string;
        countryCode?: string;
      }
    | undefined
  >(defaultSearchAddress);
  const { pickupPoints, isLoading: isPickupPointsLoading } =
    usePickupPointOptions({
      searchAddress,
      availableMethod,
    });

  return (
    <PickupPointSelector
      maxDistance={200}
      pickupPoints={pickupPoints ?? []}
      selectedPickupPoint={selectedPickupPoint}
      onSelectPickupPoint={(pickupPoint: PickupPointOption) =>
        onSetPickupPoint({
          pickupPoint,
        })
      }
      searchAddress={searchAddress}
      onAddressSelected={(addressSelected) => setSearchAddress(addressSelected)}
      onClose={onClose}
      pickupPointsIsLoading={isPickupPointsLoading}
      handlingValues={handlingValues}
    />
  );
};
