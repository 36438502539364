import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  Ref,
  useRef,
} from 'react';
import { useDropzone } from 'react-dropzone';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import {
  HTMLFileInputElement,
  MessageType,
} from '@savgroup-front-common/types';

import {
  safeFormattedIntlString,
  SafeFormattedMessage,
  SafeFormattedMessageWithoutSpread,
} from '../../../formatters';
import { useCombinedRefs } from '../../../hooks';
import { QrCodeIcon } from '../../../protons/icons/QrCode.icon';
import { Button } from '../../button';
import { FieldMessage } from '../common';
import { getFinalFieldState } from '../common/helpers/getFinalFieldState';
import { FieldMessages } from '../common/helpers/getFinalFieldState.types';
import { Label } from '../common/Label/Label';

import AttachmentItem from './AttachmentItem';
import DropIcon from './components/DropIcon';
import messages from './messages';
import {
  $Didactics,
  $Dropzone,
  $FileInput,
  $FileInputDropZoneContainer,
  $FileInputLabelContainer,
  $FileInputWrapper,
} from './NewDesignFileUploadHookForm.styles';

interface NewDesignFileUploadHookFormProps {
  label?: MessageType | string;
  didactics?: MessageType;
  dropZoneLabel?: MessageType;
  postLabel?: ReactNode;
  isRequired?: boolean;
  dropzone?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
  allowedMimeTypes?: string[];
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isLoading?: boolean;
  file?: { value: File; progress?: number };
  hollow?: boolean;
  onRemove?: (file: { value: File; progress?: number }) => void;
  name: string;
  dataTestId?: string;
  onSelect: (files?: File[]) => void;
  isLiveUpload?: boolean;
  isFullWidth?: boolean;
  onImportByQrCodeClick?: () => void;
}
interface FileUploadHookFormPropsRef extends NewDesignFileUploadHookFormProps {
  forwardedRef?: Ref<HTMLInputElement> | (() => void);
}

const NewDesignFileUploadHookForm: FunctionComponent<
  React.PropsWithChildren<FileUploadHookFormPropsRef>
> = ({
  label,
  didactics,
  postLabel = null,
  name,
  isRequired = false,
  isError = false,
  isWarning = false,
  isSuccess = false,
  disabled = false,
  isDisabled = false,
  allowedMimeTypes = [],
  file,
  forwardedRef = null,
  errors = {},
  warnings = {},
  successes = {},
  onSelect,
  onRemove = undefined,
  isLoading = false,
  isLiveUpload = true,
  dropZoneLabel,
  dataTestId,
  onImportByQrCodeClick,
}) => {
  const transitionDisabled = disabled || isDisabled;
  const [status, message] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const combinedRefs = useCombinedRefs(fileRef, forwardedRef);

  const handleClickInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fileRef.current) {
      fileRef.current.click();
    }
    event.preventDefault();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      return onSelect(acceptedFiles);
    },
  });

  return (
    <$FileInputWrapper>
      <$FileInputLabelContainer>
        {label && (
          <Label
            isRequired={isRequired}
            htmlFor={name}
            postLabel={postLabel}
            status={status}
          >
            {SafeFormattedMessage(label)}
          </Label>
        )}
        {didactics && (
          <$Didactics>{SafeFormattedMessage(didactics)}</$Didactics>
        )}
        {file && (
          <AttachmentItem
            file={file}
            onRemove={onRemove}
            name={name}
            isLiveUpload={isLiveUpload}
            errors={errors}
          />
        )}
        {!file && (
          <FieldMessage
            message={message}
            status={status}
            dataTestId={dataTestId}
          />
        )}
      </$FileInputLabelContainer>
      <$FileInputDropZoneContainer>
        <$FileInput
          {...getInputProps()}
          id={name}
          name={name}
          ref={combinedRefs}
          disabled={transitionDisabled}
          type="file"
          accept={allowedMimeTypes.join(',')}
          onChange={async (e: ChangeEvent<HTMLFileInputElement>) => {
            onSelect(e.target.files ? Array.from(e.target.files) : undefined);
            e.target.value = '';
          }}
          data-testid={dataTestId}
        />
        <$Dropzone
          data-testid="dropzone"
          {...(getRootProps() as any)}
          onClick={handleClickInput}
          type={BUTTON_TYPES.BUTTON}
          $isDragActive={isDragActive}
          aria-label={safeFormattedIntlString(
            messages.dragAndDropFilesDocumentAriaLabel,
            {
              fieldLabel: label,
            },
          )}
        >
          <div>
            <DropIcon />
            <SafeFormattedMessageWithoutSpread
              message={dropZoneLabel || messages.dragAndDropFilesDocument}
            />
            <SafeFormattedMessageWithoutSpread
              message={messages.fileInputDocument}
            />
          </div>

          {onImportByQrCodeClick && (
            <Button
              disabled={transitionDisabled}
              primary
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                onImportByQrCodeClick();
              }}
              type={BUTTON_TYPES.BUTTON}
              icon={<QrCodeIcon />}
              isLoading={isLoading}
              dataTestId={`qrCodeButton_${dataTestId}`}
            />
          )}
        </$Dropzone>
      </$FileInputDropZoneContainer>
    </$FileInputWrapper>
  );
};

export default React.forwardRef<
  HTMLInputElement,
  NewDesignFileUploadHookFormProps
>((props, ref) => (
  <NewDesignFileUploadHookForm forwardedRef={ref} {...props} />
));
