import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  HANDLING_MODES,
  SUPPORTED_METHODS,
  WEEK_DAY,
} from '@savgroup-front-common/constants';
import {
  AddressInfoDto,
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.claim}claimGroups/${COMMON_QUERY_PARAMS.CLAIM_GROUP_ID}/handling/transportPlan`;

export enum HandlingProcedure {
  Transport = 'Transport',
  Destruction = 'Destruction',
}

interface SetClaimGroupHandlingTransportPlanCommandArgs {
  claimGroupId: string;
  handlingMode: HANDLING_MODES;
  transportPlanHandlingProcedure?: HandlingProcedure;
  pickupPointId?: string;
  pickupPointFullName?: string;
  pickupPointAddress?: AddressInfoDto;
  pickupPointOpeningHours?: ReverseCommonOpeningHours[];
  transportPlanId?: string;
  address?: AddressInfoDto;
  forceFreeHandlingPrice?: boolean;
}

interface Hours {
  start?: string;
  end?: string;
}

interface ReverseCommonOpeningHours {
  day: WEEK_DAY;
  hours?: Hours[];
  underCondition: boolean;
}

export const setClaimGroupHandlingTransportPlanCommand = async ({
  claimGroupId,
  transportPlanId,
  transportPlanHandlingProcedure,
  pickupPointOpeningHours,
  pickupPointFullName,
  address,
  handlingMode,
  pickupPointAddress,
  pickupPointId,
  forceFreeHandlingPrice,
}: SetClaimGroupHandlingTransportPlanCommandArgs): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.CLAIM_GROUP_ID,
      claimGroupId,
    );

    return await apiCall<BaseBackResponse>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        transportPlanId,
        transportPlanHandlingProcedure,
        pickupPointOpeningHours,
        pickupPointFullName,
        address,
        handlingMode,
        pickupPointAddress,
        pickupPointId,
        claimGroupId,
        forceFreeHandlingPrice,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
