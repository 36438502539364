import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { generatePath } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import {
  BUTTON_TYPES,
  FEATURE_FLAGS_FOR_SELLER,
  media,
} from '@savgroup-front-common/constants';
import { useGetFeatureFlagsQuery } from '@savgroup-front-common/core/src/api/Authorization/useGetFeatureFlagsQuery';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { HandlingSummary } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import { FINAL_ROUTES } from '../../../app';
import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { ResumeDelivery } from '../../../components/ResumeDelivery';
import { ResumeDeposit } from '../../../components/ResumeDeposit';
import { ResumeSolution } from '../../../components/ResumeSolution';
import { IrshStepValues } from '../IrshPages.types';

import AgreementCheckbox from './AgreementCheckbox';
import messages from './messages';
import useClaimGroupConfirmationPage from './NewClaimGroupConfirmationPage.hooks';
import {
  $AgreementRow,
  $ConfirmationItemWrapper,
  $ConfirmationResultWrapper,
  $ConfirmationWrapper,
  $Grid,
  $HeadingIcon,
  $LeftColumn,
} from './NewClaimGroupConfirmationPage.styles';
import PaymentCard from './PaymentCard/PaymentCard';

export const NewClaimGroupConfirmationPage: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onNextStep, onPreviousStep }) => {
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const handling = values?.handling as HandlingSummary;

  const hasDeposit = handling.hasDeposit;
  const hasDelivery = handling.hasDelivery;

  const isMobileView = useMedia(media.maxWidth.xs);
  const {
    claimGroupConfirmation,
    claimGroupId,
    formContext,
    onSubmit,
    agreementUrl,
    isLoading,
    paymentRequired,
    sellerId,
    isLoadingSubmit,
    isCheckInvoiceLoading,
  } = useClaimGroupConfirmationPage({
    onNextStep,
  });

  const {
    control,
    formState: { isValid, errors },
    watch,
  } = formContext;

  const cgv = watch('cgv');
  const shouldDisplayPaymentCard =
    cgv && paymentRequired && sellerId && !isCheckInvoiceLoading;

  const isTransportPlanFeatureEnabled = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.TRANSPORT_PLAN,
    sellerId,
  });

  if (isLoading || !claimGroupId) {
    return <BaseLoader />;
  }

  return (
    <>
      <$Grid fluid>
        <FormProvider {...formContext}>
          <form onSubmit={onSubmit}>
            <NewStepLayout
              stepName={STEP_CONFIG.CLAIM_GROUP_CONFIRMATION}
              withoutMarginBottom
            >
              <$ConfirmationWrapper>
                <$LeftColumn>
                  <ResumeSolution
                    claimGroupId={claimGroupId}
                    claimGroupConfirmation={claimGroupConfirmation}
                    customBuildUpdateSolutionUrl={generatePath(
                      FINAL_ROUTES.CLAIM_GROUP_SOLUTION,
                      {
                        claimGroupId,
                      },
                    )}
                  />
                  {hasDeposit && (
                    <ResumeDeposit
                      claimGroupConfirmation={claimGroupConfirmation}
                      handlingSummary={
                        isTransportPlanFeatureEnabled ? handling : undefined
                      }
                    />
                  )}
                  {hasDelivery && (
                    <ResumeDelivery
                      claimGroupConfirmation={claimGroupConfirmation}
                      handlingSummary={
                        isTransportPlanFeatureEnabled ? handling : undefined
                      }
                    />
                  )}

                  <$ConfirmationItemWrapper>
                    <Heading level={3}>
                      <$HeadingIcon>
                        <MyAccountIcon icon={ICONS_TYPE.SEND_MAIL_ICON} />
                      </$HeadingIcon>
                      <SafeFormattedMessageWithoutSpread
                        message={messages.notificationsTitle}
                      />
                    </Heading>
                    <Row>
                      <Col sm={6} xs={12}>
                        <Controller
                          control={control}
                          name="email"
                          render={({ field }) => {
                            return (
                              <Input
                                name={field.name}
                                value={field.value}
                                label={messages.mail}
                                disableBrowserAutocomplete
                                errors={errors}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                      <Col sm={6} xs={12}>
                        <Controller
                          control={control}
                          name="phone"
                          render={({ field }) => {
                            return (
                              <Input
                                name={field.name}
                                value={field.value}
                                label={messages.phone}
                                disableBrowserAutocomplete
                                errors={errors}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                    </Row>
                    <$AgreementRow>
                      <Col sm={12} xs={12}>
                        <Controller
                          control={control}
                          name="cgv"
                          render={({ field }) => {
                            return (
                              <AgreementCheckbox
                                agreementHref={agreementUrl}
                                isChecked={field.value}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </Col>
                    </$AgreementRow>
                  </$ConfirmationItemWrapper>
                </$LeftColumn>

                <$ConfirmationResultWrapper>
                  {isMobileView && (
                    <ResumeCardButton
                      claimGroupConfirmation={claimGroupConfirmation}
                      hasPrice
                      hasDepositPrice
                      hasDeliveryPrice
                      isTransportPlanFeatureEnabled={
                        isTransportPlanFeatureEnabled
                      }
                    />
                  )}
                  {!isMobileView && (
                    <ResumeCard
                      claimGroupConfirmation={claimGroupConfirmation}
                      hasPrice
                      hasDepositPrice
                      hasDeliveryPrice
                      isTransportPlanFeatureEnabled={
                        isTransportPlanFeatureEnabled
                      }
                    />
                  )}
                </$ConfirmationResultWrapper>
              </$ConfirmationWrapper>
            </NewStepLayout>

            <PageFooterContainer reverseChildren={!paymentRequired}>
              {!paymentRequired && (
                <Button
                  primary
                  type={BUTTON_TYPES.BUTTON}
                  icon={<ArrowNakedRightIcon />}
                  position={SUPPORTED_ICON_POSITIONS.RIGHT}
                  disabled={!isValid}
                  isLoading={isLoadingSubmit}
                  onClick={onSubmit}
                  dataTestId="nextButton"
                  ariaLabel={messages.nextButton}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.nextButton}
                  />
                </Button>
              )}

              <Button
                type={BUTTON_TYPES.BUTTON}
                secondary
                naked
                onClick={() => {
                  onPreviousStep({});
                }}
                icon={<ArrowNakedLeftIcon />}
                position={SUPPORTED_ICON_POSITIONS.LEFT}
                dataTestId="previousButton"
                ariaLabel={messages.previousButton}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.previousButton}
                />
              </Button>
            </PageFooterContainer>
          </form>
        </FormProvider>

        {shouldDisplayPaymentCard && (
          <Row>
            <Col sm={8} xs={12}>
              <PaymentCard
                sellerId={sellerId}
                onSubmitSuccess={onSubmit}
                isFormCompleted={isValid}
              />
            </Col>
          </Row>
        )}
      </$Grid>
    </>
  );
};
