import round from 'lodash/round';
import { useFormContext } from 'react-hook-form';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { RecipientType } from '@savgroup-front-common/types';

import { ReverseMoneyHelper } from '../../../../../helpers/ReverseMoneyHelper';
import { useGetOwnerDeductibleAndAlreadyPaidAmount } from '../../../../../hooks/useGetOwnerDeductibleAndAlreadyPaidAmount';
import { useQuotationPendingCustomerValidationContext } from '../../QuotationPendingCustomerValidation.context';
import {
  QuotationPendingCustomerValidationForm,
  QuotationPendingCustomerValidationNewModel,
} from '../../QuotationPendingCustomerValidation.types';

interface UseCalculateQuotationTotalsArgs {
  quotation: QuotationPendingCustomerValidationNewModel;
  fileId: string;
}

const VAT_TO_PRE_VAT_RATIO = 1.2;

export const useCalculateQuotationPendingCustomerValidationTotals = ({
  quotation,
  fileId,
}: UseCalculateQuotationTotalsArgs) => {
  const formContext = useFormContext<QuotationPendingCustomerValidationForm>();
  const { alternativeSpareParts } =
    useQuotationPendingCustomerValidationContext();
  const { watch } = formContext;

  const watchedValues = watch();

  const { alreadyPaidAmountInPreviousQuotation, deductibleAmountFromClaim } =
    useGetOwnerDeductibleAndAlreadyPaidAmount({ fileId });

  const totalAdministrativeFees = (quotation.administrativeFees || []).reduce(
    (acc, administrativeFee) => {
      if (administrativeFee.recipient?.recipientType !== RecipientType.OWNER) {
        return acc;
      }

      const newAmount = administrativeFee.preTaxAmount.amount;
      const newAmountIncludingTaxes =
        administrativeFee.amountIncludedTax.amount;

      return {
        totalPreVat: acc.totalPreVat + newAmount,
        totalVat: acc.totalVat + newAmountIncludingTaxes,
      };
    },
    { totalVat: 0, totalPreVat: 0 },
  );
  const totalService = (quotation.services || []).reduce(
    (acc, service) => {
      if (service.recipient?.recipientType !== RecipientType.OWNER) {
        return acc;
      }

      const newAmount = service.preTaxAmount.amount;
      const newAmountIncludingTaxes = service.amountIncludedTax.amount;

      return {
        totalPreVat: acc.totalPreVat + newAmount,
        totalVat: acc.totalVat + newAmountIncludingTaxes,
      };
    },
    { totalVat: 0, totalPreVat: 0 },
  );
  const totalUnknownService = (quotation.unknownServices || []).reduce(
    (acc, unknownService) => {
      if (unknownService.recipient?.recipientType !== RecipientType.OWNER) {
        return acc;
      }

      const newAmount = unknownService.preTaxAmount.amount;
      const newAmountIncludingTaxes = unknownService.amountIncludedTax.amount;

      return {
        totalPreVat: acc.totalPreVat + newAmount,
        totalVat: acc.totalVat + newAmountIncludingTaxes,
      };
    },
    { totalVat: 0, totalPreVat: 0 },
  );
  const totalUnknownSparePart = (quotation.unknownSpareParts || []).reduce(
    (acc, unknownSparePart) => {
      if (unknownSparePart.recipient?.recipientType !== RecipientType.OWNER) {
        return acc;
      }

      const newAmount = unknownSparePart.preTaxAmount.amount;
      const newAmountIncludingTaxes = unknownSparePart.amountIncludedTax.amount;

      return {
        totalPreVat: acc.totalPreVat + newAmount,
        totalVat: acc.totalVat + newAmountIncludingTaxes,
      };
    },
    { totalVat: 0, totalPreVat: 0 },
  );

  const totalSparePartLines = (quotation.sparePartLines || []).reduce(
    (acc, sparePartQuotationLine) => {
      if (sparePartQuotationLine.recipient?.data !== RecipientType.OWNER) {
        return acc;
      }

      const sparePartQuotationFormLine =
        watchedValues.sparePartLines?.[sparePartQuotationLine.id];

      if (
        !sparePartQuotationFormLine ||
        sparePartQuotationFormLine.isSelectedForRepair === false
      ) {
        return acc;
      }

      const concernedAlternativeSpareParts = Object.values(
        alternativeSpareParts[sparePartQuotationLine.sparePartId] || {},
      );

      if (!sparePartQuotationFormLine) {
        throw new Error('Invalid state: newCondition is not found.');
      }

      const sparePart = concernedAlternativeSpareParts.find((part) => {
        return (
          part.sparePartId === sparePartQuotationLine.sparePartId &&
          part.sparePartSupplierId ===
            sparePartQuotationFormLine.sparePartSupplierId &&
          part.condition === sparePartQuotationFormLine.condition
        );
      });

      if (!sparePart || !sparePartQuotationFormLine.condition) {
        return acc;
      }

      const amountPreVat = round(
        sparePart.recommendedSalePriceIncludingTaxes.amount /
          VAT_TO_PRE_VAT_RATIO,
        2,
      );

      return {
        totalPreVat: acc.totalPreVat + amountPreVat,
        totalVat:
          acc.totalVat + sparePart.recommendedSalePriceIncludingTaxes.amount,
      };
    },
    { totalVat: 0, totalPreVat: 0 },
  );
  const totalDiscount = (quotation.discounts || []).reduce(
    (acc, discount) => {
      const newAmount = discount.preTaxAmount.amount;
      const newAmountIncludingTaxes = discount.amountIncludedTax.amount;

      if (discount.recipient?.recipientType !== RecipientType.OWNER) {
        return acc;
      }

      return {
        totalPreVat: acc.totalPreVat + newAmount,
        totalVat: acc.totalVat + newAmountIncludingTaxes,
      };
    },
    { totalVat: 0, totalPreVat: 0 },
  );
  const totalAlreadyPaid = ReverseMoneyHelper.add(
    ReverseMoneyHelper.abs(alreadyPaidAmountInPreviousQuotation),
    ReverseMoneyHelper.abs(deductibleAmountFromClaim),
  );

  const bonus =
    quotation?.bonus && !quotation.isBonusRemoved
      ? quotation.bonus.amountIncludedTax
      : undefined;

  const subtotalPreVat =
    totalAdministrativeFees.totalPreVat +
    totalService.totalPreVat +
    totalUnknownService.totalPreVat +
    totalUnknownSparePart.totalPreVat +
    totalSparePartLines.totalPreVat;
  const subtotalVat =
    totalAdministrativeFees.totalVat +
    totalService.totalVat +
    totalUnknownService.totalVat +
    totalUnknownSparePart.totalVat +
    totalSparePartLines.totalVat;
  const totalAmountToBePaid = parseFloat(
    (
      subtotalVat -
      Math.abs(totalDiscount.totalVat) -
      totalAlreadyPaid.amount -
      Math.abs(bonus?.amount || 0)
    ).toFixed(2),
  );

  return {
    subtotalPreVat: { amount: subtotalPreVat, currency: CURRENCIES.EUR },
    subtotalVat: { amount: subtotalVat, currency: CURRENCIES.EUR },
    totalDiscountIncludedTax: quotation.totalDiscountIncludedTax,
    alreadyPaid: { amount: totalAlreadyPaid.amount, currency: CURRENCIES.EUR },
    totalAmountToBePaid: {
      amount: totalAmountToBePaid,
      currency: CURRENCIES.EUR,
    },
    bonus,
  };
};
