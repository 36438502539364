import React, { FC, PropsWithChildren, Suspense } from 'react';
import { useMedia, useWindowSize } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';

import { BaseLoader } from '../../molecules/BaseLoader';
import { CrossIcon } from '../../protons/icons';

import messages from './messages';
import { useSwipeableDrawer } from './SwipeableDrawer.hooks';
import { $Backdrop, $CloseButton, $Sheet } from './SwipeableDrawer.styles';

export enum DIRECTION {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum DRAWER_SIZE {
  X_SMALL = 240,
  TINY = 350,
  SMALL = 600,
  MEDIUM = 720,
  LARGE = 1120,
  FULL_SCREEN = 'FULL_SCREEN',
}

interface SwipeableDrawerProps {
  isOpen: boolean;
  hasBackdrop?: boolean;
  direction?: DIRECTION;
  size?: DRAWER_SIZE;
  onClose?: () => void;
  hasPadding?: boolean;
  hasScroll?: boolean;
  hasBackdropScroll?: boolean;
  dataTestId?: string;
  hasCloseButton?: boolean;
  hasCloseOnBackdrop?: boolean;
}

const SwipeableDrawer: FC<PropsWithChildren<SwipeableDrawerProps>> = ({
  children,
  isOpen,
  direction = DIRECTION.LEFT,
  size = DRAWER_SIZE.LARGE,
  hasBackdrop = true,
  onClose,
  hasPadding = true,
  hasScroll = true,
  hasBackdropScroll = true,
  dataTestId,
  hasCloseButton = true,
  hasCloseOnBackdrop = true,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const { height: windowHeight } = useWindowSize();

  const currentSize = isMobileView ? DRAWER_SIZE.FULL_SCREEN : size;
  const currentDirection = isMobileView ? DIRECTION.TOP : direction;

  const { display, height, y, x } = useSwipeableDrawer({
    isOpen,
    onClose,
    hasBackdropScroll,
    direction: currentDirection,
    size: currentSize,
  });

  let style = {
    display,
    right: 0,
    x,
  };

  if (currentDirection === DIRECTION.TOP) {
    style = { display, bottom: `calc(-100vh + ${height - 100}px)`, y } as any;
  }
  if (currentDirection === DIRECTION.RIGHT) {
    style = {
      display,
      left: 0,
      x,
    } as any;
  }

  return (
    <div data-testid={dataTestId}>
      {isOpen && hasBackdrop && (
        <$Backdrop
          onClick={hasCloseOnBackdrop ? onClose : undefined}
          $opacity={0.5}
        />
      )}
      {isOpen && (
        <$Sheet
          $isMobileView={isMobileView}
          $windowHeight={windowHeight}
          direction={currentDirection}
          size={currentSize}
          style={style}
          $hasPadding={hasPadding}
          $hasScroll={isMobileView ? true : hasScroll}
        >
          {isOpen && (
            <>
              {hasCloseButton && (
                <$CloseButton
                  type={BUTTON_TYPES.BUTTON}
                  onClick={onClose}
                  icon={<CrossIcon />}
                  dataTestId="modalClose"
                  primary
                  naked
                  small
                  ariaLabel={messages.closeDrawerButton}
                />
              )}
              <Suspense fallback={<BaseLoader />}>{children}</Suspense>
            </>
          )}
        </$Sheet>
      )}
    </div>
  );
};

export default SwipeableDrawer;
