import React, { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMedia } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import { AnimatedRenderer } from '@savgroup-front-common/core/src/atoms/AnimatedRenderer';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Didactic } from '@savgroup-front-common/core/src/atoms/Didactic';
import Form from '@savgroup-front-common/core/src/atoms/Form/Form/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { Product } from '@savgroup-front-common/types';
import {
  PROFESSIONAL_STEP_CONFIG,
  PROFESSIONAL_STEP_PROGRESS,
} from 'myaccount/view/app/NewLayout/ProfessionalRoutes.config';

import { PageFooterContainer } from '../../../../../app/NewLayout/NewStepLayout';
import { NewStepLayoutProfessional } from '../../../../../app/NewLayout/NewStepLayout/NewStepLayoutProfessional';
import { IrshStepValues } from '../../IrshPages.types';
import SearchModel from '../SearchModel/SearchModel';

import { BarcodeReader } from './BarcodeReader';
import { useAddProductToBusinessReturn } from './CartManager.hooks';
import {
  $CenteredText,
  $Container,
  $EmptyCartContainer,
  $OrderLines,
} from './CartManager.styles';
import { CartManagerForm } from './CartManager.types';
import { useCreateClaimGroupFromBusinessReturn } from './hooks/useCreateClaimGroupFromBusinessReturn';
import { useGetBusinessReturn } from './hooks/useGetBusinessReturn';
import { useSetProductToBusinessReturn } from './hooks/useSetProductToBusinessReturn';
import messages from './messages';
import { OrderLine } from './OrderLine';

interface CartManagerProps {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
  onPreviousStep: RoutedStepProps<IrshStepValues>['onPreviousStep'];
}

const CartManager: FC<CartManagerProps> = ({ onNextStep, onPreviousStep }) => {
  const isMobileView = useMedia(media.maxWidth.sm);

  const { order, isLoading, eligibility } = useGetBusinessReturn();

  const formContext = useForm<CartManagerForm>({
    defaultValues: {
      product: {},
    },
    shouldUnregister: true,
  });
  const {
    handleProductScan,
    handleAddProductBySearchValue,
    isAddProductLoading,
  } = useAddProductToBusinessReturn({ formContext });
  const { handleSetProduct, isSetProductLoading } =
    useSetProductToBusinessReturn({ formContext });

  const { isCreateClaimGroupLoading, handleCreateClaimGroup } =
    useCreateClaimGroupFromBusinessReturn({
      onNextStep,
    });

  const handlePreviousStep = useCallback(() => {
    onPreviousStep({
      newValues: {
        order,
      },
    });
  }, [onPreviousStep, order]);

  if (!eligibility || !order || isLoading) {
    return <BaseLoader />;
  }

  return (
    <Form<CartManagerForm>
      formContext={formContext}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <NewStepLayoutProfessional
        stepName={PROFESSIONAL_STEP_CONFIG.PROFESSIONAL_PRODUCTS_TO_RETURN_PAGE}
        customSteps={PROFESSIONAL_STEP_PROGRESS}
        minHeight
      >
        <$EmptyCartContainer>
          {!order?.products.length && (
            <AnimatedRenderer isOpen>
              <$CenteredText>
                <img
                  style={{ maxWidth: '224px' }}
                  src="/images/emptyOrder.svg"
                  alt=""
                />
                <Didactic withBottomMargin={false}>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.startByScanProductToReturn}
                  />
                </Didactic>
              </$CenteredText>
              <SearchModel
                handleAddProduct={handleAddProductBySearchValue}
                isAddProductLoading={isAddProductLoading}
              />
            </AnimatedRenderer>
          )}
        </$EmptyCartContainer>

        <$Container>
          {order?.products.length > 0 && (
            <Card withoutMarginBottom isFluid minimal>
              <SearchModel
                handleAddProduct={handleAddProductBySearchValue}
                isAddProductLoading={isAddProductLoading}
              />
            </Card>
          )}
        </$Container>

        <$OrderLines>
          {order?.products.map((product: Product, index) => {
            return (
              <OrderLine
                key={product.orderLineReference}
                product={product}
                eligibility={eligibility}
                onUpdateQuantity={handleSetProduct}
                index={index}
              />
            );
          })}
        </$OrderLines>
        {isMobileView && (
          <BarcodeReader
            onProductScan={handleProductScan}
            isAddProductLoading={isAddProductLoading}
          />
        )}
      </NewStepLayoutProfessional>

      <PageFooterContainer>
        <Button
          primary
          type={BUTTON_TYPES.BUTTON}
          icon={<ArrowNakedRightIcon />}
          position={SUPPORTED_ICON_POSITIONS.RIGHT}
          isLoading={isSetProductLoading || isCreateClaimGroupLoading}
          onClick={() => handleCreateClaimGroup()}
          dataTestId="nextButton"
        >
          <SafeFormattedMessageWithoutSpread message={messages.nextButton} />
        </Button>

        <Button
          type={BUTTON_TYPES.BUTTON}
          secondary
          naked
          onClick={() => {
            handlePreviousStep();
          }}
          icon={<ArrowNakedLeftIcon />}
          position={SUPPORTED_ICON_POSITIONS.LEFT}
          dataTestId="previousButton"
        >
          <SafeFormattedMessageWithoutSpread
            message={messages.previousButton}
          />
        </Button>
      </PageFooterContainer>
    </Form>
  );
};

export default CartManager;
