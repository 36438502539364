import { defineMessages } from 'react-intl';

import { LANGUAGES } from '@savgroup-front-common/constants';

export default defineMessages({
  [LANGUAGES.EN]: {
    id: 'components.myaccount.layout.header.languageChooser.en',
    defaultMessage: 'English',
  },
  [LANGUAGES.FR]: {
    id: 'components.myaccount.layout.header.languageChooser.fr',
    defaultMessage: 'Français',
  },
  [LANGUAGES.ES]: {
    id: 'components.myaccount.layout.header.languageChooser.es',
    defaultMessage: 'Español',
  },
  [LANGUAGES.DE]: {
    id: 'components.myaccount.layout.header.languageChooser.de',
    defaultMessage: 'Deutsch',
  },
  [LANGUAGES.IT]: {
    id: 'components.myaccount.layout.header.languageChooser.it',
    defaultMessage: 'Italiano',
  },
  [LANGUAGES.PT]: {
    id: 'components.myaccount.layout.header.languageChooser.pt',
    defaultMessage: 'Português',
  },
  [LANGUAGES.NL]: {
    id: 'components.myaccount.layout.header.languageChooser.nl',
    defaultMessage: 'Nederlands',
  },
  title: {
    id: 'components.myaccount.layout.header.languageChooser.title',
    defaultMessage: 'Change language',
  },
  chooseLanguageAriaLabel: {
    id: 'ariaLabel.chooseLanguageAriaLabel',
    defaultMessage: 'Choose language',
  },
});
