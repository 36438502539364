import { defineMessages } from 'react-intl';

export default defineMessages({
  paginationPerPage: {
    id: 'view.ExpendableList.paginationPerPage',
    defaultMessage: 'Elements per page',
  },
  paginationPerPageSingular: {
    id: 'view.ExpendableList.paginationPerPageSingular',
    defaultMessage: 'Element per page',
  },
  totalPage: {
    id: 'view.ExpendableList.totalPage',
    defaultMessage: 'on {totalPage}',
  },
  choosePage: {
    id: 'atoms.pagination.placeholder.choosePage',
    defaultMessage: 'Choose page',
  },
  goToFirstPage: {
    id: 'ariaLabel.goToFirstPage',
    defaultMessage: 'Go to first page',
  },
  goToLastPage: {
    id: 'ariaLabel.goToLastPage',
    defaultMessage: 'Go to last page',
  },
  goToPreviousPage: {
    id: 'ariaLabel.goToPreviousPage',
    defaultMessage: 'Go to previous page',
  },
  goToNextPage: {
    id: 'ariaLabel.goToNextPage',
    defaultMessage: 'Go to next page',
  },
});
