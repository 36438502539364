import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $Button = styled(Button)`
  &&,
  &&:visited,
  &&:active,
  &&:focus {
    background: transparent;
    padding: 0;
    height: auto;
    min-height: auto;
    justify-content: flex-start;
    text-align: left;
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;

    &&:hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
