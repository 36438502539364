import styled from 'styled-components';

export const $Price = styled('span')`
  color: ${({ theme }) => theme.colors.danger};
  margin-left: 0.4em;
  span {
    color: inherit !important;
  }
`;

export const $Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
`;
