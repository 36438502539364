import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { computeAdditionalInformationValue } from '@savgroup-front-common/core/src/helpers/AdditionalInformations/computeAdditionalInformationValue';
import { computeInternalId } from '@savgroup-front-common/core/src/helpers/AdditionalInformations/computeInternalId';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { StrictAutocompleteOption } from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { GetSolutionsByClaim } from 'myaccount/view/app/hooks/useGetSolutionsByClaim';

import { ReasonSummary } from '../../../../api/Claim/getReasonsByClaimGroup';
import useGetClaimReasons from '../../../app/hooks/useGetClaimReasons';
import { findNeededAdditionalInformationById } from '../helpers/findNeededAdditionalInformationById';
import useGetClaimGroupSummary, {
  USE_GET_CLAIMS_GROUP_SUMMARY,
} from '../hooks/useGetClaimGroupSummary';
import { USE_GET_SOLUTIONS_BY_CLAIM_GROUP_ID } from '../hooks/useGetSolutionsByClaim';
import { IrshStepValues } from '../IrshPages.types';

import {
  adaptReason,
  prepareReasonAdditionalInformation,
} from './helpers/reason.adapters';
import claimGroupReasonSchema from './NewClaimGroupReasonPage.schema';
import { ClaimGroupReasonValues } from './NewClaimGroupReasonPage.types';

const SET_REASON_FOR_CLAIM = 'setReasonForClaim';
const SET_ADDITIONNAL_INFORMATION = 'setAdditionalInformation';

interface Args {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupReason = ({ onNextStep }: Args) => {
  const queryClient = useQueryClient();
  const { removeAllNotifications, pushErrors } = useToasts();

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const hasRefusedDiagnosticTree =
    values?.diagnosticTree?.hasRefusedDiagnosticTree ?? false;
  const isDiagnosticIsAlreadyMade =
    values?.diagnosticTree?.isDiagnosticIsAlreadyMade ||
    claims?.at(0)?.isDiagnosticTreeDone
      ? true
      : false;
  const reason = values?.reason;

  const isMultiProduct = claims ? claims.length > 1 : undefined;

  const firstClaim = claims?.at(0);

  const claimIds = claims?.map((claim) => claim.claimId) || [];

  const defaultAdditionalInformations = claims?.reduce((acc, claim) => {
    return claim.additionalClaimInformation.reduce(
      (acc2, additionalInformation) => {
        const neededInformation = findNeededAdditionalInformationById({
          id: additionalInformation.additionalInformationId,
          neededAdditionalInformations: reason?.neededInformation,
        });

        if (!neededInformation) {
          return acc2;
        }

        const internalId = computeInternalId({
          id: additionalInformation.additionalInformationId,
          claimId: claim.claimId,
          uniquenessByProduct: neededInformation.uniquenessByProduct,
        });

        return {
          ...acc2,
          [internalId]: computeAdditionalInformationValue({
            value: additionalInformation,
            type: neededInformation.type,
          }),
        };
      },
      acc,
    );
  }, {});

  const formContext = useForm<ClaimGroupReasonValues>({
    resolver: yupResolver(
      claimGroupReasonSchema({
        reasonSelected: values?.reason,
        isMultiProduct,
      }),
    ),
    context: {
      reasonSelected: values?.reason,
      isMultiProduct,
    },
    defaultValues: {
      reason: reason
        ? { value: reason.id, data: reason, label: reason.name }
        : undefined,
      reasonComment: firstClaim?.reasonComment,
      reasonAdditionalInformation: defaultAdditionalInformations as any,
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
    reValidateMode: REVALIDATE_MODES.ON_CHANGE,
    shouldUnregister: true,
  });

  const { handleSubmit, watch } = formContext;

  const watchedReasonSelected = watch('reason');
  const reasonSelected = watchedReasonSelected?.data;

  const reasonAdapted =
    reasonSelected && adaptReason({ reasonSelected, claimIds });

  const {
    mutateAsync: handleReasonForClaim,
    isLoading: isLoadingSubmitReasonForClaim,
  } = useMutation(
    [SET_REASON_FOR_CLAIM],
    async ({
      claimId,
      reasonId,
      reasonComment,
    }: {
      claimId: string;
      reasonId: string;
      reasonComment?: string;
    }) => {
      removeAllNotifications();

      const responseReason = await ClaimService.setReasonForClaim({
        claimId,
        reasonId,
        reasonComment,
      });

      if (responseReason.failure) {
        pushErrors(responseReason.errors);

        return undefined;
      }

      return responseReason;
    },
  );

  const {
    mutateAsync: handleAdditionalInformation,
    isLoading: isLoadingAdditionalInformation,
  } = useMutation(
    [SET_ADDITIONNAL_INFORMATION],
    async ({
      claimId,
      additionalClaimInformationValues,
    }: {
      claimId: string;
      additionalClaimInformationValues: { id: string; value: string }[];
    }) => {
      removeAllNotifications();
      const responseAdditionalInformation =
        await ClaimService.setClaimAdditionalInformation({
          claimId,
          additionalClaimInformationValues,
        });

      if (responseAdditionalInformation.failure) {
        pushErrors(responseAdditionalInformation.errors);

        return undefined;
      }

      return responseAdditionalInformation;
    },
  );

  const onSubmit = handleSubmit(async (data) => {
    if (!claims) {
      throw new Error('No claims found');
    }

    const reasonSelected = data.reason.data;

    try {
      const responses = (
        await Promise.all(
          claims.map(async (claim) => {
            const responseReason = await handleReasonForClaim({
              claimId: claim.claimId,
              reasonId: data?.reason?.value,
              reasonComment: data?.reasonComment,
            });

            if (!responseReason) {
              return undefined;
            }

            const prepareReasonAdditionalInformationFormated =
              prepareReasonAdditionalInformation(
                claim.claimId,
                data?.reasonAdditionalInformation as any,
                values?.reason?.neededInformation as any,
              );

            const responseAdditionalInformation =
              await handleAdditionalInformation({
                claimId: claim.claimId,
                additionalClaimInformationValues:
                  prepareReasonAdditionalInformationFormated as any,
              });

            if (!responseAdditionalInformation) {
              return undefined;
            }

            return [responseReason, responseAdditionalInformation];
          }),
        )
      ).flat();

      if (responses.some((response) => response?.failure)) {
        return undefined;
      }

      await queryClient.invalidateQueries([GetSolutionsByClaim]);
      await queryClient.invalidateQueries([
        USE_GET_SOLUTIONS_BY_CLAIM_GROUP_ID,
      ]);

      await queryClient.invalidateQueries([
        USE_GET_CLAIMS_GROUP_SUMMARY,
        { claimGroupId },
      ]);

      onNextStep({
        newValues: {
          reason: reasonSelected,
        },
      });

      return undefined;
    } catch (err: any) {
      pushErrors(err);

      return undefined;
    }
  });

  const isDiagnosticTreeAvailable =
    reasonAdapted?.isDiagnosticTreeAvailable &&
    !hasRefusedDiagnosticTree &&
    !isDiagnosticIsAlreadyMade;

  const { reasons = [], reasonsIsLoading } = useGetClaimReasons({
    claimGroupId,
  });

  const reasonOptions = reasons.map((reason) => {
    return {
      label: reason.name,
      value: reason.id,
      data: reason,
    } as StrictAutocompleteOption<ReasonSummary>;
  });

  return {
    reasonOptions,
    reasonAdapted,
    isMultiProduct,
    isLoading: reasonsIsLoading,
    formContext,
    onSubmit,
    isLoadingSubmit:
      isLoadingSubmitReasonForClaim || isLoadingAdditionalInformation,
    isDiagnosticTreeAvailable,
  };
};

export default useClaimGroupReason;
