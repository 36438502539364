import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  FEATURE_FLAGS_FOR_SELLER,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import prepareResponseFailure from '../../helpers/prepareResponseFailure';
import { buildUrl } from '../../helpers/url';
import apiCall from '../../services/apiCall';

const ENDPOINT = `${APIConfiguration.authorization}features`;

interface GetFeatureFlagsQueryResponseValue {
  key: FEATURE_FLAGS_FOR_SELLER;
  value: boolean;
}

const getFeatureFlagsQuery = async ({
  sellerId,
}: {
  sellerId?: string;
}): Promise<
  BackResponse<GetFeatureFlagsQueryResponseValue[]> | BackResponseFailure
> => {
  try {
    return await apiCall<BackResponse<GetFeatureFlagsQueryResponseValue[]>>(
      buildUrl(ENDPOINT, { sellerId }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getFeatureFlagsQuery;
