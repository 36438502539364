import { TransportMethodType } from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import { MessageType } from '@savgroup-front-common/types';

import messages from './messages';

export const getHandlingCardLabel = ({
  transportMethodType,
}: {
  transportMethodType?: TransportMethodType;
}): {
  label: MessageType | string;
  highlighting: MessageType | string | undefined;
  description: MessageType | string;
} => {
  switch (transportMethodType) {
    case TransportMethodType.RelayPointDeposit: {
      return {
        label: messages.relayPointDeposit,
        highlighting: undefined,
        description: messages.relayPointDepositDescription,
      };
    }
    case TransportMethodType.OnSiteCollection: {
      return {
        label: messages.onSiteCollection,
        highlighting: undefined,
        description: messages.onSiteCollectionDescription,
      };
    }
    case TransportMethodType.HomeDelivery: {
      return {
        label: messages.homeDelivery,
        highlighting: undefined,
        description: messages.homeDeliveryDescription,
      };
    }

    case TransportMethodType.HomePickup: {
      return {
        label: messages.homePickup,
        highlighting: undefined,
        description: messages.homePickupDescription,
      };
    }

    case TransportMethodType.PickUpRelayPointDelivery: {
      return {
        label: messages.pickUpStationDelivery,
        highlighting: undefined,
        description: messages.pickUpStationDeliveryDescription,
      };
    }
    case TransportMethodType.DropAtStoreDeposit: {
      return {
        label: messages.dropAtStoreDeposit,
        highlighting: messages.dropAtStoreDepositHighlighting,
        description: messages.dropAtStoreDepositDescription,
      };
    }

    case TransportMethodType.HomePickupProvidedBySeller: {
      return {
        label: messages.externalHomePickup,
        highlighting: undefined,
        description: messages.externalHomePickupDescription,
      };
    }

    case TransportMethodType.PickUpStoreDelivery: {
      return {
        label: messages.pickUpStoreDelivery,
        highlighting: messages.pickUpStoreDeliveryHighlighting,
        description: messages.pickUpStoreDeliveryDescription,
      };
    }
    case TransportMethodType.ImmediateDropAtStoreDeposit: {
      return {
        label: messages.immediateDropAtStoreDeposit,
        highlighting: undefined,
        description: messages.immediateDropAtStoreDepositDescription,
      };
    }
    case TransportMethodType.LabelProvidedBySeller: {
      return {
        label: messages.sellerProvidedCarrier,
        highlighting: undefined,
        description: messages.sellerProvidedCarrierDescription,
      };
    }
    case TransportMethodType.LabelProvidedByCustomer: {
      return {
        label: messages.selfDepositCarrier,
        highlighting: undefined,
        description: messages.selfDepositCarrierDescription,
      };
    }

    case TransportMethodType.HomeIntervention: {
      return {
        label: messages.homeIntervention,
        highlighting: undefined,
        description: messages.homeInterventionDescription,
      };
    }

    default:
      return {
        label: transportMethodType ?? '',
        highlighting: undefined,
        description: '',
      };
  }
};
