import React, { useContext } from 'react';
import { Grid, Row } from 'react-styled-flexboxgrid';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { EmptyPlaceHolderWithIllustration } from '@savgroup-front-common/core/src/atoms/EmptyPlaceholder/EmptyPlaceHolderWithIllustration';
import { RadioButtonGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RadioCardColumn } from '@savgroup-front-common/core/src/molecules/RadioCardColumn';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { CloudErrorIcon } from '@savgroup-front-common/core/src/protons/icons';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';

import { FINAL_ROUTES } from '../../../app';
import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import { InitContext } from '../../../app/NewLayout/InitProvider/InitProvider.context';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import {
  $ColSvgItem,
  $RowContainer,
} from '../../../components/common/Container';
import { IrshStepValues } from '../IrshPages.types';

import messages from './messages';
import { getColumn } from './NewIssuesPage.helpers';
import useIssuesPage from './NewIssuesPage.hooks';
import { $Col, $PlaceholderPosition } from './NewIssuesPage.styles';

export const NewIssuesPage: React.FC<RoutedStepProps<IrshStepValues>> = ({
  onNextStep,
}) => {
  const { orders } = useContext(InitContext);

  const { issues, isIssuesLoading } = useIssuesPage({
    orders,
    onNextStep,
  });

  if (isIssuesLoading) {
    return <BaseLoader />;
  }

  return (
    <>
      <NewStepLayout stepName={STEP_CONFIG.ISSUES}>
        {issues.length === 0 && (
          <$PlaceholderPosition>
            <EmptyPlaceHolderWithIllustration
              illustration={<CloudErrorIcon />}
              texts={[messages.noIssuesFound, messages.contactCustomerServices]}
            />
          </$PlaceholderPosition>
        )}

        {issues.length > 0 && (
          <RadioButtonGroup name="choiceIssueRadioButtonGroup">
            <Grid fluid>
              <$RowContainer>
                {issues.map((issue) => {
                  return (
                    <$Col
                      key={issue.id}
                      xs={12}
                      sm={6}
                      md={getColumn(issues.length)}
                    >
                      <RadioCardColumn
                        title={issue.name}
                        didactics={issue.didactic}
                        name="choiceIssue"
                        value={issue.id}
                        onClick={() => {
                          onNextStep({
                            newValues: { issue },
                          });
                        }}
                        image={
                          <Row center="xs">
                            <$ColSvgItem xs={12}>
                              <MyAccountIcon icon={issue.iconKey} />
                            </$ColSvgItem>
                          </Row>
                        }
                      />
                    </$Col>
                  );
                })}
              </$RowContainer>
            </Grid>
          </RadioButtonGroup>
        )}
      </NewStepLayout>

      <PageFooterContainer reverseChildren={false}>
        <ButtonLink
          secondary
          naked
          href={FINAL_ROUTES.HOME}
          icon={<ArrowNakedLeftIcon />}
          position={SUPPORTED_ICON_POSITIONS.LEFT}
          dataTestId="previousButton"
        >
          <SafeFormattedMessageWithoutSpread
            message={messages.previousButton}
          />
        </ButtonLink>
      </PageFooterContainer>
    </>
  );
};
