import { useQuery } from 'react-query';

import { FEATURE_FLAGS_FOR_SELLER } from '@savgroup-front-common/constants';

import useToasts from '../../molecules/NotificationsProvider/ToastsProvider/hooks/useToasts';

import getFeatureFlagsQuery from './getFeatureFlagsQuery';

export const useGetFeatureFlagsQuery = ({
  featureFlag,
  sellerId,
  suspense = true,
}: {
  featureFlag: FEATURE_FLAGS_FOR_SELLER;
  sellerId?: string;
  suspense?: boolean;
}): boolean => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data } = useQuery(
    ['useGetFeatureFlagsQuery', { sellerId }],
    async () => {
      removeAllNotifications();
      const response = await getFeatureFlagsQuery({
        sellerId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return [];
      }

      return response.value;
    },
    { staleTime: Infinity, suspense },
  );

  const isFeatureEnabled = data?.find(
    (feature) => feature.key === featureFlag,
  )?.value;

  return isFeatureEnabled || false;
};
