import React, { FC, ReactElement, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { CURRENCIES } from '@savgroup-front-common/constants';
import {
  TransportMethodType,
  TransportPlan,
} from '@savgroup-front-common/core/src/api/Claim/getClaimGroupTransportPlanQuery';
import {
  homeMethods,
  immediateDropAtStoreMethods,
  pickupMethods,
} from '@savgroup-front-common/core/src/api/Claim/hooks/useGetClaimGroupTransportPlanQuery';
import { ChooseItemCard as ChooseHandlingCard } from '@savgroup-front-common/core/src/atoms/ChooseItemCard';
import SwipeableDrawer from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { selectLogin } from '@savgroup-front-common/core/src/domains/login/selectors';
import {
  AddressInfoDto,
  HandlingSummary,
  MessageType,
  PickupPoint,
  StrictAutocompleteOption,
} from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { useMyAccountTypedSelector } from '../../../../../../hooks';
import { HandlingValues, PickupPointOption } from '../../../IrshPages.types';
import DisplayHomeAddress from '../../DisplayHomeAddress/DisplayHomeAddress';
import { DisplayPickupPointAddress } from '../../DisplayPickupPointAddress/DisplayPickupPointAddress';
import { getHandlingCardLabel } from '../helpers/getHandlingCardLabel';

import { AddressManagement } from './AddressManagement/AddressManagement';
import { SelectPickupPointDrawerContent } from './SelectPickupPointDrawerContent/SelectPickupPointDrawerContent';

interface ChooseItemCardProps {
  handleOnChange?: (...value: any) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  title?: string | number | boolean | MessageType | MessageDescriptor;
  highlighting?: string | number | boolean | MessageType | MessageDescriptor;
  description?: string | number | boolean | MessageType | MessageDescriptor;
  price?: number;
  icon?: ICONS_TYPE;
  logo?: ReactElement;
  actionButton?: ReactElement;
  hasDisplayFreeAmount?: boolean;
  dataTestId?: string;
  isSelected?: boolean;

  onSetPickupPoint: ({
    selectedPickupPoint,
    selectedMethod,
  }: {
    selectedPickupPoint?: StrictAutocompleteOption<{
      pickupPoint: PickupPoint;
      transportPlan: TransportPlan;
    }>;
    selectedMethod: StrictAutocompleteOption<TransportPlan[]>;
  }) => void;
  onSetAddress: ({
    address,
    selectedMethod,
  }: {
    address: AddressInfoDto;
    selectedMethod: StrictAutocompleteOption<TransportPlan[]>;
  }) => void;
  availableMethod: StrictAutocompleteOption<TransportPlan[]>;
  handlingValues: HandlingValues;
  handlingSummary: HandlingSummary;
}

export const ChooseHandlingMethod: FC<
  React.PropsWithChildren<ChooseItemCardProps>
> = ({
  icon = undefined,
  dataTestId,
  isSelected,
  onSetAddress,
  onSetPickupPoint,
  availableMethod,
  handlingValues,
  handlingSummary,
}) => {
  const { address, selectedPickupPoint } = handlingValues;

  const hasHome = handlingSummary?.hasHome;
  const canDeduceTransportFromRefund =
    availableMethod?.data?.at(0)?.isDeductible;

  const login = useMyAccountTypedSelector(selectLogin);

  const ownerAddress = login.ownerAddress;

  const isHomeMethod = homeMethods.includes(
    availableMethod.value as TransportMethodType,
  );
  const isPickupMethod = pickupMethods
    .concat(immediateDropAtStoreMethods)
    .includes(availableMethod.value as TransportMethodType);

  const [isSelectPickupPointDrawerOpen, setIsSelectPickupPointDrawerOpen] =
    useState(false);
  const [isAddressManagementDrawerOpen, setIsAddressManagementDrawerOpen] =
    useState(false);

  const handleCardClick = React.useCallback(() => {
    if (isHomeMethod) {
      setIsAddressManagementDrawerOpen(true);

      return;
    }

    if (isPickupMethod) {
      setIsSelectPickupPointDrawerOpen(true);

      return;
    }
  }, [isHomeMethod, isPickupMethod]);

  const { label, highlighting, description } = getHandlingCardLabel({
    transportMethodType: hasHome
      ? TransportMethodType.HomeIntervention
      : (availableMethod.value as TransportMethodType),
  });

  const transportPlans = availableMethod.data;

  const transportPlanWithMinPrice = transportPlans.reduce(
    (minPlan, currentPlan) => {
      if ((currentPlan.priceWithTax ?? 0) < (minPlan.priceWithTax ?? 0)) {
        return currentPlan;
      }

      return minPlan;
    },
    transportPlans[0],
  );
  const price = transportPlanWithMinPrice.priceWithTax;

  const isAllTransportPlanHasSamePrice = transportPlans.every(
    (plan) => plan.priceWithTax === transportPlans[0].priceWithTax,
  );

  const pickupPointSubLine = selectedPickupPoint?.data?.pickupPoint ? (
    <DisplayPickupPointAddress
      pickupPointOption={selectedPickupPoint}
      onModifyClick={handleCardClick}
    />
  ) : undefined;

  const homeSubLine =
    !selectedPickupPoint && address ? (
      <DisplayHomeAddress address={address} onModifyClick={handleCardClick} />
    ) : undefined;

  return (
    <div>
      <ChooseHandlingCard
        dataTestId={dataTestId}
        icon={icon}
        isActive={isSelected}
        subLine={isSelected ? homeSubLine ?? pickupPointSubLine : undefined}
        onClick={handleCardClick}
        label={label}
        highlighting={highlighting}
        description={description}
        price={{
          amount: price,
          currency: CURRENCIES.EUR,
        }}
        displayPriceAs={isAllTransportPlanHasSamePrice ? 'amount' : 'startFrom'}
        canDeduceTransportFromRefund={canDeduceTransportFromRefund}
        hasDisplayFreeAmount
      />

      <SwipeableDrawer
        isOpen={isSelectPickupPointDrawerOpen}
        onClose={() => setIsSelectPickupPointDrawerOpen(false)}
        hasBackdrop
        hasPadding={false}
        hasBackdropScroll={false}
        hasCloseButton={false}
      >
        <SelectPickupPointDrawerContent
          availableMethod={availableMethod}
          onSetPickupPoint={({
            pickupPoint,
          }: {
            pickupPoint: PickupPointOption;
          }) =>
            onSetPickupPoint({
              selectedMethod: availableMethod,
              selectedPickupPoint: pickupPoint,
            })
          }
          onClose={() => setIsSelectPickupPointDrawerOpen(false)}
          defaultSearchAddress={
            address ?? ownerAddress
              ? {
                  address: (address ?? ownerAddress)?.address,
                  countryCode: (address ?? ownerAddress)?.countryCode,
                }
              : undefined
          }
          handlingValues={handlingValues}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        isOpen={isAddressManagementDrawerOpen}
        onClose={() => setIsAddressManagementDrawerOpen(false)}
        hasBackdrop
        hasBackdropScroll={false}
      >
        <AddressManagement
          defaultAddress={address ?? ownerAddress}
          onAddressSubmit={({ address }: { address: AddressInfoDto }) => {
            onSetAddress({
              address,
              selectedMethod: availableMethod,
            });

            setIsAddressManagementDrawerOpen(false);
          }}
        />
      </SwipeableDrawer>
    </div>
  );
};
