import flatten from 'lodash/flatten';
import mime from 'mime-types';
import * as Yup from 'yup';

import {
  ALLOWED_MIME_TYPES,
  IMAGE_DOCUMENT_TYPES,
  MIME_TYPES,
} from '@savgroup-front-common/constants';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

import { safeFormattedIntlString } from '../../formatters';
import {
  getUniqErrorMessages,
  globalMessages,
  isValidIMEI,
} from '../../helpers';

import messages from './messages';

const typesToConstraintMap = {
  [ADDITIONAL_INFORMATION_TYPES.DATE]: () => Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.DECIMAL]: () =>
    Yup.number().transform((value) =>
      Number.isNaN(value) ? undefined : value,
    ),
  [ADDITIONAL_INFORMATION_TYPES.CHECKBOX]: () => Yup.boolean().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_CONDITION_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_DEFECT_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_REPAIR_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_SECTION_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_EXTENDED_CONDITION_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IRIS_SYMPTOM_CODE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.ENUM]: () => Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.SUPPLIER_CREDIT_NOTE_REJECTION_TYPE]: () =>
    Yup.object().nullable(),
  [ADDITIONAL_INFORMATION_TYPES.IMEI]: () =>
    Yup.string()
      .nullable()
      .test('validIMEI', messages.mustBeValidImei, (value) => {
        return isValidIMEI(value);
      }),
  [ADDITIONAL_INFORMATION_TYPES.STRING]: () => Yup.string(),
  [ADDITIONAL_INFORMATION_TYPES.INTEGER]: () =>
    Yup.number().transform((value) =>
      Number.isNaN(value) ? undefined : value,
    ),
  [ADDITIONAL_INFORMATION_TYPES.FILE]: ({ documentType } = {}) =>
    Yup.object()
      .nullable()
      .test('validFile', (file, { createError, path }) => {
        if (file?.errors) {
          return createError({
            message: getUniqErrorMessages(file?.errors)[0],
            path,
          });
        }

        return true;
      })
      .test(
        'max-filesize',
        safeFormattedIntlString(globalMessages.form.maximumFileSize),
        (file) => {
          if (!file || !file?.value) {
            return true;
          }

          return (file?.value?.size || 0) <= 25_000_000;
        },
      )
      .test('unsupported-filetype', (file, { createError }) => {
        if (!file || !file?.value) {
          return true;
        }

        const allowedMimeTypes =
          documentType &&
          Object.values(IMAGE_DOCUMENT_TYPES).includes(documentType)
            ? [
                MIME_TYPES.JPEG,

                MIME_TYPES.PNG,
                MIME_TYPES.IMAGE_HEIC,
                MIME_TYPES.PDF,
              ]
            : ALLOWED_MIME_TYPES;

        const adaptedMimeTypes = allowedMimeTypes.map((type) =>
          type.toString(),
        );

        if (!file?.value?.type) {
          return true;
        }

        if (
          adaptedMimeTypes.includes((file?.value?.type || '').toLowerCase())
        ) {
          return true;
        }

        return createError({
          message: safeFormattedIntlString(
            globalMessages.form.unsupportedFileTypeWithSpecificSupportedOne,
            {
              supportedTypes: allowedMimeTypes
                .map((type) => {
                  return mime.extension(type.toString());
                })
                .join(', '),
            },
          ),
        });
      }),
  [ADDITIONAL_INFORMATION_TYPES.MULTI_FILES]: () =>
    Yup.array()
      .nullable()
      .of(
        Yup.object()
          .nullable()
          .test('validFile', (file, { createError, path }) => {
            if (file?.errors) {
              return createError({
                message: getUniqErrorMessages(file?.errors)[0],
                path,
              });
            }

            return true;
          })
          .test(
            'max-filesize',
            safeFormattedIntlString(globalMessages.form.maximumFileSize),
            (file) => {
              if (!file || !file?.value) {
                return true;
              }

              return (file?.value?.size || 0) <= 25_000_000;
            },
          )
          .test('unsupported-filetype', (file, { createError }) => {
            if (!file || !file?.value) {
              return true;
            }

            const allowedMimeTypes = [
              MIME_TYPES.JPEG,
              MIME_TYPES.PNG,
              MIME_TYPES.IMAGE_HEIC,
              MIME_TYPES.PDF,
            ];

            const adaptedMimeTypes = allowedMimeTypes.map((type) =>
              type.toString(),
            );

            if (!file?.value?.type) {
              return true;
            }

            if (
              adaptedMimeTypes.includes((file?.value?.type || '').toLowerCase())
            ) {
              return true;
            }

            return createError({
              message: safeFormattedIntlString(
                globalMessages.form.unsupportedFileTypeWithSpecificSupportedOne,
                {
                  supportedTypes: allowedMimeTypes
                    .map((type) => {
                      return mime.extension(type.toString());
                    })
                    .join(', '),
                },
              ),
            });
          }),
      )
      .max(15, messages.maximumFiles),
};

function getInputType({ type, documentType }) {
  const constraint = typesToConstraintMap[type];

  if (!constraint) {
    return Yup.string();
  }

  if (documentType) {
    return constraint({ documentType });
  }

  return constraint();
}

function applyIsRequiredToBase(
  base,
  isRequired,
  meta,
  validatingRegex = undefined,
) {
  const { type } = meta;
  const textType = [
    ADDITIONAL_INFORMATION_TYPES.STRING,
    ADDITIONAL_INFORMATION_TYPES.SERIAL_NUMBER,
    ADDITIONAL_INFORMATION_TYPES.EXCHANGE_ORDER_REFERENCE,
    ADDITIONAL_INFORMATION_TYPES.RMA_AGREEMENT_NUMBER,
  ];

  if (type === ADDITIONAL_INFORMATION_TYPES.CHECKBOX) {
    return base.test(
      'required-checkbox',
      messages.thisFieldIsRequired,
      (value) => {
        return value === true;
      },
    );
  }

  if (textType.includes(type) && !!validatingRegex && isRequired) {
    return base.test('regex-invalid', messages.mustBeValidRegex, (value) => {
      const regex = new RegExp(validatingRegex);

      return regex.test(value);
    });
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.DATE && isRequired) {
    return base.test(
      'required-moment',
      messages.thisFieldIsRequired,
      (value) => {
        if (!value) {
          return false;
        }

        return true;
      },
    );
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.FILE && isRequired) {
    return base.test('required-file', messages.thisFieldIsRequired, (file) => {
      return Boolean(file);
    });
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES && isRequired) {
    return base.min(1, messages.thisFieldIsRequired);
  }

  return isRequired ? base.required(messages.thisFieldIsRequired) : base;
}

export function getConstraint(neededInformationItem) {
  const { type, isRequired, validatingRegex, documentType } =
    neededInformationItem;
  const base = getInputType({ type, documentType });

  return applyIsRequiredToBase(base, isRequired, { type }, validatingRegex);
}

const schema = ({ neededInformationList = {} }) => {
  const values = Object.values(neededInformationList);

  if (!values.length) {
    return undefined;
  }

  return flatten(values)
    .filter(
      (neededInformationItem) =>
        ![
          ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_ALERT,
          ADDITIONAL_INFORMATION_TYPES.NOTIFICATION_INFO,
        ].includes(neededInformationItem.type),
    )
    .reduce((acc, neededInformationItem) => {
      const { internalId, id, claimIds } = neededInformationItem;

      if (!internalId) {
        const computedInternalId = neededInformationItem.uniquenessByProduct
          ? `${id}_${claimIds?.at(0)}`
          : id;

        return acc.shape({
          [computedInternalId]: getConstraint(neededInformationItem),
        });
      }

      return acc.shape({
        [internalId]: getConstraint(neededInformationItem),
      });
    }, Yup.object());
};

export default schema;
