import { FileProductSummary } from './File';
import { ReverseMoneyNumberAmount } from './ReverseMoney';
import { SpareProductSize } from './SparePartCatalog';
import { WarrantyTypeGroup } from './WarrantyTypeGroup';

export enum SparePartStockAvailability {
  UNAVAILABLE = 'Unavailable',
  LOCAL_STOCK = 'LocalStock',
  SUPPLIER_STOCK = 'SupplierStock',
}

export enum SparePartStockVisibilityQuantity {
  UNKNOWN_QUANTITY = 'UnknownQuantity',
  DISPLAY_QUANTITY = 'DisplayQuantity',
  HIDE_QUANTITY = 'HideQuantity',
}

export enum SparePartCondition {
  NEW = 'New',
  RECONDITIONED = 'Reconditioned',
  USED = 'Used',
}

export enum SparePartLinkType {
  OEM_REFERENCE = 'OEMReference',
  SUBSTITUTION = 'Substitution',
  COMPATIBLE = 'Compatible',
}

export enum RequestStatus {
  TO_VALIDATE = 'ToValidate',
  VALID = 'Valid',
  CANCELLED_COMMERCIAL_SOLUTION_NOT_REPAIRABLE = 'CancelledCommercialSolutionNotRepairable',
  CANCELLED_NEW_DIAGNOSTIC = 'CancelledNewDiagnostic',
  PENDING = 'Pending',
}

export enum RequestLineStatus {
  TO_VALIDATE = 'ToValidate',
  VALID = 'Valid',
  CANCELLED_ABANDONED = 'CancelledAbandoned',
  AVAILABLE_IN_STOCK = 'AvailableInStock',
  SUBMITTED_TO_SUPPLIER = 'SubmittedToSupplier',
  ORDER_SUBMITTED = 'OrderSubmitted',
  REMAINDER = 'Remainder',
  REJECTED = 'Rejected',
  MISSING = 'Missing',
  SOLD_OUT = 'SoldOut',
  ORDER_SHIPPED = 'OrderShipped',
  ORDER_DELIVERED = 'OrderDelivered',
  ORDER_COLLECTED = 'OrderCollected',
  CANCELLED_COMMERCIAL_SOLUTION_REMAINDER = 'CancelledCommercialSolutionRemainder',
  CANCELLED_COMMERCIAL_SOLUTION_EXPIRED = 'CancelledCommercialSolutionExpired',
  CANCELLED_COMMERCIAL_SOLUTION_SOLD_OUT = 'CancelledCommercialSolutionSoldOut',
  CANCELLED_COMMERCIAL_SOLUTION_NOT_REPAIRABLE = 'CancelledCommercialSolutionNotRepairable',

  TO_PROCESS = 'ToProcess',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  DISPATCHED = 'Dispatched',
  TO_BE_DISPATCHED = 'ToBeDispatched',
}

interface SparePartGroupBOMPosition {
  comment: string;
  sparePartGroupId: string;
  sparePartGroupUniqueName: string;
  sparePartBOMPosition?: string;
}

export interface SparePartSearchSummary {
  sparePartId: string;
  sparePartSupplierAssociationId: string;
  manufacturerReference: string;
  manufacturerLabel: string;
  manufacturerName: string;
  supplierName: string;
  supplierPartReference: string;
  sparePartSupplierId: string;
  condition: SparePartCondition;
  size: SpareProductSize;
  pretaxPurchasePrice: ReverseMoneyNumberAmount;
  recommendedSalePriceIncludingTaxes: ReverseMoneyNumberAmount;
  availableQuantity: number;
  stockAvailability: SparePartStockAvailability;
  stockVisibility?: SparePartStockVisibilityQuantity;
  category: string;
  subCategory: string;
  minDeliveryDays: number;
  maxDeliveryDays: number;
  stockName: string;
  stockOrigin: string;
  manufacturerId: string;
  isOEM?: boolean;
  linkType?: string;
  sparePartGroupsBOMPosition?: SparePartGroupBOMPosition[];
}

export enum DeliveryLocationType {
  DEPOSIT_STORE = 'DepositStore',
  CLIENT = 'Client',
  STORE = 'Store',
  BOX = 'Box',
  HUB = 'Hub',
  WORKSHOP = 'Workshop',
  WAREHOUSE = 'Warehouse',
  CUSTOM_ADDRESS = 'CustomAddress',
  VAN = 'Van',
  ON_PREMISE = 'OnPremise',
}

export enum RequestReliability {
  RELIABLE = 'Reliable',
  TO_CHECK = 'ToCheck',
  TO_REFERENCE = 'ToReference',
}
export enum Validity {
  TO_VALIDATE = 'ToValidate',
  VALID = 'Valid',
  CANCELLED = 'Cancelled',
  TO_PROCESS = 'ToProcess',
  ORDER_SUBMITTED = 'OrderSubmitted',
  ORDER_DELIVERED = 'OrderDelivered',
  ORDER_COLLECTED = 'OrderCollected',
  AVAILABLE_IN_STOCK = 'AvailableInStock',
  REJECTED = 'Rejected',
  MISSING = 'Missing',
  CLOSED = 'Closed',
  ORDER_SHIPPED = 'OrderShipped',
  CANCELLED_ABANDONED = 'CancelledAbandoned',
  SUBMITTED_TO_SUPPLIER = 'SubmittedToSupplier',
  REMAINDER = 'Remainder',
  SOLD_OUT = 'SoldOut',
  CANCELLED_COMMERCIAL_SOLUTION_REMAINDER = 'CancelledCommercialSolutionRemainder',
  CANCELLED_COMMERCIAL_SOLUTION_EXPIRED = 'CancelledCommercialSolutionExpired',
  CANCELLED_COMMERCIAL_SOLUTION_SOLD_OUT = 'CancelledCommercialSolutionSoldOut',
  CANCELLED_COMMERCIAL_SOLUTION_NOT_REPAIRABLE = 'CancelledCommercialSolutionNotRepairable',
}

export interface RequestValidation {
  fileId?: string;
  documentIndexKey: string;
  oldestRequestDate: Date;
  fileReference: string;
  fileProduct: FileProductSummary;
  sellerName: string;
  interventionsCount: number;
  warranty: string;
  warrantyTypeGroup: WarrantyTypeGroup;
  partsQuantity: number;
  partsPriceSum?: ReverseMoneyNumberAmount;
  productPrice?: ReverseMoneyNumberAmount;
  dpaCost: number;
  reliability: RequestReliability;
  status: RequestLineStatus;
  requestLines: RequestLineValidation[];
  requester?: string;
  vinCode: string;
  exceedSellerReparabilityThreshold: boolean;
  possibleNextStatuses: RequestLineStatus[];
  isFileClosed: boolean;
}

export interface RequestLineValidation {
  requestLineId: string;
  creationDate: Date;
  label?: string;
  manufacturerName: string;
  manufacturerReference: string;
  supplierName: string;
  supplierReference?: string;
  deliveryLocationType: DeliveryLocationType;
  intermediateDeliveryLocationType?: DeliveryLocationType;
  deliveryLocationName: string;
  intermediateDeliveryLocationName?: string;
  purchasePrice?: ReverseMoneyNumberAmount;
  reliability: RequestReliability;
  status: RequestLineStatus;
  requester: string;
  possibleNextStatuses: RequestLineStatus[];
  stockItemId?: string;
  orderReference?: string;
}

export enum SparePartStateUponReceipt {
  COMPLIANT = 'Compliant',
  BROKEN = 'Broken',
  MISMATCH = 'Mismatch',
  MISMATCH_WRONG_PART = 'MismatchWrongPart',
}

export enum FilterScope {
  SPARE_PART_REQUESTS = 'SparePartRequests',
  SUPPLIER_DISPATCHES = 'SupplierDispatches',
  OUTGOING_DISPATCHES = 'OutgoingDispatches',
}
