import React, { FC } from 'react';

import { ReverseMoneyNumberAmount } from '@savgroup-front-common/types';

import { SafeFormattedMessageWithoutSpread } from '../../../formatters';
import { formatReverseMoneyToString } from '../../../formatters/intl';
import { ReverseMoneyHelper } from '../../../helpers/ReverseMoneyHelper';

import messages from './messages';
import { StatelessQuotationTotalVariants } from './StatelessQuotationTotal.constants';
import {
  $FinalTotalLine,
  $QuotationTotal,
  $TotalLine,
} from './StatelessQuotationTotal.styles';

interface QuotationTotalProps {
  subtotalVat: ReverseMoneyNumberAmount;
  subtotalPreVat: ReverseMoneyNumberAmount;
  totalDiscountIncludedTax: ReverseMoneyNumberAmount;
  alreadyPaid: ReverseMoneyNumberAmount;
  totalAmountToBePaid: ReverseMoneyNumberAmount;
  bonus?: ReverseMoneyNumberAmount;
  variant?: StatelessQuotationTotalVariants;
}

export const StatelessQuotationTotal: FC<QuotationTotalProps> = ({
  totalAmountToBePaid,
  totalDiscountIncludedTax,
  alreadyPaid,
  subtotalVat,
  subtotalPreVat,
  bonus,
  variant = StatelessQuotationTotalVariants.DEFAULT,
}) => {
  return (
    <$QuotationTotal>
      <$TotalLine>
        <SafeFormattedMessageWithoutSpread message={messages.subTotalPreVat} />{' '}
        :{' '}
        <span data-testid="QuotationTotal_subtotalPreVat">
          {formatReverseMoneyToString(subtotalPreVat)}
        </span>
      </$TotalLine>
      <$TotalLine>
        <SafeFormattedMessageWithoutSpread
          message={messages.subTotalIncludingVat}
        />{' '}
        :{' '}
        <span data-testid="QuotationTotal_subtotalVat">
          {formatReverseMoneyToString(subtotalVat)}
        </span>
      </$TotalLine>
      <$TotalLine>
        <SafeFormattedMessageWithoutSpread message={messages.discount} /> :{' '}
        <span data-testid="QuotationTotal_totalDiscountIncludedTax">
          {formatReverseMoneyToString(
            ReverseMoneyHelper.multiplyByNumber(
              ReverseMoneyHelper.abs(totalDiscountIncludedTax),
              -1,
            ),
          )}
        </span>
      </$TotalLine>
      {bonus && (
        <$TotalLine>
          <SafeFormattedMessageWithoutSpread message={messages.bonus} /> :
          <span data-testid="QuotationTotal_bonus">
            {formatReverseMoneyToString(
              ReverseMoneyHelper.multiplyByNumber(
                ReverseMoneyHelper.abs(bonus),
                -1,
              ),
            )}
          </span>
        </$TotalLine>
      )}
      <$TotalLine>
        <SafeFormattedMessageWithoutSpread message={messages.alreadyPaid} /> :{' '}
        <span data-testid="QuotationTotal_alreadyPaid">
          {formatReverseMoneyToString(
            ReverseMoneyHelper.multiplyByNumber(
              ReverseMoneyHelper.abs(alreadyPaid),
              -1,
            ),
          )}
        </span>
      </$TotalLine>
      <$FinalTotalLine $variant={variant}>
        <div>
          <SafeFormattedMessageWithoutSpread
            message={messages.totalToBePaidByOwnerIncludingVat}
          />{' '}
          :{' '}
          <span data-testid="QuotationTotal_totalAmountToBePaid">
            {formatReverseMoneyToString(totalAmountToBePaid)}
          </span>
        </div>
      </$FinalTotalLine>
    </$QuotationTotal>
  );
};
