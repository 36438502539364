import React from 'react';
import { generatePath } from 'react-router-dom';
import { useMedia } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import { FINAL_ROUTES } from '../../../app';
import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { ResumeSolution } from '../../../components/ResumeSolution';
import { AvailableHandlingMethods } from '../components/AvailableHandlingMethods/AvailableHandlingMethods';
import { IrshStepValues } from '../IrshPages.types';

import useClaimGroupDepositPage from './ClaimGroupDepositPage.hooks';
import {
  $DepositItemWrapper,
  $DepositResultWrapper,
  $DepositWrapper,
  $HeadingIcon,
  $LeftColumn,
} from './ClaimGroupDepositPage.styles';
import messages from './messages';

export const ClaimGroupDepositPage: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onNextStep, onPreviousStep }) => {
  const isMobileView = useMedia(media.maxWidth.xs);

  const {
    claimGroupConfirmation,
    claimGroupId,
    handling,
    onSubmit,
    handleSetPickupPoint,
    handleSetAddress,
    handlingValues,
    isSubmitLoading,
    depositAvailableMethods,
  } = useClaimGroupDepositPage({
    onNextStep,
  });

  const isValid =
    (!!handlingValues?.address || !!handlingValues?.pickupPointSelected) &&
    !!handlingValues?.selectedMethod;

  if (!handling || !claimGroupConfirmation) {
    return <BaseLoader />;
  }

  return (
    <>
      <NewStepLayout stepName={STEP_CONFIG.CLAIM_GROUP_DEPOSIT}>
        <$DepositWrapper>
          <$LeftColumn>
            <ResumeSolution
              claimGroupId={claimGroupId}
              claimGroupConfirmation={claimGroupConfirmation}
              customBuildUpdateSolutionUrl={generatePath(
                FINAL_ROUTES.CLAIM_GROUP_SOLUTION,
                {
                  claimGroupId,
                },
              )}
            />

            <Heading level={3} dataTestId="handling-deposit-title">
              <$HeadingIcon>
                <MyAccountIcon icon={ICONS_TYPE.DEPOSIT_ICON} />
              </$HeadingIcon>
              <SafeFormattedMessageWithoutSpread
                message={
                  handling?.hasHome
                    ? messages.handlingTitleOnlyHome
                    : messages.handlingTitle
                }
              />
            </Heading>

            <$DepositItemWrapper>
              <AvailableHandlingMethods
                handlingSummary={handling}
                availableMethods={depositAvailableMethods}
                onSetPickupPoint={handleSetPickupPoint}
                onSetAddress={handleSetAddress}
                handlingValues={handlingValues}
              />
            </$DepositItemWrapper>
          </$LeftColumn>

          <$DepositResultWrapper>
            {isMobileView && (
              <ResumeCardButton
                claimGroupConfirmation={claimGroupConfirmation}
                isTransportPlanFeatureEnabled
              />
            )}
            {!isMobileView && (
              <ResumeCard
                claimGroupConfirmation={claimGroupConfirmation}
                customLinkModify={generatePath(
                  FINAL_ROUTES.CLAIM_GROUP_REASON,
                  {
                    claimGroupId,
                  },
                )}
                isTransportPlanFeatureEnabled
              />
            )}
          </$DepositResultWrapper>
        </$DepositWrapper>
      </NewStepLayout>

      <PageFooterContainer>
        <Button
          primary
          type={BUTTON_TYPES.BUTTON}
          icon={<ArrowNakedRightIcon />}
          position={SUPPORTED_ICON_POSITIONS.RIGHT}
          disabled={!isValid}
          isLoading={isSubmitLoading}
          onClick={() => onSubmit(handlingValues)}
          dataTestId="nextButton"
        >
          <SafeFormattedMessageWithoutSpread message={messages.nextButton} />
        </Button>

        <Button
          type={BUTTON_TYPES.BUTTON}
          secondary
          naked
          onClick={() => {
            onPreviousStep({});
          }}
          icon={<ArrowNakedLeftIcon />}
          position={SUPPORTED_ICON_POSITIONS.LEFT}
          dataTestId="previousButton"
        >
          <SafeFormattedMessageWithoutSpread
            message={messages.previousButton}
          />
        </Button>
      </PageFooterContainer>
    </>
  );
};
