import { defineMessages } from 'react-intl';

export default defineMessages({
  billingLabel: {
    id: 'view.myaccount.step.chooseProduct.billing',
    defaultMessage: 'Facture {value}',
  },
  additionalDateText: {
    id: 'view.myaccount.step.chooseProduct.additionalDateText',
    defaultMessage: 'from',
  },
  numberItems: {
    id: 'view.myaccount.step.chooseProduct.numberItems',
    defaultMessage: '{value, plural, one {# article} other {# articles}}',
  },
  isGenericHeader: {
    id: 'view.myaccount.step.chooseProduct.isGenericHeader',
    defaultMessage: 'produit generic',
  },
  withoutWarranty: {
    id: 'view.myaccount.step.chooseProduct.withoutWarranty',
    defaultMessage: 'Without warranty',
  },

  previousButton: {
    id: 'global.stepsOrchestrator.previousButton',
    defaultMessage: 'Previous',
  },
  nextButton: {
    id: 'view.myaccount.component.nextButton',
    defaultMessage: 'Next',
  },
});
