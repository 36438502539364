import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import {
  Modal,
  MODAL_SIZES,
} from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { Field } from '@savgroup-front-common/core/src/molecules/Field';
import { FileSparePartRequestLine } from '@savgroup-front-common/types';

import messagesSpareParts from './DeliveredSparePart/messages';
import {
  $DeliveredSparePartActionButton,
  $DeliveredSparePartInfoModal,
} from './DeliveredSparePartsList.styles';
import messages from './messages';

interface DeliveredSparePartsListModalTypeProps {
  isOpen?: boolean;
  onClose: () => void;
  onValidate: () => void;
  currentRequestLine: FileSparePartRequestLine | undefined;
}

const DeliveredSparePartsListModal: FunctionComponent<
  DeliveredSparePartsListModalTypeProps
> = ({ isOpen, onClose, currentRequestLine, onValidate }) => {
  const theme = useTheme();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={MODAL_SIZES.SMALL}>
      {currentRequestLine && (
        <>
          <Heading level={3}>
            <SafeFormattedMessageWithoutSpread message={messages.title} />
          </Heading>

          <Banner notificationType={NOTIFICATION_TYPES.ALERT} hollow>
            <SafeFormattedMessageWithoutSpread message={messages.warningMsg} />
          </Banner>

          <div>
            <div>
              <$DeliveredSparePartInfoModal>
                <Field
                  label={messagesSpareParts.ref}
                  isNewUi={false}
                  dataTestId={`deliveredSparePartsListModal_${currentRequestLine.requestLineId}_sparePartReference`}
                >
                  {currentRequestLine.sparePartReference}
                </Field>
              </$DeliveredSparePartInfoModal>

              <$DeliveredSparePartInfoModal>
                <Field
                  label={messagesSpareParts.supplier}
                  isNewUi={false}
                  dataTestId={`deliveredSparePartsListModal_${currentRequestLine.requestLineId}_supplierName`}
                >
                  {currentRequestLine.supplierName}
                </Field>
              </$DeliveredSparePartInfoModal>
            </div>

            <ActionRow>
              <$DeliveredSparePartActionButton
                theme={theme}
                type={BUTTON_TYPES.BUTTON}
                onClick={onValidate}
                dataTestId={`deliveredSparePartsListModal_${currentRequestLine.requestLineId}_validateButton`}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.validateButton}
                />
              </$DeliveredSparePartActionButton>
            </ActionRow>
          </div>
        </>
      )}
    </Modal>
  );
};

export default DeliveredSparePartsListModal;
