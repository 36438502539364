import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

import { prepareResponseFailure } from '../../helpers';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.owner}owners/me/preferences`;

export const setOwnerLanguagePreference = async ({
  preferences,
}: {
  preferences: {
    language: string;
  };
}): Promise<BaseBackResponse | BackResponseFailure> => {
  try {
    return await apiCall<BaseBackResponse>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        preferences,
      },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
