import React, { FunctionComponent, ReactElement, ReactNode } from 'react';

import { SUPPORTED_ICON_POSITIONS } from '../../../helpers';
import { useIsNewUiEnabled } from '../../../hooks/useIsNewUiEnabled';
import { Icon } from '../Icon';
import LoadingBasedOnProps from '../LoadingBasedOnProps';

import { $InnerLinkStyled } from './ButtonLink.styles';

export interface ButtonLinkProps {
  href?: string;
  icon?: ReactElement;
  isExternal?: boolean;
  target?: string;
  rel?: string;
  disabled?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  componentThemeName?: string;
  hollow?: boolean;
  underline?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  naked?: boolean;
  primary?: boolean;
  position?: SUPPORTED_ICON_POSITIONS;
  dataTestId?: string;
  download?: string;
  rounded?: boolean;
  success?: boolean;
  small?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  alert?: boolean;
  isFluid?: boolean;
  isMinimal?: boolean;
  ariaLabel?: string;
}

const ButtonLink: FunctionComponent<
  React.PropsWithChildren<ButtonLinkProps>
> = ({
  href,
  icon,
  children,
  isExternal,
  target,
  rel,
  disabled = false,
  isLoading = false,
  hollow = false,
  underline = false,
  secondary = false,
  tertiary = false,
  naked = false,
  componentThemeName,
  primary = false,
  position = SUPPORTED_ICON_POSITIONS.LEFT,
  dataTestId,
  download,
  rounded = false,
  success = false,
  onClick,
  isFluid = false,
  isMinimal = false,
  ariaLabel,
  ...rest
}) => {
  const isSquareIconButton = Boolean(icon && children === undefined);
  const isNewUiEnabled = useIsNewUiEnabled();

  return (
    <$InnerLinkStyled
      fluid={isFluid} // For <Button /> styles => to migrate into isFluid
      isFluid={isFluid} // For <ButtonLink /> styles
      rounded={rounded}
      href={href}
      isExternal={isExternal}
      target={target}
      rel={rel}
      $isSquareIconButton={isSquareIconButton}
      {...rest}
      disabled={disabled}
      position={position}
      isLoading={isLoading}
      hollow={hollow}
      underline={underline}
      secondary={secondary}
      naked={naked}
      componentThemeName={componentThemeName}
      primary={primary}
      dataTestId={dataTestId}
      download={download}
      onClick={onClick}
      success={success}
      $isIcon={icon !== undefined}
      $isOnlyIcon={!!children && icon !== undefined}
      $isNewUiEnabled={isNewUiEnabled}
      $isMinimal={isMinimal}
      ariaLabel={ariaLabel}
    >
      <LoadingBasedOnProps
        disabled={disabled}
        isLoading={isLoading}
        secondary={secondary}
        primary={primary}
        tertiary={tertiary}
        success={success}
      >
        {!icon && children}
        {icon && (
          <Icon
            icon={icon}
            position={position}
            componentThemeName={componentThemeName}
            tertiary={tertiary}
            secondary={secondary}
          >
            {children}
          </Icon>
        )}
      </LoadingBasedOnProps>
    </$InnerLinkStyled>
  );
};

export default ButtonLink;
