import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { ChooseItemCard as CommonChooseItemCard } from '@savgroup-front-common/core/src/atoms/ChooseItemCard/ChooseItemCard';
import { Solution } from 'myaccount/api/Claim/getSolutionsByClaim';

import { useSolutionItem } from './SolutionItem.hooks';

interface SolutionItemProps {
  solution: Solution;
  onClick: () => void;
  dataTestId?: string;
}

const SolutionItem: FC<SolutionItemProps> = ({
  solution,
  onClick,
  dataTestId,
}) => {
  const { iconKey } = useSolutionItem({
    solutionTypeId: solution.solutionTypeId,
  });

  const formContext = useFormContext();
  const { setValue, watch } = formContext;
  const chooseSolution = watch('chooseSolution');

  return (
    <>
      <CommonChooseItemCard
        label={solution.name}
        price={{
          amount: solution.priceWithTax,
          currency: CURRENCIES.EUR,
        }}
        description={solution.didactic}
        onClick={() => {
          setValue('chooseSolution', solution.solutionTypeId);

          onClick();
        }}
        icon={iconKey}
        hasDisplayFreeAmount={false}
        dataTestId={dataTestId}
        isActive={solution.solutionTypeId === chooseSolution}
      />
    </>
  );
};

export default SolutionItem;
