import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import {
  AddressInfoDto,
  PickupPointAddress,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { useGetClaimGroupCarrierQuery } from 'myaccount/view/app/hooks/useGetClaimGroupCarrierQuery';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import useGetHomePickup from 'myaccount/view/app/hooks/useGetHomePickup';
import useGetMultiCarrierProductByIdQuery from 'myaccount/view/app/hooks/useGetMultiCarrierProductByIdQuery';

import { getGroupedCarriers } from '../../../../helpers';
import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { IrshStepValues } from '../IrshPages.types';

import NewClaimGroupDepositPageSchema from './NewClaimGroupDepositPage.schema';
import { ClaimGroupDepositValues } from './NewClaimGroupDepositPage.types';

interface UseClaimGroupDepositPageArgs {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupDepositPage = ({
  onNextStep,
}: UseClaimGroupDepositPageArgs) => {
  const { removeAllNotifications, pushErrors } = useToasts();
  const [openModalRelayPointDeposit, setOpenModalRelayPointDeposit] =
    useState(false);
  const [openModalHomeDeposit, setOpenModalHomeDeposit] = useState(false);

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const handling = values?.handling;

  const { homePickupAddress, mail } = useGetHomePickup();

  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const depositAddress =
    claims && claims?.at(0) && claims?.at(0)?.depositAddress
      ? claims?.at(0)?.depositAddress
      : undefined;

  const ownerAddress = depositAddress || homePickupAddress;

  const { claimGroupConfirmation, isLoading: isGetClaimGroupConfirmation } =
    useGetClaimGroupConfirmation({
      claimGroupId,
    });

  const hasDeposit = handling?.hasDeposit;

  const { carriers, isLoading: isGetCarrierLoading } =
    useGetClaimGroupCarrierQuery({
      claimGroupId,
      handlingMode: hasDeposit ? HANDLING_MODES.DEPOSIT : HANDLING_MODES.HOME,
    });

  const { multiCarrierProductSummary } = useGetMultiCarrierProductByIdQuery({
    carrierProductIds: carriers
      ?.map((carrier) => carrier.carrierProductId)
      .filter((carrier) => carrier) as string[],
  });

  const { groupedCarriers } = getGroupedCarriers({
    carriers,
    multiCarrierProductSummary,
    handlingInfo: handling,
    handlingMode: HANDLING_MODES.DEPOSIT,
  });

  const shouldShowTransportDeduction =
    handling?.canDeduceTransportFromRefund &&
    carriers?.some((carrier) => carrier.priceWithTax);

  const formContext = useForm<ClaimGroupDepositValues>({
    defaultValues: {
      addressSelected:
        values?.handlingDeposit?.depositAddress ?? ownerAddress
          ? {
              ...ownerAddress,
              firstName: (ownerAddress as any).firstname,
              lastName: (ownerAddress as any).lastname,
              mail,
            }
          : undefined,
      chooseHandling: values?.handlingDeposit?.chooseHandling,
      pickupPointSelected: values?.handlingDeposit?.pickupPointSelected,
    },
    resolver: yupResolver(NewClaimGroupDepositPageSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit, setValue, watch } = formContext;

  const [hasChangeValue, setChangedValue] = useState(false);

  const changeSelection = () => {
    setValue('chooseHandling', undefined);
    setValue('pickupPointSelected', undefined);
    setChangedValue(false);
    setOpenModalRelayPointDeposit(false);
    setOpenModalHomeDeposit(false);
  };

  const resetAddress = () => {
    setValue('pickupPointSelected', undefined);
    setValue('addressSelected', undefined);
  };

  const {
    mutateAsync: handleDepositHandling,
    isLoading: isLoadingDepositHandlingSubmit,
  } = useMutation(
    ['setDepositHandling'],
    async ({
      claimGroupId,
      payload,
    }: {
      claimGroupId?: string;
      payload: {
        handlingMode: HANDLING_MODES;
        pickupPointId?: string;
        pickupPointAddress?: PickupPointAddress | null;
        carrierCustomerPriceId?: string;
        carrierSellerPriceId?: string;
        address?: AddressInfoDto | null;
      };
    }) => {
      removeAllNotifications();
      const responseHandling = await ClaimService.setClaimGroupHandling({
        claimGroupId,
        payload,
      });

      if (responseHandling.failure) {
        pushErrors(responseHandling.errors);

        return undefined;
      }

      return responseHandling;
    },
  );

  const chooseHandlingSelected = watch('chooseHandling');
  const pickupPointSelected = watch('pickupPointSelected');

  const handlingSelected = Boolean(
    chooseHandlingSelected === HANDLING_GROUPS.PICKUP_POINT ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE_DELIVERY,
  );

  const homeSelectedAddressChanged = Boolean(
    hasChangeValue &&
      (chooseHandlingSelected === HANDLING_GROUPS.HOME ||
        chooseHandlingSelected === HANDLING_GROUPS.HOME_INTERVENTION ||
        chooseHandlingSelected === HANDLING_GROUPS.EXTERNAL),
  );

  const handleOpenDepositModal = (e: React.MouseEvent<HTMLInputElement>) => {
    resetAddress();

    if (
      e.currentTarget.value === HANDLING_GROUPS.HOME ||
      e.currentTarget.value === HANDLING_GROUPS.HOME_INTERVENTION ||
      e.currentTarget.value === HANDLING_GROUPS.EXTERNAL
    ) {
      setOpenModalHomeDeposit((prev) => !prev);

      return;
    }

    if (
      e.currentTarget.value === HANDLING_GROUPS.PICKUP_POINT ||
      e.currentTarget.value === HANDLING_GROUPS.PICKUP_STORE ||
      e.currentTarget.value === HANDLING_GROUPS.PICKUP_STORE_DELIVERY
    ) {
      setOpenModalRelayPointDeposit((prev) => !prev);

      return;
    }

    return;
  };

  const onSubmit = handleSubmit(
    async ({
      chooseHandling,
      pickupPointSelected,
      addressSelected,
      addressSuggested,
      keepSuggestedAddress,
    }) => {
      const hasHome = handling?.hasHome;

      const payload =
        !hasHome && pickupPointSelected
          ? {
              handlingMode: HANDLING_MODES.DEPOSIT,
              pickupPointId: pickupPointSelected.id,
              pickupPointAddress: pickupPointSelected && {
                id: pickupPointSelected.id,
                name: pickupPointSelected.name,
                firstName: pickupPointSelected.name,
                address: pickupPointSelected.adress,
                additionalAddress: pickupPointSelected.adress3,
                postalCode: pickupPointSelected.postalCode,
                city: pickupPointSelected.city,
                countryCode: pickupPointSelected.countryCode,
              },
              carrierCustomerPriceId:
                pickupPointSelected.carrierCustomerPriceId,
              carrierSellerPriceId: pickupPointSelected.carrierSellerPriceId,
              address: undefined,
            }
          : {
              handlingMode: hasHome
                ? HANDLING_MODES.HOME
                : HANDLING_MODES.DEPOSIT,
              pickupPointId: undefined,
              pickupPointAddress: undefined,
              carrierCustomerPriceId:
                groupedCarriers[chooseHandling || HANDLING_GROUPS.HOME][0]
                  .carrierCustomerPriceId,
              carrierSellerPriceId:
                groupedCarriers[chooseHandling || HANDLING_GROUPS.HOME][0]
                  .carrierSellerPriceId,
              address: keepSuggestedAddress
                ? addressSuggested
                : addressSelected,
            };

      const responseHandling = await handleDepositHandling({
        claimGroupId,
        payload,
      });

      if (!responseHandling) {
        return undefined;
      }

      onNextStep({
        newValues: {
          handlingDeposit: {
            chooseHandling,
            pickupPointSelected,
            depositAddress: keepSuggestedAddress
              ? addressSuggested
              : addressSelected,
          },
        },
      });
    },
  );

  const isDepositHandlingLoading =
    isGetCarrierLoading || isGetClaimGroupConfirmation;

  return {
    claimGroupConfirmation,
    claimGroupId,
    groupedCarriers,
    depositAddress,
    shouldShowTransportDeduction,
    formContext,
    setChangedValue,
    changeSelection,
    onSubmit,
    handling,
    isDepositHandlingLoading,
    isLoadingSubmit: isLoadingDepositHandlingSubmit,
    chooseHandlingSelected,
    handlingSelected: handlingSelected && !pickupPointSelected,
    homeSelectedAddressChanged,
    openModalRelayPointDeposit,
    openModalHomeDeposit,
    handleOpenDepositModal,
  };
};

export default useClaimGroupDepositPage;
