import React from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { formatAmountWithCurrency } from '@savgroup-front-common/core/src/formatters/intl';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { MessageType } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import messages from './messages';
import {
  $IsDeductibleBlock,
  $PriceColumn,
  $SimpleCardAddressName,
  $SimpleCardAddressNameContainer,
  $SimpleCardContainer,
  $SimpleCardDetail,
  $SimpleCardDidactic,
  $SimpleCardIcon,
  $SimpleCardInfoContainer,
  $SimpleCardLink,
  $SimpleCardPrefixTitle,
  $SimpleCardTitle,
  $SimpleCardTitleContainer,
} from './SimpleItemCardMobile.styles';

interface SimpleItemCardMobileProps {
  icon?: ICONS_TYPE;
  iconColor?: string;
  prefixTitle: MessageType;
  title?: string | MessageType;
  didactic?: string | MessageType;
  address?: {
    addressName?: string;
    addressStreet: string;
  };
  link?: string | MessageType;
  linkUrl?: string;
  price?: {
    priceValue?: number;
    priceCurrencyCode?: CURRENCIES;
  };
  hasDisplayFreeAmount?: boolean;
  isDeductibleFromRefund?: boolean;
}

const SimpleItemCardMobile: React.FC<SimpleItemCardMobileProps> = ({
  icon,
  iconColor,
  prefixTitle,
  title,
  didactic,
  address,
  link,
  linkUrl,
  price,
  hasDisplayFreeAmount = true,
  isDeductibleFromRefund,
}) => {
  return (
    <$SimpleCardContainer>
      <div style={{ width: '100%' }}>
        <$SimpleCardInfoContainer>
          {icon && (
            <$SimpleCardIcon>
              <MyAccountIcon icon={icon} color={iconColor} />
            </$SimpleCardIcon>
          )}
          <$SimpleCardTitleContainer>
            {prefixTitle && (
              <>
                <$SimpleCardPrefixTitle>
                  <SafeFormattedMessageWithoutSpread message={prefixTitle} />
                </$SimpleCardPrefixTitle>{' '}
              </>
            )}
            {title && (
              <$SimpleCardTitle>
                <SafeFormattedMessageWithoutSpread message={title} />
              </$SimpleCardTitle>
            )}
          </$SimpleCardTitleContainer>
        </$SimpleCardInfoContainer>
        {didactic && (
          <$SimpleCardDidactic>
            <SafeFormattedMessageWithoutSpread message={didactic} />
          </$SimpleCardDidactic>
        )}
        {address && (
          <$SimpleCardAddressNameContainer>
            {address.addressName && (
              <$SimpleCardAddressName>
                <span>{address.addressName}</span>
              </$SimpleCardAddressName>
            )}
            <span>{address.addressStreet}</span>
          </$SimpleCardAddressNameContainer>
        )}
        {!!price?.priceValue && (
          <$PriceColumn>
            <$SimpleCardDetail>
              {formatAmountWithCurrency(
                price?.priceValue,
                price.priceCurrencyCode || CURRENCIES.EUR,
              )?.toString()}
            </$SimpleCardDetail>
            {!isDeductibleFromRefund && (
              <$IsDeductibleBlock>
                <SafeFormattedMessageWithoutSpread
                  message={messages.deductibleFromRefund}
                />
              </$IsDeductibleBlock>
            )}
          </$PriceColumn>
        )}

        {!price?.priceValue && hasDisplayFreeAmount && (
          <$SimpleCardDetail>
            <SafeFormattedMessageWithoutSpread message={messages.free} />
          </$SimpleCardDetail>
        )}

        {link && (
          <$SimpleCardLink href={linkUrl}>
            <SafeFormattedMessageWithoutSpread message={link} />
          </$SimpleCardLink>
        )}
      </div>
    </$SimpleCardContainer>
  );
};

export default SimpleItemCardMobile;
