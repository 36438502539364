import React, { FC, ReactElement } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import {
  MessageType,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '../../formatters';
import { MyAccountIcon } from '../../protons/IconsNewDesign/MyAccount.icon';

import {
  $Item,
  $ItemContentTextWrapper,
  $ItemContentWrapper,
  $ItemDescription,
  $ItemDistance,
  $ItemHighlighting,
  $ItemHighlightingIcon,
  $ItemLogo,
  $ItemTitle,
  $LeftSideContentWrapper,
  $SubLineContainer,
} from './ChooseItemCard.styles';
import { ChooseItemCardPriceBlock } from './ChooseItemCardPriceBlock';
import { MobileChooseItemCard } from './MobileChooseItemCard';

interface ChooseItemCardProps {
  onClick?: () => void;
  hasDisplayFreeAmount?: boolean;
  logo?: ReactElement;
  isActive?: boolean;
  icon?: ICONS_TYPE;
  label: string | MessageType;
  description?: string | number | boolean | MessageType | MessageDescriptor;
  highlighting?: string | number | boolean | MessageType | MessageDescriptor;
  distance?: string | number | boolean | MessageType | MessageDescriptor;
  subLine?: ReactElement;
  dataTestId?: string;
  price?: ReverseMoneyNumberAmount;
  displayPriceAs?: 'amount' | 'startFrom';
  canDeduceTransportFromRefund?: boolean;
}

export const ChooseItemCard: FC<
  React.PropsWithChildren<ChooseItemCardProps>
> = ({
  onClick,
  children,
  label,
  dataTestId,
  description,
  hasDisplayFreeAmount,
  highlighting,
  icon,
  isActive = false,
  logo,
  price,
  displayPriceAs = 'amount',
  subLine,
  distance,
  canDeduceTransportFromRefund,
}) => {
  const theme = useTheme();
  const isMobileView = useMedia(media.maxWidth.xs);

  if (isMobileView) {
    return (
      <MobileChooseItemCard
        onClick={onClick}
        children={children}
        label={label}
        dataTestId={dataTestId}
        description={description}
        hasDisplayFreeAmount={hasDisplayFreeAmount}
        highlighting={highlighting}
        icon={icon}
        isActive={isActive}
        logo={logo}
        price={price}
        displayPriceAs={displayPriceAs}
        subLine={subLine}
        distance={distance}
        canDeduceTransportFromRefund={canDeduceTransportFromRefund}
      />
    );
  }

  return (
    <$Item
      data-testid={dataTestId}
      title={safeFormattedIntlString(label)}
      onClick={onClick}
      $isActive={isActive}
      type={BUTTON_TYPES.BUTTON}
    >
      <$ItemContentWrapper>
        <$LeftSideContentWrapper>
          {icon ? (
            <MyAccountIcon
              icon={icon}
              color={
                isActive
                  ? theme.colors.primary
                  : theme.systemColors.defaultTextColor
              }
              size="34px"
            />
          ) : undefined}

          <$ItemContentTextWrapper>
            {label && (
              <$ItemTitle $isActive={isActive}>
                <SafeFormattedMessageWithoutSpread message={label} />
              </$ItemTitle>
            )}
            {highlighting && (
              <$ItemHighlighting>
                <$ItemHighlightingIcon>
                  <MyAccountIcon
                    icon={ICONS_TYPE.BASELINE_ECO}
                    color={theme.colors.success}
                  />
                </$ItemHighlightingIcon>
                <SafeFormattedMessageWithoutSpread message={highlighting} />
              </$ItemHighlighting>
            )}
            {description && (
              <$ItemDescription>
                <SafeFormattedMessageWithoutSpread message={description} />
              </$ItemDescription>
            )}

            {logo && (
              <ChooseItemCardPriceBlock
                hasDisplayFreeAmount={hasDisplayFreeAmount}
                price={price}
                displayPriceAs={displayPriceAs}
              />
            )}

            {distance && (
              <$ItemDistance>
                <SafeFormattedMessageWithoutSpread message={distance} />
              </$ItemDistance>
            )}

            {children}

            {subLine && <$SubLineContainer>{subLine}</$SubLineContainer>}
          </$ItemContentTextWrapper>
        </$LeftSideContentWrapper>

        {!logo && (
          <ChooseItemCardPriceBlock
            hasDisplayFreeAmount={hasDisplayFreeAmount}
            price={price}
            displayPriceAs={displayPriceAs}
            canDeduceTransportFromRefund={canDeduceTransportFromRefund}
          />
        )}

        {logo && <$ItemLogo>{logo}</$ItemLogo>}
      </$ItemContentWrapper>
    </$Item>
  );
};
