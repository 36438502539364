import { CommonClaimService } from '@savgroup-front-common/core/src/api';

import { createClaimGroupContextFromBusinessReturnCommand } from './createClaimGroupContextFromBusinessReturnCommand';
import createInvoiceQuery from './createInvoiceQuery';
import getAdditionalInformationEntitySummaryQuery from './getAdditionalInformationEntitySummaryQuery';
import getCarrierSummaryByClaimGroup from './getCarrierSummaryByClaimGroup';
import getClaimByClaimId from './getClaimByClaimId';
import getClaimContextHandlingQuery from './getClaimContextHandlingQuery';
import getClaimContextsByGroupIdQuery from './getClaimContextsByGroupIdQuery';
import getClaimContextSummaryQuery from './getClaimContextSummaryQuery';
import getClaimDocument from './getClaimDocument';
import getClaimGroupCarrierQuery from './getClaimGroupCarrierQuery';
import getClaimGroupConfirmationQuery from './getClaimGroupConfirmationQuery';
import getClaimGroupQuery from './getClaimGroupQuery';
import getClaimGroupStateQuery from './getClaimGroupStateQuery';
import getClaimGroupSummary from './getClaimGroupSummary';
import { getClaimReasonsByIssueQuery } from './getClaimReasonsByIssueQuery';
import getClaimRmaContentQuery from './getClaimRmaContentQuery';
import getConfirmationInfoByClaim from './getConfirmationInfoByClaim';
import getConfirmationInfoByClaimGroup from './getConfirmationInfoByClaimGroup';
import getHandlingByClaimGroup from './getHandlingByClaimGroup';
import getIssuesByClaim from './getIssuesByClaim';
import getIssuesByOrder from './getIssuesByOrder';
import { getIssuesForOwnerQuery } from './getIssuesForOwnerQuery';
import getReasonsByClaimGroup from './getReasonsByClaimGroup';
import getSolutionsByClaim from './getSolutionsByClaim';
import getSolutionsByClaimGroup from './getSolutionsByClaimGroup';
import getSolutionTypeQuery from './getSolutionTypeQuery';
import handleDiagnosticResponseCommandQuery from './handleDiagnosticResponseCommandQuery';
import setClaimAdditionalInformation from './setClaimAdditionalInformation';
import setClaimConfirmation from './setClaimConfirmation';
import setClaimGroupHandling from './setClaimGroupHandling';
import setClaimSolutionCommand from './setClaimSolutionCommand';
import setCreateClaimGroup from './setCreateClaimGroup';
import setIssueForClaim from './setIssueForClaim';
import setReasonForClaim from './setReasonForClaim';
import startOrContinueDiagnosticTreeQuery from './startOrContinueDiagnosticTreeQuery';

export const ClaimService = {
  setClaimAdditionalInformation,
  getAdditionalInformationEntitySummaryQuery,
  getClaimByClaimId,
  getSolutionTypeQuery,
  getClaimGroupConfirmationQuery,
  getClaimGroupCarrierQuery,
  getCarrierSummaryByClaimGroup,
  getConfirmationInfoByClaim,
  getConfirmationInfoByClaimGroup,
  getHandlingByClaimGroup,
  getIssuesByClaim,
  getIssuesByOrder,
  getReasonsByClaimGroup,
  getFullClaimContextQuery: CommonClaimService.getFullClaimContextQuery,
  getSolutionsByClaim,
  getSolutionsByClaimGroup,
  setIssueForClaim,
  setReasonForClaim,
  setClaimSolutionCommand,
  setCreateClaimGroup,
  setClaimGroupHandling,
  getClaimGroupQuery,
  getClaimDocument,
  getClaimGroupSummary,
  setClaimConfirmation,
  getClaimContextSummaryQuery,
  getClaimContextHandlingQuery,
  getClaimRmaContentQuery,
  createInvoiceQuery,
  getClaimGroupStateQuery,
  getClaimContextsByGroupIdQuery,
  startOrContinueDiagnosticTreeQuery,
  handleDiagnosticResponseCommandQuery,
  getIssuesForOwnerQuery,
  getClaimReasonsByIssueQuery,
  createClaimGroupContextFromBusinessReturnCommand,

  setClaimGroupHandlingTransportPlanCommand:
    CommonClaimService.setClaimGroupHandlingTransportPlanCommand,

  setClaimGroupSolutionCommand: CommonClaimService.setClaimGroupSolutionCommand,
};
