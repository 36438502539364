import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { COUNTRY_CODES } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  BackResponse,
  BackResponseFailure,
  PickupPoint,
} from '@savgroup-front-common/types';

import { buildUrl } from '../../helpers';
import prepareResponseFailure from '../../helpers/prepareResponseFailure';
import { apiCall } from '../../services';

const ENDPOINT = `${APIConfiguration.carrier}pickuppoints/coord`;

export interface GetPickupPointByGeoCoordQueryArgs {
  countryCode: COUNTRY_CODES | string;
  address: string;
  sellerId: string;
  productType?: string;
  transportPlanId?: string;
  carrierCompany?: string;
  carrierName?: string;
  distance?: number;
  maxPickupPoint?: number;
  claimId?: string;
}

export const getPickupPointByGeoCoordQuery = async ({
  countryCode,
  address,
  sellerId,
  productType,
  carrierCompany,
  distance = 40,
  maxPickupPoint = 10,
  claimId,
  transportPlanId,
}: GetPickupPointByGeoCoordQueryArgs): Promise<
  BackResponse<PickupPoint[]> | BackResponseFailure
> => {
  try {
    const payload = {
      CountryCode: countryCode,
      carrierCompany:
        carrierCompany === 'chrono' ? 'Chronopost' : carrierCompany,
      distance,
      maxPickupPoint,
      productType,
      sellerId,
      address,
      claimId,
      transportPlanId,
    };

    const parameters = pickBy(payload, identity);
    const query = buildUrl(ENDPOINT, parameters);

    return await apiCall<BackResponse<PickupPoint[]>>(query, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
