import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.myaccount.interventionAppointment.title',
    defaultMessage: 'Take an appointment for intervention',
  },
  whatINeedToDo: {
    id: 'components.interventionAppointment.whatINeedToDo',
    defaultMessage: 'What i need to do ?',
  },

  'Reverse.SalesforceAdapter.Errors.SalesforceAdapter.CoordinatesNotFoundOnTheServiceAppointment':
    {
      id: 'view.takeInterventionAppointmentLegacy.CoordinatesNotFoundOnTheServiceAppointment',
      defaultMessage: 'Please retry later.',
    },

  searchingForTechnician: {
    id: 'appointmentCalendarLoader.searchingForTechnician',
    defaultMessage: 'We are searching for technician',
  },
  searchingAvailability: {
    id: 'appointmentCalendarLoader.searchingAvailability',
    defaultMessage: 'We are searching for availability',
  },
  synchronizeAgendas: {
    id: 'appointmentCalendarLoader.synchronizeAgendas',
    defaultMessage: 'Synchronizing agendas',
  },
  regroupingTimeslots: {
    id: 'appointmentCalendarLoader.regroupingTimeslots',
    defaultMessage: 'Regrouping timeslots',
  },
  optimizingTechnicianItinerary: {
    id: 'appointmentCalendarLoader.optimizingTechnicianItinerary',
    defaultMessage: 'Optimising technician itinerary',
  },
});
