import { rgba, tint } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Theme } from '@savgroup-front-common/types';

import { decelerateTimingFunctionTransition } from '../../animations/timingFunction';
import {
  FIELD_STATUS,
  InputStatus,
} from '../../atoms/Form/common/helpers/getFinalFieldState.types';
import { rem } from '../../helpers';

const imageSize = 180;

interface $SolutionProps {
  $status: InputStatus;
}

const getStatusColor = (
  {
    $status,
    theme,
  }: {
    $status?: InputStatus;
    theme: Theme;
  },
  defaultColor?: string,
) => {
  switch ($status) {
    case FIELD_STATUS.ERROR:
      return theme.colors.danger;
    case FIELD_STATUS.WARNING:
      return theme.colors.alert;
    case FIELD_STATUS.SUCCESS:
      return theme.colors.success;
    default:
      return defaultColor || theme.newUI.defaultColors.menu;
  }
};

export const $RadioCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  flex: 1;
  justify-content: space-evenly;
  overflow: hidden;
`;

export const $PostTitle = styled.div`
  display: flex;
`;

export const $TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: fit-content;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.newUI.defaultColors.menu};
`;

export const $ImageContainer = styled.div<$SolutionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  width: 90%;
  height: ${rem(120)};
  @media ${media.minWidth.sm} {
    height: ${rem(120)};
  }
  ${decelerateTimingFunctionTransition};
  svg {
    width: 100%;
    height: auto;
    color: ${({ theme }) => theme.newUI.defaultColors.menu};
  }
`;

export const $RadioCardTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.newUI.defaultColors.menu};
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 8px;
`;

export const $RadioCardLabel = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const $RadioCardBody = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
  padding-left: ${rem(imageSize + 14)};
`;

export const $RadioCardContainer = styled.div<$SolutionProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-width: ${rem(1)};
  border-style: solid;
  border-color: ${({ theme, $status }) =>
    getStatusColor({ theme, $status }, theme.colors.default)};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borders.radius};

  cursor: pointer;
  padding: 18px;
  margin-bottom: 1rem;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  ${decelerateTimingFunctionTransition};
  @media ${media.minWidth.sm} {
    padding: 24px;
  }
`;

export const $RadioCardLabelContainer = styled.button<$SolutionProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};

  background: transparent;
  border: none;

  ${decelerateTimingFunctionTransition};

  &:hover,
  &:focus,
  &:active {
    outline: none;
    border: none;
    & ${$RadioCardContainer} {
      border-color: ${getStatusColor};
    }
  }
  input:checked {
    ~ ${$RadioCardContainer} {
      border-color: ${getStatusColor};
      box-shadow: ${({ theme }) =>
        `0px 0px 4px ${rgba(getStatusColor({ theme }), 0.4)}`};
      ${$RadioCardTitle} {
        color: ${getStatusColor};
      }
      ${$RadioCardLabel} {
        color: ${getStatusColor};
      }
      ${$ImageContainer} {
        border-color: ${getStatusColor};
      }
      background-color: ${({ theme, $status }) =>
        tint(0.96, getStatusColor({ theme, $status }))};
    }
  }

  &:active {
    transform: scale(0.99);
  }

  input + * {
    transform: translateY(-50%) scale(1);
    top: ${rem(imageSize / 2 + 14)};
    right: 1rem;
    left: auto;
  }
  input:active + * {
    transform: translateY(-50%)
      scale(${({ theme }) => theme.interactions.activeScale}) !important;
  }
`;
