import { CommonCarrierService } from '@savgroup-front-common/core/src/api';

import getCarrierLabelDocument from './getCarrierLabelDocument';
import getCarrierLabelsInfosQuery from './getCarrierLabelsInfosQuery';
import getCarrierLabelUrlQuery from './getCarrierLabelUrlQuery';
import getVerifiedAddress from './getVerifiedAddress';

export const CarrierService = {
  getVerifiedAddress,
  getCarrierLabelsInfosQuery,
  getCarrierLabelUrlQuery,
  getCarrierLabelDocument,
  getPickupPoints: CommonCarrierService.getPickupPointByGeoCoordQuery,
  getPickupPointByGeoCoordQuery:
    CommonCarrierService.getPickupPointByGeoCoordQuery,
};
