import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';

import {
  BUTTON_TYPES,
  HOUSING_TYPES,
  INPUT_TYPES,
  POSITIONS,
} from '@savgroup-front-common/constants/src/shared';
import { ActionRow } from '@savgroup-front-common/core/src/atoms/ActionRow';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import {
  InputHookForm,
  SwitchHookForm,
  TextareaHookForm,
} from '@savgroup-front-common/core/src/molecules/Form';
import { AddressInfoDto, FLEX_DIRECTION } from '@savgroup-front-common/types';

import RadioButton from '../components/RadioButton/RadioButton';
import SuggestedAddressModalRadioButtonInformation from '../components/SuggestedAddressModalRadioButtonInformation';

import { addressFormSchema } from './AddressForm.schema';
import { $CardContainer, $Col } from './AddressForm.styles';
import useSuggestedAddress from './hooks/useSuggestedAddress';
import messages from './messages';

const MAX_LENGTH_ADDITIONAL_INFORMATION = 50;

interface AddressFormProps {
  defaultAddress?: AddressInfoDto;
  countriesOptions: any[];
  onAddressSubmit: ({ address }: { address: AddressInfoDto }) => void;
}

const AddressForm: FC<AddressFormProps> = ({
  countriesOptions,
  onAddressSubmit,
  defaultAddress,
}) => {
  const formContext = useForm<AddressInfoDto>({
    defaultValues: defaultAddress,
    resolver: yupResolver(addressFormSchema),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = formContext;

  const currentAddress = watch();

  const habitationOptions = [
    {
      value: HOUSING_TYPES.HOUSE,
      label: safeFormattedIntlString(messages.house),
    },
    {
      value: HOUSING_TYPES.APARTMENT,
      label: safeFormattedIntlString(messages.flat),
    },
    {
      value: HOUSING_TYPES.OTHER,
      label: safeFormattedIntlString(messages.other),
    },
  ];

  const {
    handleVerifyAddress,
    isSuggestAddressOpen,
    suggestedAddressData,
    isSuggestedAddressSelected,
    onChooseAddress,
  } = useSuggestedAddress({ currentAddress });

  const onSubmit = React.useCallback(
    async (data: AddressInfoDto) => {
      const resultHasSameAddress = await handleVerifyAddress({
        addressSelected: data,
      });

      if (isSuggestedAddressSelected !== undefined) {
        const address = isSuggestedAddressSelected
          ? suggestedAddressData
          : data;

        if (address) {
          onAddressSubmit({
            address,
          });
        }

        return undefined;
      }

      if (resultHasSameAddress) {
        onAddressSubmit({
          address: data,
        });

        return undefined;
      }

      return undefined;
    },
    [
      handleVerifyAddress,
      isSuggestedAddressSelected,
      onAddressSubmit,
      suggestedAddressData,
    ],
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading level={3}>
          <SafeFormattedMessageWithoutSpread message={messages.information} />
        </Heading>
        <$CardContainer>
          <Row>
            <Col xs={12} sm={6} data-firstname-addressform>
              <FormGroup>
                <InputHookForm
                  errors={errors}
                  name="firstname"
                  placeholder={messages.firstName}
                  label={messages.firstName}
                  register={register}
                  type={INPUT_TYPES.TEXT}
                  isRequired
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} data-lastname-addressform>
              <FormGroup>
                <InputHookForm
                  errors={errors}
                  name="lastname"
                  placeholder={messages.lastName}
                  label={messages.lastName}
                  register={register}
                  type={INPUT_TYPES.TEXT}
                  isRequired
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} data-company-addressform>
              <FormGroup>
                <InputHookForm
                  errors={errors}
                  name="companyName"
                  placeholder={messages.companyName}
                  label={messages.companyName}
                  register={register}
                  type={INPUT_TYPES.TEXT}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} data-phonenumber-addressform>
              <FormGroup>
                <InputHookForm
                  label={messages.phone}
                  name="phone"
                  maxLength={25}
                  register={register}
                  errors={errors}
                  type={INPUT_TYPES.TEXT}
                  isRequired
                />
              </FormGroup>
            </Col>
          </Row>
        </$CardContainer>
        <$CardContainer>
          <Row>
            <Col xs={12} data-location-addressform>
              <FormGroup>
                <InputHookForm
                  label={messages.address}
                  name="address"
                  register={register}
                  errors={errors}
                  type={INPUT_TYPES.TEXT}
                  isRequired
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <InputHookForm
                  label={messages.additionalAddress}
                  name="additionalAddress"
                  placeholder={messages.additionalInformationsPlaceHolder}
                  register={register}
                  errors={errors}
                  type={INPUT_TYPES.TEXT}
                  dataTestId="additionalAddressInput"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={4} data-postal-addressform>
              <FormGroup>
                <InputHookForm
                  label={messages.postalCode}
                  name="postalCode"
                  register={register}
                  errors={errors}
                  type={INPUT_TYPES.TEXT}
                  isRequired
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4} data-city-addressform>
              <FormGroup>
                <InputHookForm
                  label={messages.city}
                  name="city"
                  register={register}
                  errors={errors}
                  type={INPUT_TYPES.TEXT}
                  isRequired
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4} data-countrycode-addressform>
              <FormGroup>
                <Controller
                  control={control}
                  name="countryCode"
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        name={field.name}
                        tabSelectsValue={false}
                        isClearable={false}
                        backspaceRemovesValue={false}
                        hideSelectedOptions={false}
                        label={messages.country}
                        placeholder={messages.chooseOption}
                        options={countriesOptions}
                        errors={errors}
                        value={field.value as any}
                        onChange={(option) => field.onChange(option.value)}
                        ref={field.ref}
                        isRequired
                      />
                    );
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4}>
              <FormGroup>
                <SwitchHookForm
                  register={register}
                  name="hasElevator"
                  label={messages.elevator}
                  alignLabel={FLEX_DIRECTION.COLUMN}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
              <FormGroup>
                <SwitchHookForm
                  register={register}
                  name="hasParkingSpace"
                  label={messages.parking}
                  alignLabel={FLEX_DIRECTION.COLUMN}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
              <FormGroup>
                <Controller
                  control={control}
                  name="housingType"
                  render={({ field }) => (
                    <Autocomplete
                      name={field.name}
                      errors={errors}
                      label={messages.housing}
                      options={habitationOptions}
                      onChange={(option) => field.onChange(option?.value)}
                      value={field.value as any}
                      ref={field.ref}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <FormGroup>
                <InputHookForm
                  label={messages.floor}
                  name="floor"
                  maxLength={25}
                  register={register}
                  errors={errors}
                  type={INPUT_TYPES.TEXT}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6}>
              <FormGroup>
                <InputHookForm
                  label={messages.intercom}
                  name="foorCode"
                  maxLength={25}
                  register={register}
                  errors={errors}
                  type={INPUT_TYPES.TEXT}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <TextareaHookForm
                  label={messages.additionalInfo}
                  name="additionalInformationsd"
                  errors={errors}
                  maxLength={MAX_LENGTH_ADDITIONAL_INFORMATION}
                  register={register}
                />
              </FormGroup>
            </Col>
          </Row>

          {isSuggestAddressOpen && (
            <Row>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <RadioButton
                    name="selectSuggestedAddress"
                    checked={isSuggestedAddressSelected === true}
                    onClick={() => {
                      onChooseAddress(true);
                    }}
                    isFluid
                    label={
                      <SuggestedAddressModalRadioButtonInformation
                        title={messages.suggestedAddress}
                        checked={isSuggestedAddressSelected}
                        address={suggestedAddressData}
                      />
                    }
                    labelPosition={POSITIONS.LEFT}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} sm={6}>
                <FormGroup>
                  <RadioButton
                    name="selectSuggestedAddress"
                    checked={isSuggestedAddressSelected === false}
                    onClick={() => {
                      onChooseAddress(false);
                    }}
                    isFluid
                    label={
                      <SuggestedAddressModalRadioButtonInformation
                        title={messages.addressEntered}
                        checked={!isSuggestedAddressSelected}
                        address={currentAddress}
                      />
                    }
                    labelPosition={POSITIONS.LEFT}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </$CardContainer>

        <ActionRow withoutPadding withoutMarginBottom>
          <$Col xs={12} sm={3}>
            <Button
              type={BUTTON_TYPES.SUBMIT}
              data-addressnextstep-button
              fluid
              primary
              isError={!isValid}
              dataTestId="submitButton"
            >
              <SafeFormattedMessageWithoutSpread message={messages.next} />
            </Button>
          </$Col>
        </ActionRow>
      </form>
    </div>
  );
};

export default AddressForm;
