import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { mapStyles, markerShape } from './MapWithControlledZoom.constants';
import useMapWithControlledZoom from './MapWithControlledZoom.hooks';
import { $GoogleMap } from './MapWithControlledZoom.styles';

const HOME_MARKER_URL = `${
  import.meta.env.VITE_STATIC_HASH
}/images/home_marker.svg`;

const MapWithControlledZoom = ({
  onSelectPickupPoint,
  pickupPoints,
  searchAddress,
  selectedPickupPointId,
  bounds,
  center,
}) => {
  const [hasHumanZoom, setHasHumanZoom] = useState(false);
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState(null);

  const sellerId = useSelector((state) => {
    const { items } = state.orders;

    return _.get(_.first(items), 'sellerId', null);
  });
  const { isLoading, adaptedPickupPoints } = useMapWithControlledZoom({
    pickupPoints,
    sellerId,
  });

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onZoomChanged = () => {
    if (map) {
      setZoom(map.getZoom());
      setHasHumanZoom(true);
    }
  };

  const handleSelectPickupPoint = (pickupPoint) => () => {
    const { latitude, longitude } = pickupPoint;

    if (map) {
      map.panTo({ lat: latitude, lng: longitude });
      setZoom(17);
    }

    onSelectPickupPoint(pickupPoint);
  };

  const autoFocus = useCallback(() => {
    const lat = adaptedPickupPoints[0].latitude;
    const lng = adaptedPickupPoints[0].longitude;

    if (map && lat && lng) {
      const x = map.getBounds();

      if (x) {
        const northEast = x.getNorthEast();
        const southWest = x.getSouthWest();

        if (
          !(
            (lat < northEast.lat() && lat > southWest.lat()) ||
            (lng < northEast.lng() && lng > northEast.lng())
          )
        ) {
          setZoom(zoom - 1);
        }
      }
    }
  }, [adaptedPickupPoints, zoom, map]);

  useEffect(() => {
    if (
      !hasHumanZoom &&
      adaptedPickupPoints &&
      adaptedPickupPoints?.length > 0
    ) {
      autoFocus();
    }
  }, [autoFocus, adaptedPickupPoints, zoom, hasHumanZoom]);

  if (isLoading) {
    return <BaseLoader />;
  }

  if (!bounds) {
    return null;
  }

  return (
    <$GoogleMap>
      <GoogleMap
        center={center}
        resetBoundsOnResize
        zoom={zoom}
        onZoomChanged={onZoomChanged}
        onLoad={onLoad}
        onUnmount={onUnmount}
        defaultOptions={{ styles: mapStyles }}
      >
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={500 / Math.max(zoom, 1)}
          zoomOnClick={false}
          maxZoom={15}
          children={() =>
            adaptedPickupPoints?.map((adaptedPickupPoint) => {
              const isSelected =
                selectedPickupPointId === adaptedPickupPoint.id;
              const zIndex = isSelected ? 1000 : 1;

              return (
                <Marker
                  key={adaptedPickupPoint.id}
                  position={{
                    lat: adaptedPickupPoint.latitude,
                    lng: adaptedPickupPoint.longitude,
                  }}
                  onClick={handleSelectPickupPoint(adaptedPickupPoint)}
                  title={adaptedPickupPoint.name}
                  zIndex={zIndex}
                  clickableIcons
                  shape={markerShape}
                  icon={
                    isSelected
                      ? adaptedPickupPoint.selectedIcon
                      : adaptedPickupPoint.icon
                  }
                />
              );
            })
          }
        />
        <Marker
          position={{
            lat: searchAddress.location.lat,
            lng: searchAddress.location.lng,
          }}
          icon={HOME_MARKER_URL}
        />
      </GoogleMap>
    </$GoogleMap>
  );
};

MapWithControlledZoom.propTypes = {
  searchAddress: PropTypes.shape().isRequired,
  bounds: PropTypes.shape().isRequired,
  onSelectPickupPoint: PropTypes.func.isRequired,
  pickupPoints: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

MapWithControlledZoom.displayName = 'MapWithControlledZoom';

export default React.memo(MapWithControlledZoom);
