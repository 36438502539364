import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';

import { Button } from '../../../../atoms/button';
import { Didactic } from '../../../../atoms/Didactic';
import { Autocomplete, FormGroup, Textarea } from '../../../../atoms/Form';
import { OptionValueWithPriceFormatter } from '../../../../atoms/Form/Autocomplete/Formatters/OptionValueWithPriceFormatter';
import { Heading } from '../../../../atoms/Heading';
import { ModalHeader } from '../../../../atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '../../../../formatters';
import { AdaptedQuotationNewModel } from '../../types/AdaptedQuotationNewModel';

import { $ActionRow } from './ConfirmQuotationRejectModalContent.styles';
import useConfirmQuotationRejectModal from './hooks/useConfirmQuotationRejectModal';
import messages from './messages';

interface ConfirmQuotationRejectModalContentProps {
  onRejectWithReturn: ({ rejectReason }: { rejectReason: string }) => void;
  onRejectWithDestruction: ({ rejectReason }: { rejectReason: string }) => void;
  onClose: () => void;
  quotation: AdaptedQuotationNewModel;
}

const ConfirmQuotationRejectModalContent: FC<
  ConfirmQuotationRejectModalContentProps
> = ({ onRejectWithReturn, onRejectWithDestruction, onClose, quotation }) => {
  const { formContext, handleRejectWithReturn, rejectOptions } =
    useConfirmQuotationRejectModal({
      onRejectWithReturn,
      onRejectWithDestruction,
      quotation,
    });

  const {
    control,
    formState: { errors, isValid },
  } = formContext;

  return (
    <form onSubmit={handleRejectWithReturn}>
      <ModalHeader>
        <Heading level={3}>
          <SafeFormattedMessageWithoutSpread message={messages.title} />
        </Heading>
      </ModalHeader>

      {(quotation.returnFee?.amountIncludedTax?.amount ||
        quotation.destructionFee?.amountIncludedTax?.amount) && (
        <Didactic>
          <SafeFormattedMessageWithoutSpread
            message={messages.rejectQuotationDidactic}
          />
        </Didactic>
      )}

      <FormGroup>
        <Didactic>
          <SafeFormattedMessageWithoutSpread
            message={messages.rejectOptionDescription}
          />
        </Didactic>
        <Controller
          control={control}
          name="rejectOption"
          render={({ field }) => (
            <Autocomplete
              data-testid="rejectWithDestructionOption"
              name={field.name}
              value={field.value}
              label={messages.rejectOptionLabel}
              options={rejectOptions}
              onChange={field.onChange}
              errors={errors}
              isRequired
              formatOptionLabel={OptionValueWithPriceFormatter}
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Didactic>
          <SafeFormattedMessageWithoutSpread
            message={messages.rejectCommentDescription}
          />
        </Didactic>
        <Controller
          control={control}
          name="rejectComment"
          render={({ field }) => (
            <Textarea
              name={field.name}
              value={field.value}
              label={messages.rejectCommentLabel}
              onChange={field.onChange}
              errors={errors}
            />
          )}
        />
      </FormGroup>

      <$ActionRow>
        <Button tertiary type={BUTTON_TYPES.BUTTON} onClick={() => onClose()}>
          <SafeFormattedMessageWithoutSpread message={messages.buttonCancel} />
        </Button>
        <Button type={BUTTON_TYPES.SUBMIT} danger disabled={!isValid}>
          <SafeFormattedMessageWithoutSpread message={messages.buttonReject} />
        </Button>
      </$ActionRow>
    </form>
  );
};

export default ConfirmQuotationRejectModalContent;
