import createHomePickupCommand from './createHomePickupCommand';
import getCarrierProductByIdQuery from './getCarrierProductByIdQuery';
import getHomePickupScheduleQuery from './getHomePickupScheduleQuery';
import { getPickupPointByGeoCoordQuery } from './getPickupPointByGeoCoordQuery';
import { getTransportPlanByIdQuery } from './getTransportPlanByIdQuery';

const CommonCarrierService = {
  getHomePickupScheduleQuery,
  getCarrierProductByIdQuery,
  createHomePickupCommand,
  getPickupPointByGeoCoordQuery,
  getTransportPlanByIdQuery,
};

export default CommonCarrierService;
